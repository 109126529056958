import React, { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import TopBar from "./TopBar";
import ApiHelper from "../../../helpers/ApiHelper";
import DateHelper from "../../../helpers/DateHelper";
import { Spinner } from "@blueprintjs/core";
const moment = require("moment");

const options = (normalData, predictedData) => {
  return {
    chart: {
      // type: "spline",
      backgroundColor: "rgb(247, 250, 252)"
    },
    title: {
      text: ""
    },
    xAxis: {
      type: "datetime"
    },
    yAxis: {
      title: {
        text: ""
      }
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      spline: {
        marker: {
          radius: 4,
          lineColor: "#666666",
          lineWidth: 1
        }
      }
    },
    series: [
      {
        pointStart: Date.UTC(2019, 5, 1),
        pointInterval: 24 * 3600 * 1000,
        name: "Current",
        data: normalData,
        color: "rgba(88, 106, 218, 1)"
      },
      {
        pointStart: Date.UTC(2019, 5, 1),
        pointInterval: 24 * 3600 * 1000 * 31,
        name: "Predicted",
        data: predictedData,
        showInLegend: true,
        color: "rgba(88, 106, 218, 0.3)"
      }
    ]
  };
};

const generatePredictedData = (
  initialValue,
  setPredictedData,
  growthRate,
  setTarget
) => {
  const data = [initialValue];
  const monthNameList = [
    "June 1, 2019",
    "July 1, 2019",
    "August 1, 2019",
    "September 1, 2019",
    "October 1, 2019",
    "November 1, 2019",
    "December 1, 2019",
    "January 1, 2020",
    "February 1, 2020",
    "March 1, 2020",
    "April 1, 2020",
    "May 1, 2020",
    "June 1, 2020"
  ];

  const nextMonthIndex = parseInt(moment().format("M")) + 6 + 1;
  const nextMonth = monthNameList[nextMonthIndex];

  const toReturn = [["June 1, 2019", initialValue]];

  for (let i = 0; i < 13; i++) {
    if (i == 0) {
      continue;
    }

    if (toReturn[i - 1]) {
      const previousValue = toReturn[i - 1][1];
      const newValue = Math.round(previousValue * (1 + growthRate / 100));
      const newDate = monthNameList[i];
      const newArr = [newDate, newValue];
      toReturn.push(newArr);
    }
  }

  const nextPred = toReturn[nextMonthIndex];
  setTarget(nextPred);
  setPredictedData(toReturn);
};

const fetchData = async (setNormalData, setLoading, setInit, setCurrent) => {
  const endPoint = "api/statistics/subscribers";
  const param = DateHelper.getSubscriberDates();
  setLoading(true);

  try {
    const res = await ApiHelper.getRequest(endPoint, param);
    const data = res.data.map((x) => x.sum).filter((x) => x !== 0);
    const current = data[data.length - 1];

    setCurrent(current);
    setNormalData(data);
    setInit(data[0]);
  } catch (err) {
    console.log(err);
  }

  setLoading(false);
};

const ActiveSubscriberBlock = (props) => {
  const [normalData, setNormalData] = useState([]);
  const [initialValue, setInit] = useState();
  const [predictedData, setPredictedData] = useState([]);
  const [growthRate, setGrowthRate] = useState(8);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState();
  const [target, setTarget] = useState();
  useEffect(() => {
    fetchData(setNormalData, setLoading, setInit, setCurrent);
  }, []);

  useEffect(() => {
    generatePredictedData(
      initialValue,
      setPredictedData,
      growthRate,
      setTarget
    );
  }, [growthRate, initialValue]);

  if (loading) {
    return (
      <div className="Card-root Card--radius--all Card--shadow--medium  statBackground">
        <div className={"Flex-grow--1"} style={props.style}>
          <div className="Flex-flex Flex-direction--column">
            <div className="Margin-top--8">
              <Spinner size={80} />;
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="Card-root Card--radius--all Card--shadow--medium statsBackground">
        <div className="Flex-flex Flex-direction--column">
          <div className="Flex-alignContent--center"></div>
          <TopBar
            growthRate={growthRate}
            setGrowthRate={setGrowthRate}
            current={current}
            target={target}
          />
          <HighchartsReact
            highcharts={Highcharts}
            options={options(normalData, predictedData)}
          />
        </div>
      </div>
    );
  }
};

export default ActiveSubscriberBlock;
