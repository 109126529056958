import React, { Component } from "react";
import classNames from "classnames";
import { Classes, Overlay, Spinner } from "@blueprintjs/core";
import Aux from "./Aux";

import { config } from "../config";

const PostViewer = (WrappedComponent) => {
  return class extends Component {
    state = {
      loading: false,
      showing: false,
      id: ""
    };

    closePostViewer = () => {
      this.setState({ showing: false });
    };

    showPostViewer = (id) => {
      console.log(id);
      this.setState({ showing: true, id: id }, () => this.componentDidMount());
    };

    componentDidMount() {
      if (this.state.id === "") {
        return;
      }

      this.setState({ loading: true });

      const script = document.createElement("script");

      const link = `${config.publicUrl}core/embed/r/${this.state.id}.js`;

      console.log(link);
      script.src = link;
      script.async = true;
      script.onload = () => {
        this.setState({ loading: false });
      };

      document.body.appendChild(script);
    }

    render() {
      const classes = classNames("test");

      const postViewer = (
        <div>
          <Overlay
            className={Classes.OVERLAY_SCROLL_CONTAINER}
            isOpen={this.state.showing}
            canOutsideClickClose={true}
            onClose={this.closePostViewer}
          >
            {this.state.loading} && <Spinner className={classes} size="200" />
            <div className="test">
              <div className="test2">
                <div class="nar-root" data-post-id={this.state.id}>
                  <script type="text/javascript" />
                </div>
              </div>
            </div>
          </Overlay>
        </div>
      );

      return (
        <Aux>
          {postViewer}
          <WrappedComponent
            {...this.props}
            showPostViewer={this.showPostViewer}
          />
        </Aux>
      );
    }
  };
};

export default PostViewer;
