import React from "react";
import PostFilter from "./PostFilter";
import AccountFilter from "./AccountFilter";
import EventFilter from "./EventFilter";
import FilterHelper from "../../helpers/FilterHelper";

const FilterFactory = {
  getFilter: (type, setFilter, hasFilters, datePickerDate) => {
    let filter;

    if (type !== "") {
      if (type === "Posts") {
        filter = (
          <PostFilter
            onChange={setFilter}
            previousState={FilterFactory.getPreviousState(
              hasFilters,
              datePickerDate
            )}
          />
        );
      }

      if (type === "Accounts") {
        filter = (
          <AccountFilter
            onChange={setFilter}
            previousState={FilterFactory.getPreviousState(
              hasFilters,
              datePickerDate
            )}
          />
        );
      }

      if (type === "Events") {
        filter = (
          <EventFilter
            onChange={setFilter}
            previousState={FilterFactory.getPreviousState(
              hasFilters,
              datePickerDate
            )}
          />
        );
      }
    }

    return filter;
  },
  getPreviousState: (hasFilters, datePickerDate) => {
    let prevState = {};

    if (hasFilters != null) {
      prevState = FilterHelper.getFilterState(hasFilters);
      if (datePickerDate !== null) {
        prevState.datePickerDate = datePickerDate;
      }
    }

    return prevState;
  }
};

export default FilterFactory;
