import React from "react";
import CopyButton from "./CopyButton";
import AppToaster from "../../AppToaster";

const EventMenu = (props) => {
  return (
    <div className="Margin-top--8">
      <CopyButton
        text={props.props.event_id}
        onCopy={() =>
          AppToaster.show({ message: "Event ID copied to clipboard" })
        }
      >
        Copy ID
      </CopyButton>
    </div>
  );
};

export default EventMenu;
