import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import NavHeader from "./NavHeader.jsx";
import PageableTable from "./Table/PageableTable.jsx";
import UserHelper from "../helpers/UserHelper.js";
import ApiHelper from "../helpers/ApiHelper.js";

import Error from "../hoc/Error";
import ObjectFactory from "../helpers/ObjectFactory/ObjectFactory.js";

class Users extends Component {
  constructor(props) {
    super(props);

    const searchVal = this.props.location.search;
    var account_id = undefined;
    if (searchVal !== undefined) {
      const paramsMap = searchVal
        .replace(/(^\?)/, "")
        .split("&")
        .map(
          function(n) {
            return (n = n.split("=")), (this[n[0]] = n[1]), this;
          }.bind({})
        )[0];
      if (paramsMap["account_id"] !== undefined) {
        account_id = paramsMap["account_id"];
      }
    }

    this.state = {
      fetched: false,
      pagination: { number: 1, size: 9 },
      account_id: account_id
    };

    const queryParams = this.props.location.search
      .replace(/(^\?)/, "")
      .split("&")
      .map(
        function(n) {
          return (n = n.split("=")), (this[n[0]] = n[1]), this;
        }.bind({})
      )[0];

    this.state = {
      fetched: false,
      pagination: { number: 1, size: 9 },
      account_id: queryParams["account_id"]
    };

    this.fetchCurrentPage = this.fetchCurrentPage.bind(this);
    this.incrementPage = this.incrementPage.bind(this);
    this.decrementPage = this.decrementPage.bind(this);
  }

  componentDidMount() {
    this.fetchCurrentPage(this.state.pagination);
  }

  fetchCurrentPage(pagination) {
    var params = ApiHelper.buildPaginationParams(
      pagination.number,
      pagination.size
    );

    if (this.state.account_id !== undefined) {
      params = {
        ...params,
        "filter[account_id]": this.state.account_id
      };
    }

    const pageParams = ApiHelper.buildPaginationParams(
      pagination.number,
      pagination.size
    );

    params = {};
    if (this.state.account_id === undefined) {
      params = {
        ...pageParams
      };
    } else {
      params = {
        ...pageParams,
        "filter[account_id]": this.state.account_id
      };
    }

    ApiHelper.getRequest("api/users", params)
      .then((res) => {
        const pagination = res.data.pagination;
        const users = res.data.data.map((user) => {
          return UserHelper.formatUser(user);
        });
        this.setState({
          fetched: true,
          users: users,
          pagination: pagination
        });
      })
      .catch((error) => {
        console.log(error);
        this.props.errorHandler(error);
      });
  }

  incrementPage() {
    if (this.state.pagination.total === this.state.pagination.number) return;
    const newPage = {
      ...this.state.pagination,
      number: this.state.pagination.number + 1
    };
    this.setState({ fetched: false, pagination: newPage });
    this.fetchCurrentPage(newPage);
  }

  decrementPage() {
    if (this.state.pagination.number === 1) return;
    const newPage = {
      ...this.state.pagination,
      number: this.state.pagination.number - 1
    };
    this.setState({ fetched: false, pagination: newPage });
    this.fetchCurrentPage(newPage);
  }

  render() {
    return (
      <div>
        <NavHeader />
        <div className="World-container">
          <PageableTable
            className="container-box"
            fetching={!this.state.fetched}
            pagination={this.state.pagination}
            nextPage={this.incrementPage}
            prevPage={this.decrementPage}
            tableInfo={ObjectFactory.users(this.state.users)}
          />
        </div>
      </div>
    );
  }
}

export default Error(withRouter(Users));
