import React from "react";
import { Button } from "@blueprintjs/core";
import CopyButton from "./CopyButton";
import AppToaster from "../../AppToaster";

const AccountMenu = (props) => {
  return (
    <div className="Margin-top--8">
      <CopyButton
        text={props.props.account_id}
        onCopy={() =>
          AppToaster.show({ message: "Account ID copied to clipboard" })
        }
      >
        Copy ID
      </CopyButton>
      <Button className="Margin-left--16" onClick={props.props.onEditClick}>
        Edit Account
      </Button>
      <Button className="Margin-left--16" onClick={props.props.onEditPlanClick}>
        Edit Plan
      </Button>
      <Button
        className="Margin-left--16"
        onClick={props.props.onMergeAccountClick}
        disabled={true}
      >
        Merge Account
      </Button>
    </div>
  );
};

export default AccountMenu;
