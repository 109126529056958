import AccountFactory from "./AccountFactory";
import EventFactory from "./EventFactory";
import PostFactory from "./PostFactory";
import UserFactory from "./UserFactory";
import TableInfoFactory from "./TableInfoFactory";
import SearchFactory from "./SearchFactory";

const ObjectFactory = {
  account: AccountFactory,
  accounts: TableInfoFactory.accounts,
  event: EventFactory,
  events: TableInfoFactory.events,
  post: PostFactory,
  posts: TableInfoFactory.posts,
  user: UserFactory,
  users: TableInfoFactory.users,
  admins: TableInfoFactory.admins,
  search: SearchFactory
};

export default ObjectFactory;
