import React from "react";
import DiffSearchCard from "../components/Search/DiffSearchCard";

const plans = {
  narrative_pro: "Pro",
  narrative_pro_year: "Pro Year",
  narrative_free_4: "Free 4",
  narrative_basic: "Basic",
  narrative_basic_year: "Basic Year",
  narrative_lite: "Lite",
  narrative_lite_year: "Lite Year"
};

const statuses = {
  "0": "Ok",
  "1": "Preparing Revision"
};

const SearchHelper = {
  getSearchCards: (res, props) => {
    let link;
    let objectType;
    let title;
    let className;
    let subTitles;

    const cards = res.data.map((result) => {
      switch (result.type) {
        case "post":
          link = "/posts/" + result.data.id;
          objectType = SearchHelper.capitalizeFirstLetter(result.type);
          title = result.data.name;
          className = "card-post";

          subTitles = [`Status: ${statuses[result.data.status]}`];

          break;
        case "account":
          console.log(result.data);
          link = "/accounts/" + result.data.id;
          objectType = SearchHelper.capitalizeFirstLetter(result.type);
          title = result.data.user.f_name + " " + result.data.user.l_name;
          className = "card-account";

          subTitles = [
            `Host: ${result.data.publisher.host}`,
            `Email: ${result.data.user.email}`,
            `Plan: ${plans[result.data.plan_id]}`
          ];

          subTitles = subTitles.filter((subtitle) => subtitle !== "Host: null");

          break;
        case "event":
          link = "/events/" + result.data.id;
          objectType = SearchHelper.capitalizeFirstLetter(result.type);
          title = result.data.topic;

          subTitles = [`Account ID: `, `Transaction ID: `];
          break;
        default:
          break;
      }

      return (
        <DiffSearchCard
          objectType={objectType}
          className={className}
          title={title}
          subTitles={subTitles}
          link={link}
          {...props}
        />
      );
    });

    return cards;
  },
  capitalizeFirstLetter: (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  getPlan: (text) => {
    return Object.keys(plans).find(
      (key) => plans[key].toLowerCase() === text.toLowerCase()
    );
  },
  getAccounts: (response) => {
    const res = response.data
      .filter((data) => data.type === "account")
      .map((data) => data.data);

    res.forEach(
      (newData) =>
        (newData.name = newData.user.f_name + " " + newData.user.l_name)
    );

    res.forEach((newData) => (newData.host = newData.publisher.host));

    res.forEach((newData) => (newData.email = newData.user.email));

    return res;
  },
  getPosts: (response) => {
    const postResponse = response.data
      .filter((data) => data.type === "post")
      .map((data) => data.data);

    postResponse.forEach((data) => {
      data.status = statuses[data.status];
    });

    return postResponse;
  },
  getEvents: (response) => {
    return response.data
      .filter((data) => data.type === "event")
      .map((data) => data.data);
  }
};

export default SearchHelper;
