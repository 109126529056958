import React, { Component } from "react";

import NavHeader from "./NavHeader.jsx";
import PageableTable from "./Table/PageableTable.jsx";

import AccountHelper from "../helpers/AccountHelper.js";
import ApiHelper from "../helpers/ApiHelper.js";

import PaginationHelper from "../helpers/PaginationHelper.js";
import Error from "../hoc/Error";
import ObjectFactory from "../helpers/ObjectFactory/ObjectFactory";

const sorts = {
  name: {
    ascending: "user_f_name,user_l_name",
    descending: "-user_f_name,-user_l_name"
  },
  email: {
    ascending: "email",
    descending: "-email"
  },
  host: {
    ascending: "publisher_host",
    descending: "-publisher_host"
  },
  status: {
    ascending: "status",
    descending: "-status"
  },
  inserted_at: {
    ascending: "inserted_at",
    descending: "-inserted_at"
  },
  plan_name: {
    ascending: "plan_id",
    descending: "-plan_id"
  },
  post_count: {
    ascending: "post_count",
    descending: "-post_count"
  }
};

class Accounts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetched: false,
      pagination: { number: 1, size: PaginationHelper.getPageSize() },
      filters: {},
      datePickerDate: null,
      currentSort: "inserted_at",
      descending: true
    };

    this.fetchCurrentPage = this.fetchCurrentPage.bind(this);
    this.incrementPage = this.incrementPage.bind(this);
    this.decrementPage = this.decrementPage.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setPagination = this.setPagination.bind(this);
  }

  switchSort = () => {
    this.setState(
      (prevState) => ({
        descending: !prevState.descending
      }),
      () => this.componentDidMount(this.state.pagination, this.state.filters)
    );
  };

  componentDidMount() {
    this.fetchCurrentPage(this.state.pagination, this.state.filters);
  }

  fetchCurrentPage(pagination, filters) {
    const pageParams = ApiHelper.buildPaginationParams(
      pagination.number,
      pagination.size
    );

    let params = {
      ...pageParams,
      ...filters
    };

    if (this.state.descending === true) {
      params = {
        ...params,
        sort: sorts[this.state.currentSort]["descending"]
      };
    } else {
      params = {
        ...params,
        sort: sorts[this.state.currentSort]["ascending"]
      };
    }

    ApiHelper.getRequest("api/accounts", params)
      .then((res) => {
        if (res === "redirect") {
          this.props.history.push("/login");
        }

        const pagination = res.data.pagination;
        console.log(res.data);
        const accounts = res.data.data
          .filter((account) => account.active !== null)
          .map((account) => {
            return AccountHelper.formatAccount(account);
          });

        this.setState(
          {
            fetched: true,
            accounts: accounts,
            pagination: pagination,
            repsonseError: true
          },
          () => {
            if (
              this.state.pagination.number > this.state.pagination.total &&
              this.state.pagination.total !== 0
            ) {
              const newPagination = {
                ...this.state.pagination,
                number: this.state.pagination.total
              };

              this.fetchCurrentPage(newPagination, filters);
            }
          }
        );
      })
      .catch((error) => {
        console.log(error);
        this.setState({ repsonseError: error });
        this.props.errorHandler(error);
      });
  }

  incrementPage() {
    if (this.state.pagination.total === this.state.pagination.number) return;
    const newPage = {
      ...this.state.pagination,
      number: this.state.pagination.number + 1
    };
    this.setState({ fetched: false, pagination: newPage });
    this.fetchCurrentPage(newPage, this.state.filters);
  }

  decrementPage() {
    if (this.state.pagination.number === 1) return;
    const newPage = {
      ...this.state.pagination,
      number: this.state.pagination.number - 1
    };
    this.setState({ fetched: false, pagination: newPage });
    this.fetchCurrentPage(newPage, this.state.filters);
  }

  setFilter(filters, datePickerDate) {
    this.setState({
      fetched: false,
      filters: filters,
      datePickerDate: datePickerDate
    });
    this.fetchCurrentPage(this.state.pagination, filters);
  }

  setPagination(size) {
    const newPage = {
      ...this.state.pagination,
      size: size,
      number: 1
    };
    this.setState({ fetched: false, pagination: newPage });
    this.fetchCurrentPage(newPage, this.state.filters);
  }

  switchCurrentSort = (newSort) => {
    this.setState({ currentSort: newSort }, () =>
      this.componentDidMount(this.state.pagination, this.state.filters)
    );
  };

  getSortObj = () => {
    return {
      descending: this.state.descending,
      switchSort: this.switchSort,
      currentSort: this.state.currentSort,
      switchCurrentSort: this.switchCurrentSort
    };
  };

  render() {
    console.log(this.getSortObj());
    return (
      <div>
        <NavHeader />
        <div className="World-container">
          <PageableTable
            className="container-box"
            fetching={!this.state.fetched}
            pagination={this.state.pagination}
            resourceName="Accounts"
            nextPage={this.incrementPage}
            prevPage={this.decrementPage}
            tableInfo={ObjectFactory.accounts(this.state.accounts)}
            setFilter={this.setFilter}
            setPagination={this.setPagination}
            filters={this.state.filters}
            datePickerDate={this.state.datePickerDate}
            sortObj={this.getSortObj()}
          />
        </div>
      </div>
    );
  }
}

export default Error(Accounts);
