import React from "react";
import { Checkbox } from "@blueprintjs/core";

const OptionCheckbox = (props) => {
  return (
    <div className="Flex-flex Flex-direction--row Flex-justifyContent--flexEnd">
      <div className="Margin-left--8 ">
        <span> {props.status} </span>
      </div>

      <Checkbox
        className="Margin-left--8 "
        style={{ bottom: "5px", left: "5px" }}
        onChange={props.changedHandler}
        checked={props.checked}
      />
    </div>
  );
};

export default OptionCheckbox;
