import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import NavHeader from "./components/NavHeader.jsx";

import Login from "./components/Login.jsx";
import Home from "./components/Home.jsx";

import Accounts from "./components/Accounts.jsx";
import Account from "./components/Account/Account";

import Posts from "./components/Posts.jsx";
import Post from "./components/Post.jsx";

import Users from "./components/Users.jsx";
import User from "./components/User.jsx";

import Coupons from "./components/Coupons.jsx";

import Events from "./components/Events.jsx";
import Event from "./components/Event.jsx";

import AuthHelper from "./helpers/AuthHelper";
import SearchPage from "./components/SearchPage";
import Admins from "./components/Admin/Admins";
import FastPostViewer from "./components/FastPostViewer";

class App extends Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/" component={Home} />

            <PrivateRoute exact path="/accounts" component={Accounts} />
            <PrivateRoute exact path="/accounts/:id" component={Account} />

            <PrivateRoute exact path="/posts" component={Posts} />
            <PrivateRoute exact path="/posts/:id" component={Post} />
            <PrivateRoute exact path="/postviewer/:id" component={FastPostViewer} />

            <PrivateRoute exact path="/users" component={Users} />
            <PrivateRoute exact path="/users/:id" component={User} />

            <PrivateRoute exact path="/coupons" component={Coupons} />

            <PrivateRoute exact path="/events" component={Events} />
            <PrivateRoute exact path="/events/:id" component={Event} />

            <PrivateRoute exact path="/search/:id" component={SearchPage} />
            <PrivateRoute exact path="/admins" component={Admins} />

            <Route component={NoMatch} />
          </Switch>
        </Router>
      </div>
    );
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      AuthHelper.getUserToken() !== undefined ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const NoMatch = ({ location }) => (
  <div>
    <NavHeader />
    <div className="World-container">
      <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
        <div className="Box-root Margin-horizontal--20 Margin-vertical--64 Flex-flex Flex-alignItems--center">
          <span
            style={{ width: "100%" }}
            className="Text-color--dark Text-align--center Text-fontSize--32 Text-fontWeight--bold Text-lineHeight--32 Text-typeface--base Text-wrap--noWrap Text-display--block"
          >
            404 Not Found
          </span>
        </div>
        <div className="Box-root Margin-horizontal--20 Margin-vertical--64 Flex-flex">
          <span
            style={{ width: "100%" }}
            className="Text-color--gray Text-align--center Text-fontSize--24 Text-fontWeight--medium Text-lineHeight--28 Text-typeface--base Text-wrap--noWrap Text-display--block"
          >
            <code>{location.pathname}</code>
          </span>
        </div>
      </div>
    </div>
  </div>
);

export default App;
