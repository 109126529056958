const formatBytes = (a, b) => {
  if (0 === a) return "0 Bytes";
  var c = 1024,
    d = b || 2,
    e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    f = Math.floor(Math.log(a) / Math.log(c));
  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
};

const UserHelper = {
  formatUser: (user) => {
    return {
      ...user,
      key: user.id,
      fullName: user.f_name + " " + user.l_name
    };
  },
  transformAppData: (user) => {
    if (user !== null) {
      if (user.app_usage !== null) {
        if (user.app_usage.m_count !== null) {
          user.app_usage.m_count = formatBytes(user.app_usage.m_count);
        }
      }
    }
  }
};

export default UserHelper;
