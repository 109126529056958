import React from "react";
import { Button } from "@blueprintjs/core";
import axios from "axios";
import AuthHelper from "../../../helpers/AuthHelper";
import AppToaster from "../../AppToaster";

import { config } from "../../../config";

const resetPassword = (email) => {
  const path = `${config.publicUrl}core/request-password`;
  return axios({
    method: "post",
    url: path,
    data: {
      email: email
    },
    headers: {
      Authorization: "Bearer " + AuthHelper.getUserToken()
    }
  });
};

const onResetPassHandler = (email) => {
  resetPassword(email)
    .then((res) =>
      AppToaster.show({
        message: `Reset password email to ${email} has been sent`
      })
    )
    .catch((err) => console.log(err));
};

const emailVerification = (email) => {
  const path = `${config.publicUrl}api/request-email-verify`;
  console.log(path);
  return axios({
    method: "post",
    url: path,
    data: {
      email: email
    },
    headers: {
      Authorization: "Bearer " + AuthHelper.getUserToken()
    }
  });
};

const onEmailVerification = (email) => {
  emailVerification(email)
    .then((res) =>
      AppToaster.show({
        message: `Verification email to ${email} has been sent`
      })
    )
    .catch((err) => console.log(err));
};

const UserMenu = (props) => {
  const email = props.props.user.email;

  return (
    <div className="Margin-top--8">
      <Button onClick={() => onEmailVerification(email)}>
        {" "}
        Send Verify Email
      </Button>
      <Button
        onClick={() => onResetPassHandler(email)}
        className="Margin-left--16"
      >
        Send Password Reset
      </Button>
      <Button className="Margin-left--16" onClick={props.props.onDeleteClick}>
        Edit User Details
      </Button>
    </div>
  );
};

export default UserMenu;
