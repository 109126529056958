import React from "react";
import { Spinner } from "@blueprintjs/core";

const searchResults = (props) => {
  const spinner = (
    <div className="Margin-top--8">
      <Spinner size="30" />
    </div>
  );

  const searchCards = props.searchCards;

  return <div>{props.loading ? spinner : searchCards}</div>;
};

export default searchResults;
