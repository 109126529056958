import React, { Component } from "react";
import Aux from "../Aux";
import classNames from "classnames";
import {
  Button,
  Classes,
  Intent,
  Overlay,
  H5,
  FormGroup,
  Icon,
  H4,
  Spinner,
  HTMLSelect
} from "@blueprintjs/core";
import OptionCheckbox from "./OptionCheckbox";
import AccountHelper from "../../helpers/AccountHelper";
import ApiHelper from "../../helpers/ApiHelper";

const initialState = {
  editConfirmed: false,
  editLoading: false,
  editSuccess: false,
  editError: false,
  showForm: false,
  currentActive: "",
  cannotChargeCardChecked: false,
  subscriptionSuspendedChecked: false,
  violatedFairUsePolicyChecked: false,
  violatedTermsChecked: false,
  suspiciousActivityChecked: false,
  pendingDeletionChecked: false
};

const EditAccount = (WrappedComponent) => {
  return class extends Component {
    state = {
      editConfirmed: false,
      editLoading: false,
      editSuccess: false,
      editError: false,
      showForm: false,
      currentActive: "",
      cannotChargeCardChecked: false,
      subscriptionSuspendedChecked: false,
      violatedFairUsePolicyChecked: false,
      violatedTermsChecked: false,
      suspiciousActivityChecked: false,
      pendingDeletionChecked: false,
      updateAccountDetails: null
    };

    activeChangedHandler = (e) => {
      this.setState({ currentActive: e.currentTarget.value });
    };

    cannotChargeCardHandler = () => {
      this.setState((prevState) => ({
        cannotChargeCardChecked: !prevState.cannotChargeCardChecked
      }));
    };

    subscriptionSuspendedHandler = () => {
      this.setState((prevState) => ({
        subscriptionSuspendedChecked: !prevState.subscriptionSuspendedChecked
      }));
    };

    violatedFairUsePolicyHandler = () => {
      this.setState((prevState) => ({
        violatedFairUsePolicyChecked: !prevState.violatedFairUsePolicyChecked
      }));
    };

    violatedTermsHandler = () => {
      this.setState((prevState) => ({
        violatedTermsChecked: !prevState.violatedTermsChecked
      }));
    };

    suspiciousActivityHandler = () => {
      this.setState((prevState) => ({
        suspiciousActivityChecked: !prevState.suspiciousActivityChecked
      }));
    };

    pendingDeletionHandler = () => {
      this.setState((prevState) => ({
        pendingDeletionChecked: !prevState.pendingDeletionChecked
      }));
    };

    closeFormValid = () => {
      return !this.state.editLoading === true;
    };

    showEditAccount = (currStatus, currActive, updateAccountDetails) => {
      this.setState({
        showForm: true,
        updateAccountDetails: updateAccountDetails
      });

      this.setState(AccountHelper.getInitialState(currStatus, currActive));
    };

    closeEditAccount = () => {
      this.setState({ showForm: false });
      this.setState(initialState);
    };

    submitHandler = () => {
      this.setState({
        editLoading: true,
        editConfirmed: true
      });

      const accountID = window.location.pathname.substring(1);
      const link = "api/" + accountID.toString();
      const body = AccountHelper.getBody(this.state);

      ApiHelper.sendEditRequest(link, body)
        .then((res) => {
          this.setState({ editLoading: false }, () =>
            this.setState({ editSuccess: true }, () => {
              this.state.updateAccountDetails(
                AccountHelper.getUpdatedAccount(res)
              );
            })
          );
        })
        .catch((err) => {
          this.setState({ editLoading: false }, () =>
            this.setState({
              editError: true,
              errorMessage: err.response.data.info
            })
          );
        });
    };

    render() {
      const classes = classNames(
        Classes.CARD,
        Classes.ELEVATION_4,
        "Text-align--center",
        "container"
      );

      const formContent = (
        <div className={classes}>
          <FormGroup
            label={
              <span className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-fontWeight--medium Text-lineHeight--24 Text-typeface--base Text-wrap--wrap Text-display--inline">
                <span> Editing Account Details: </span>
              </span>
            }
            labelFor="text-input"
          >
            <div className="Margin-top--12" />
            <div className="Flex-flex Flex-direction--row">
              <span>{"Active:"}</span>
              <HTMLSelect
                className="Margin-left--8 Bottom-5"
                options={["Active", "Inactive"]}
                value={this.state.currentActive}
                onChange={this.activeChangedHandler}
              />
            </div>

            <div className="Margin-top--12" />
            <div className="Flex-flex Flex-direction--row">
              <div>
                <span>{"Status:"}</span>
              </div>
              <div className="Flex-flex Flex-direction--column">
                <OptionCheckbox
                  checked={this.state.cannotChargeCardChecked}
                  changedHandler={this.cannotChargeCardHandler}
                  status={"Cannot Charge Card"}
                />
                <OptionCheckbox
                  checked={this.state.subscriptionSuspendedChecked}
                  changedHandler={this.subscriptionSuspendedHandler}
                  status={"Subscription Suspended"}
                />
                <OptionCheckbox
                  checked={this.state.violatedFairUsePolicyChecked}
                  changedHandler={this.violatedFairUsePolicyHandler}
                  status={"Violated Fair Use Policy"}
                />
                <OptionCheckbox
                  checked={this.state.violatedTermsChecked}
                  changedHandler={this.violatedTermsHandler}
                  status={"Violated Terms"}
                />

                <OptionCheckbox
                  checked={this.state.suspiciousActivityChecked}
                  changedHandler={this.suspiciousActivityHandler}
                  status={"Suspicious Activity"}
                />
                <OptionCheckbox
                  checked={this.state.pendingDeletionChecked}
                  changedHandler={this.pendingDeletionHandler}
                  status={"Pending Deletion"}
                />
              </div>
            </div>
          </FormGroup>

          <br />
          <div className="Text-align--center">
            <Button intent={Intent.DANGER} onClick={this.closeEditAccount}>
              Close
            </Button>
            <Button
              className="Margin-left--12"
              intent={Intent.PRIMARY}
              onClick={this.submitHandler}
            >
              Confirm Edit
            </Button>
          </div>
        </div>
      );

      const loading = (
        <div className={classes}>
          <Spinner size={48} />
        </div>
      );

      const successScreen = (
        <div className={classes}>
          <Icon icon="tick" iconSize="80" intent={Intent.SUCCESS} />
          <H4> Account details successfully updated</H4>
          <div className="Text-align--center">
            <Button intent={Intent.PRIMARY} onClick={this.closeEditAccount}>
              Ok
            </Button>
          </div>
        </div>
      );

      const errorScreen = (
        <div className={classes}>
          <Icon icon="error" iconSize="80" intent={Intent.DANGER} />
          <div className="Margin-top--8">
            <H4> Account details unsuccessfully updated </H4>
            <H5> Error Message: {this.state.errorMessage} </H5>
          </div>

          <div className="Text-align--center">
            <Button intent={Intent.PRIMARY} onClick={this.closeEditAccount}>
              Ok
            </Button>
          </div>
        </div>
      );

      const editForm = (
        <Overlay
          isOpen={this.state.showForm}
          onClose={this.closeEditPublisher}
          className={Classes.OVERLAY_SCROLL_CONTAINER}
          canEscapeKeyClose={this.closeFormValid()}
          canOutsideClickClose={this.closeFormValid()}
        >
          {!this.state.editConfirmed === true && formContent}
          {this.state.editLoading === true && loading}
          {this.state.editSuccess === true && successScreen}
          {this.state.editError === true && errorScreen}
        </Overlay>
      );

      return (
        <Aux>
          {this.state.showForm === true && editForm}
          <WrappedComponent
            {...this.props}
            showEditAccount={this.showEditAccount}
          />
        </Aux>
      );
    }
  };
};

export default EditAccount;
