const PromiseHelper = {
  promiseAll: (promises) => {
    return new Promise((resolve, reject) => {
      const results = [];
      let count = 0;
      promises.forEach((promise, idx) => {
        promise
          .catch((err) => {
            return err;
          })
          .then((valueOrError) => {
            results[idx] = valueOrError;
            count += 1;
            if (count === promises.length) resolve(results);
          });
      });
    });
  }
};

export default PromiseHelper;
