import React from "react";
import { Link } from "react-router-dom";

const StringCell = (props) => {
  return (
    <td className="db-ListViewItem-cell db-ListViewItem-text">
      <Link className="db-ListViewItem-link" to={props.link}>
        <div className="db-ListViewItem-cellContent Box-root Padding-all--8">
          <span className="db-ListViewItem-text Text-color--gray Text-fontSize--14 Text-lineHeight--20 Text-typeface--base Text-wrap--wrap Text-display--inline">
            <span>{props.data}</span>
          </span>
        </div>
      </Link>
    </td>
  );
};

export default StringCell;
