import React from "react";

const BadPillRow = (props) => {
  return (
    <div className="bs-PropertyList-row">
      <div className="bs-PropertyList-property">
        <span>{props.label}</span>
      </div>
      <div className="db-ListViewItem-cellContent Box-root Padding-all--8">
        <div className="Badge Box-root Box-background--yellow100 Padding-horizontal--8 Padding-vertical--2 Flex-inlineFlex Flex-alignItems--center Flex-direction--row">
          <span className="Badge-text Text-color--yellow600 Text-fontSize--12 Text-fontWeight--medium Text-lineHeight--16 Text-typeface--base Text-wrap--noWrap Text-display--inline">
            <span>{props.value}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default BadPillRow;
