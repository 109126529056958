import React from "react";
import ReactDOM from "react-dom";
import "./Style/base.css";
import App from "./App";
import axios from "axios";
import AuthHelper from "./helpers/AuthHelper";
import { createHashHistory } from "history";

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    console.log(err.response);

    if (
      (err.response.status === 403 || err.response.status === 422) &&
      err.response.data.info === "Forbidden access"
    ) {
      AuthHelper.removeUserToken();
      const history = createHashHistory();
      history.replace({ pathname: "/login" });
    }

    return Promise.reject(err);
  }
);

ReactDOM.render(<App />, document.getElementById("root"));
