import React, { Component } from "react";
import {
  Button,
  Position,
  Popover,
  RadioGroup,
  Radio
} from "@blueprintjs/core";

import PaginationHelper from "../../helpers/PaginationHelper.js";

import Cookies from "js-cookie";

class PaginationOptions extends Component {
  constructor(props) {
    const c = PaginationHelper.getPageSize();
    super(props);
    this.state = { size: c };
    this.handleChange = this.handleChange.bind(this);
    this.handleOkChange = this.handleOkChange.bind(this);
  }

  handleChange(e) {
    this.setState({ size: e.target.value });
  }

  handleOkChange(e) {
    this.props.onChange(this.state.size);
    Cookies.set("page_size", this.state.size);
  }

  render() {
    const pageOption = (
      <div className="Padding-all--8">
        <RadioGroup
          label="Number of items"
          onChange={this.handleChange}
          selectedValue={this.state.size}
        >
          <Radio label="10" value="10" />
          <Radio label="50" value="50" />
          <Radio label="100" value="100" />
          <Radio label="200" value="200" />
        </RadioGroup>
        <Button onClick={this.handleOkChange}>Ok</Button>
      </div>
    );

    return (
      <Popover content={pageOption} position={Position.BOTTOM_LEFT}>
        <Button icon="cog">Options</Button>
      </Popover>
    );
  }
}

export default PaginationOptions;
