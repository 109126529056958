import React from "react";
import StatCell from "./StatCell";
import StatRowHeadingCell from "./StatRowHeadingCell";
import StringCell from "./StringCell";
import DateCell from "./DateCell";
import GoodPillCell from "./GoodPillCell";
import BadPillCell from "./BadPillCell";
import EmptyCell from "./EmptyCell";
import ColumnHeaderCell from "./ColumnHeaderCell";
import ActionCell from "./ActionCell";
import StaticCell from "./StaticCell";

import DateHelper from "../../helpers/DateHelper";
import PercentageCell from "./PercentageCell";
import EmptyColumnHeaderCell from "./EmptyColumnHeaderCell";
import SortableColumnHeaderCell from "./SortableColumnHeaderCell";
import StaticCallback from "./StaticCallback";
import MetaCell from "./MetaCell";
var moment = require("moment-timezone");

const CellFactory = {
  getCell: (type, data, link) => {
    switch (type) {
      case "StatsRowHeading":
        return <StatRowHeadingCell data={data} />;
      case "Stats":
        return <StatCell data={data} />;
      case "String":
        return <StringCell data={data} link={link} />;
      case "Meta":
        return <MetaCell data={data} />
      case "Date":
        return <DateCell data={DateHelper.getMomentDate(data)} link={link} />;
      case "OccurredDate":
        const tz = moment.tz.guess();
        const date = moment(data / 1000)
          .tz(tz)
          .format("DD/MM/YYYY, h:mm:ss a");
        return <DateCell data={date} link={link} />;
      case "Pill":
        if (typeof data === "string") {
          data = {
            level: data,
            key: data
          };
        }

        if (data && (data["level"] === "good" || data["level"] === "Ok")) {
          return <GoodPillCell data={data} link={link} />;
        } else {
          return <BadPillCell data={data} link={link} />;
        }
      case "Static":
        return <StaticCell data={data} />;
      case "SelectAcc":
        return <StaticCallback data={data} link={link} />;
      default:
        break;
    }
  },
  getColumnHeaderCell: (title, sortObj, actualColName) => {
    return (
      <ColumnHeaderCell
        actualColName={actualColName}
        title={title}
        sortObj={sortObj}
      />
    );
  },
  getSortableColumnHeaderCell: (title, sortObj, actualColName) => {
    return (
      <SortableColumnHeaderCell
        actualColName={actualColName}
        title={title}
        sortObj={sortObj}
      />
    );
  },
  getEmptyColumnHeaderCell: () => {
    return <EmptyColumnHeaderCell />;
  },
  getActionCell: (menu) => {
    return <ActionCell menu={menu} />;
  },
  getEmptyCell: () => {
    return <EmptyCell />;
  },
  getPercentageCell: (data, flag) => {
    return <PercentageCell data={data} flag={flag} />;
  }
};

export default CellFactory;
