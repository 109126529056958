import React, { Component } from "react";
import { Checkbox, HTMLSelect } from "@blueprintjs/core";

class FilterActive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: this.props.isChecked
    };
  }

  componentDidMount() {
    this.setState({ isChecked: this.props.isChecked });
  }

  handleCheckbox = () => {
    this.setState(
      (prevState) => ({
        isChecked: !prevState.isChecked
      }),
      () => this.props.activeToggledHandler(this.state.isChecked)
    );
  };

  handleActiveHTMLSelectChange = (event) => {
    const newActive = event.currentTarget.value;
    this.props.activeChangedHandler(newActive);
  };

  render() {
    return (
      <div>
        <div className="Padding-all--2">
          <Checkbox
            label="Active"
            checked={this.state.isChecked}
            onChange={this.handleCheckbox}
          />
          {this.state.isChecked && (
            <HTMLSelect
              className="Flex-flex"
              value={this.props.currentActive}
              onChange={this.handleActiveHTMLSelectChange}
              disabled={!this.state.isChecked}
            >
              <option value="true"> Active </option>
              <option value="false"> Inactive </option>
            </HTMLSelect>
          )}
        </div>
      </div>
    );
  }
}

export default FilterActive;
