import React, { Component } from "react";
import { Spinner, ButtonGroup, Button } from "@blueprintjs/core";
import DataTable from "../Table/DataTable";
import Form from "../../hoc/Form";
import PaginationHelper from "../../helpers/PaginationHelper";

const ContentHeader = ({ resourceName, showForm }) => {
  return (
    <div className="ContentHeader Box-root Box-background--white Box-divider--light-bottom-1 Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-direction--column">
      <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween">
        <h1>{resourceName}</h1>
        <Button onClick={showForm} icon="edit">
          Create New Admin
        </Button>
      </div>
    </div>
  );
};

class AdminTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }
  render() {
    const showFormHandler = () => {
      this.props.showFormHandler();
      this.props.getData(this.props.tableInfo.data.map((data) => data.email));
      this.setState({ show: true });
    };

    if (this.props.showForm === false && this.state.show) {
      this.props.fetchPage({
        number: 1,
        size: PaginationHelper.getPageSize()
      });
      this.setState({ show: false });
    }

    if (this.props.fetching) {
      return (
        <div className="Box-root Padding-bottom--12">
          <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
            <ContentHeader
              resourceName={this.props.resourceName}
              createNewAdmin={showFormHandler}
            />
            <div className="ContentBlock Box-root Box-background--white Padding-all--64">
              <Spinner size={26} />
            </div>
          </div>
        </div>
      );
    } else {
      let objectCount = "";

      if (this.props.pagination.size >= this.props.pagination.count) {
        objectCount =
          this.props.pagination.count + "/" + this.props.pagination.count;
      } else {
        objectCount =
          this.props.pagination.size + "/" + this.props.pagination.count;
      }

      let bodyContent;

      if (this.props.pagination.total === 0) {
        bodyContent = (
          <div className="ContentState Box-root Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--center Flex-wrap--wrap Box-divider--light-bottom-1">
            <span className="ContentState-title Text-color--gray Text-align--center Text-fontSize--14 Text-fontWeight--regular Text-lineHeight--20 Text-typeface--base Text-wrap--wrap Text-display--block">
              <span>No {this.props.resourceName} </span>
            </span>
          </div>
        );
      } else {
        bodyContent = (
          <DataTable
            columnKeys={this.props.tableInfo.columnKeys}
            headerNames={this.props.tableInfo.headerNames}
            columnTypes={this.props.tableInfo.columnTypes}
            data={this.props.tableInfo.data}
            objectLink={this.props.tableInfo.objectLink}
            menuBuilder={this.props.tableInfo.menuBuilder}
            hasLink={"false"}
          />
        );
      }

      return (
        <div className="Box-root Padding-bottom--12">
          <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
            <ContentHeader
              resourceName={this.props.resourceName}
              showForm={showFormHandler}
            />
            <div className="ContentBlock Box-root Box-background--white ">
              {bodyContent}
              <div className="Box-root Flex-flex Flex-alignItems--center Flex-justifyContent--spaceBetween">
                <div className="Box-root Padding-all--20 Flex-flex Flex-alignItems--center">
                  <span className="Text-color--default Text-fontSize--14 Text-fontWeight--regular Text-lineHeight--20 Text-typeface--base Text-wrap--wrap Text-display--inline">
                    <span>
                      <span className="Text-color--default Text-fontSize--14 Text-fontWeight--medium Text-lineHeight--20 Text-typeface--base Text-wrap--wrap Text-display--inline">
                        {objectCount}
                      </span>{" "}
                      results
                    </span>
                  </span>
                </div>
                <div className="Box-root Padding-all--20 Flex-flex Flex-alignItems--center">
                  <span className="Text-color--default Text-fontSize--14 Text-fontWeight--regular Text-lineHeight--20 Text-typeface--base Text-wrap--wrap Text-display--inline">
                    <span>
                      <span className="Text-color--default Text-fontSize--14 Text-fontWeight--medium Text-lineHeight--20 Text-typeface--base Text-wrap--wrap Text-display--inline">
                        {this.props.pagination.number}/
                        {this.props.pagination.total}
                      </span>{" "}
                      pages
                    </span>
                  </span>
                </div>
                <div className="Box-root Padding-horizontal--20 Padding-vertical--16">
                  <div className="Box-root">
                    <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--flexStart">
                      <ButtonGroup>
                        <Button
                          onClick={this.props.prevPage}
                          disabled={this.props.pagination.number === 1}
                        >
                          Prev
                        </Button>
                        <Button
                          onClick={this.props.nextPage}
                          disabled={
                            this.props.pagination.number ===
                            this.props.pagination.total
                          }
                        >
                          Next
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Form(AdminTable);
