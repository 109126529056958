import React from "react";
import CodeRow from "./CodeRow";
import DateRow from "./DateRow";
import EmptyRow from "./EmptyRow";
import StringRow from "./StringRow";
import DateHelper from "../../helpers/DateHelper";
import GoodPillRow from "./GoodPillRow";
import BadPillRow from "./BadPillRow";
import PostHelper from "../../helpers/PostHelper";
import FailedPillRow from "./FailedPillRow";
import HostRow from "./HostRow";
import EmailRow from "./EmailRow";
import StripeRow from "./StripeRow";
import ToAccountRow from "./ToAccountRow";
import ToAccountFromUserRow from "./ToAccountFromUserRow";
import ToUserFromEventsRow from "./ToUserFromEventsRow";

const RowFactory = {
  getRow: (type, label, value) => {
    if (value === undefined || value === null || value.length === 0) {
      return RowFactory.getEmptyRow(type, label, value);
    } else {
      return RowFactory.getTypeRow(type, label, value);
    }
  },
  getTypeRow: (type, label, value) => {
    switch (type) {
      case "String":
        return <StringRow label={label} value={value} />;
      case "Host":
        return <HostRow label={label} value={value} />;
      case "Stripe":
        return <StripeRow label={label} value={value} />;
      case "Email":
        return <EmailRow label={label} value={value} />;
      case "ToAccount":
        return <ToAccountRow label={label} value={value} />;
      case "ToAccountFromUser":
        return <ToAccountFromUserRow label={label} value={value} />;
      case "ToUserFromEvent":
        return <ToUserFromEventsRow label={label} value={value} />;
      case "Date":
        return (
          <DateRow label={label} value={DateHelper.getMomentDate(value)} />
        );
      case "Code":
        return <CodeRow label={label} value={value} />;
      case "Pill":
        if (value === "Preparing Revision" || value === "False") {
          return <BadPillRow label={label} value={value} />;
        } else {
          return <GoodPillRow label={label} value={value} />;
        }
      case "RevisionPill":
        if (PostHelper.whichPill(value) === "Failed") {
          return <FailedPillRow label={label} value={value} />;
        }

        if (PostHelper.whichPill(value) === true) {
          return <GoodPillRow label={label} value={value} />;
        } else {
          return <BadPillRow label={label} value={value} />;
        }
      default:
        break;
    }
  },
  getEmptyRow: (type, label, value) => {
    return <EmptyRow label={label} />;
  }
};

export default RowFactory;
