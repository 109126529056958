import React, { Component } from "react";
import NavHeader from "./NavHeader.jsx";
import Details from "./Details/Details";
import ApiHelper from "../helpers/ApiHelper.js";
import ActionHeader from "./ActionHeader/ActionHeader";
import Error from "../hoc/Error";
import EditUser from "../hoc/User/EditUser";
import ObjectFactory from "../helpers/ObjectFactory/ObjectFactory";
import FirstPromoterDetails from "./Details/FirstPromoterDetails";

class User extends Component {
  constructor(props) {
    super(props);

    const user_id = this.props.match.params.id;
    this.state = { fetched: false, user_id: user_id };
  }

  componentDidMount() {
    this.fetchUser();
  }

  fetchUser() {
    ApiHelper.getRequest("api/users/" + this.state.user_id, null)
      .then((res) => {
        const user = res.data.data;
        // UserHelper.transformAppData(user);
        this.setState({ fetched: true, user: user });
      })
      .catch((error) => {
        console.log(error);
        this.props.errorHandler(error);
      });
  }

  getPlaceholders = () => {
    if (this.state.user !== undefined) {
      const placeholders = {
        placeholderFirstName: this.state.user.f_name,
        placeholderLastName: this.state.user.l_name,
        placeholderEmail: this.state.user.email
      };

      return placeholders;
    }
  };

  updateUserDetails = (updatedUser) => {
    console.log(updatedUser);
    this.setState({ user: updatedUser });
  };

  render() {
    var Name = undefined;
    if (this.state.user !== undefined) {
      Name = this.state.user.f_name + " " + this.state.user.l_name;
    }

    console.log(this.state.user);

    return (
      <div>
        <NavHeader />

        <div className="World-container">
          <ActionHeader
            resource="User"
            onDeleteClick={() =>
              this.props.showEditUser(
                this.getPlaceholders(),
                this.updateUserDetails
              )
            }
            fetching={!this.state.fetched}
            title={Name}
            placeHolder="no name"
            user_id={this.state.user_id}
            user={this.state.user}
          />
          <Details
            data={this.state.user}
            fetching={!this.state.fetched}
            info={ObjectFactory.user.detailInfo}
            title="Details"
          />
          <FirstPromoterDetails
            data={this.state.user}
            fetching={!this.state.fetched}
            info={ObjectFactory.user.firstPromoterInfo}
            updateUserDetails={this.updateUserDetails}
            title="First Promoter"
          />
        </div>
      </div>
    );
  }
}

export default EditUser(Error(User));
