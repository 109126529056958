import AuthHelper from "./AuthHelper";
import { config } from "./../config";
import axios from "axios";

const baseUrl = config.apiUrl;

const ApiHelper = {
  getRequest: (endpoint, params) => {
    const path = ApiHelper.buildUrl(endpoint, params);
    // console.log(path);
    const response = axios.get(path, {
      headers: { Authorization: "Bearer " + AuthHelper.getUserToken() }
    });
    return response;
  },
  buildUrl: (endpoint, params) => {
    const url = `${baseUrl}${endpoint}`;
    if (params) {
      const getParams = Object.keys(params)
        .map((k) => `${k}=${encodeURIComponent(params[k])}`)
        .join("&");
      return `${url}?${getParams}`;
    }
    return url;
  },
  getParams: (params) => {
    if (params) {
      const getParams = Object.keys(params)
        .map((k) => `${k}=${encodeURIComponent(params[k])}`)
        .join("&");

      return getParams;
    }
  },
  buildPaginationParams: (pageNumber, pageSize) => {
    return { "page[number]": pageNumber, "page[size]": pageSize };
  },
  sendDeleteRequest: (endpoint) => {
    const path = `${baseUrl}${endpoint}`;
    console.log(path);
    axios
      .delete(path, {
        headers: { Authorization: "Bearer " + AuthHelper.getUserToken() }
      })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  },
  sendEditRequest: (endpoint, body) => {
    const path = `${baseUrl}${endpoint}`;

    return axios({
      method: "put",
      url: path,
      data: body,
      headers: {
        Authorization: "Bearer " + AuthHelper.getUserToken()
      }
    });
  }
};

export default ApiHelper;
