import React, { Component } from "react";
import { Spinner, Icon } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import getSorts from "./getSorts";

import DataTable from "./DataTable.jsx";

import ApiHelper from "../../helpers/ApiHelper.js";

const ContentHeader = ({ title, count, totalCount }) => (
  <div className="ContentHeader Box-root Box-background--white Box-divider--light-bottom-1 Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-direction--column">
    <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween">
      <span className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-fontWeight--medium Text-lineHeight--24 Text-typeface--base Text-wrap--wrap Text-display--inline">
        <span>{title}</span>
      </span>
      {
        <div className="Badge Box-root Box-background--blue100 Padding-horizontal--8 Padding-vertical--2 Flex-inlineFlex Flex-alignItems--center Flex-direction--row">
          <span className="Badge-text Text-color--blue600 Text-fontSize--12 Text-fontWeight--medium Text-lineHeight--16 Text-typeface--base Text-wrap--noWrap Text-display--inline">
            <span>{totalCount}</span>
          </span>
        </div>
      }
    </div>
  </div>
);

class TableSection extends Component {
  constructor(props) {
    super(props);

    const s = this.props.pageSize === undefined ? 10 : this.props.pageSize;

    this.state = {
      fetched: false,
      pagination: { number: 1, size: s },
      currentSort: getSorts.getDefaultState(props.objectName)[0],
      descending: getSorts.getDefaultState(props.objectName)[1],
      count: 0
    };

    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData(this.props);
  }

  // componentWillReceiveProps(nextProps) {
  //   console.log("comonent will receive props called");
  //   console.log(nextProps);
  //   this.fetchData(nextProps);
  // }

  componentDidUpdate() {
    if (this.props.resource_id) {
      if (this.props.resource_id.length > 0) {
        if (this.state.count < 2) {
          this.componentDidMount();
          this.setState({ count: this.state.count + 1 });
        }
      }
    }
  }

  fetchData(props) {
    const pageParams = ApiHelper.buildPaginationParams(
      1,
      this.state.pagination.size
    );
    var params = {};
    if (props.account_id === undefined) {
      params = {
        ...pageParams
      };
    } else {
      params = {
        ...pageParams,
        "filter[account_id]": props.account_id
      };
    }

    const sorts = getSorts.getSortObj(props.objectName);

    if (sorts !== undefined) {
      if (this.state.descending === true) {
        if (sorts[this.state.currentSort]["descending"] !== "") {
          params = {
            ...params,
            sort: sorts[this.state.currentSort]["descending"]
          };
        }
      } else {
        if (sorts[this.state.currentSort]["ascending"] !== "") {
          params = {
            ...params,
            sort: sorts[this.state.currentSort]["ascending"]
          };
        }
      }
    }

    if (props.resource_id !== undefined) {
      params["filter[resource_id]"] = props.resource_id;
    }

    ApiHelper.getRequest("api/" + props.objectName, params)
      .then((res) => {
        console.log(params, res.data.data.length);
        const data = res.data.data.map((obj) => {
          return props.dataTransformer(obj);
        });
        this.setState({
          fetched: true,
          data: data,
          totalCount: res.data.pagination.count
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  switchSort = () => {
    this.setState(
      (prevState) => ({
        descending: !prevState.descending
      }),
      () => this.componentDidMount(this.state.pagination, this.state.filters)
    );
  };

  switchCurrentSort = (newSort) => {
    this.setState({ currentSort: newSort }, () =>
      this.componentDidMount(this.state.pagination, this.state.filters)
    );
  };

  getSortObj = () => {
    return {
      descending: this.state.descending,
      switchSort: this.switchSort,
      currentSort: this.state.currentSort,
      switchCurrentSort: this.switchCurrentSort
    };
  };

  render() {
    if (this.state.fetched && this.state.data.length !== 0) {
      let moreLink = "";
      if (this.props.account_id) {
        moreLink =
          "/" + this.props.objectName + "/?account=" + this.props.account_id;
      }

      if (this.props.resource_id) {
        moreLink =
          "/" + this.props.objectName + "/?account=" + this.props.resource_id;
      }

      return (
        <div className="Box-root Padding-bottom--12">
          <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
            <ContentHeader
              title={this.props.title}
              count={this.state.data.length}
              totalCount={this.state.totalCount}
            />
            <div>
              <DataTable
                columnKeys={this.props.tableInfo.columnKeys}
                headerNames={this.props.tableInfo.headerNames}
                columnTypes={this.props.tableInfo.columnTypes}
                data={this.state.data}
                objectLink={this.props.tableInfo.objectLink}
                menuBuilder={this.props.tableInfo.menuBuilder}
                onClick={this.props.onClick}
                sortObj={this.getSortObj()}
              />
            </div>
            {this.state.data.length !== this.state.totalCount && (
              <div className="Box-root Box-background--white">
                <div className="Box-root Padding-all--12 Flex-flex Flex-alignItems--center Flex-justifyContent--center">
                  <Link
                    className="ButtonLink IconParent Flex-flex"
                    to={moreLink}
                  >
                    <div className="Box-root Flex-inlineFlex Flex-alignItems--center Flex-direction--rowReversed">
                      <div className="Padding-left--4">
                        <Icon icon={"arrow-right"} iconSize={12} />
                      </div>
                      <span className="ButtonLink-label Text-color--blue Text-fontSize--14 Text-fontWeight--medium Text-lineHeight--20 Text-typeface--base Text-wrap--noWrap Text-display--inline">
                        More
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else if (this.state.fetched) {
      return (
        <div className="Box-root Padding-bottom--12">
          <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
            <ContentHeader
              title={this.props.title}
              count={this.state.data.length}
              totalCount={this.state.totalCount}
            />
            <div className="ContentBlock Box-root Box-background--white">
              <div className="ContentState Box-root Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--center Flex-wrap--wrap">
                <span className="ContentState-title Text-color--gray Text-align--center Text-fontSize--14 Text-fontWeight--regular Text-lineHeight--20 Text-typeface--base Text-wrap--wrap Text-display--block">
                  <span>No {this.props.title}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="Box-root Padding-bottom--12">
          <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
            <ContentHeader title={this.props.title} />
            <div className="Box-root Padding-all--12">
              <Spinner size={26} />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default TableSection;
