import React from "react";
import LoadingBlock from "./LoadingBlock";
import ContentHeader from "./ContentHeader";
import Content from "./Content";
import NoContent from "./NoContent";

const Details = (props) => {
  if (props.fetching) {
    return <LoadingBlock title={props.title} />;
  } else {
    return (
      <div className="Box-root Padding-bottom--12">
        <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
          <ContentHeader title={props.title} />
          {props.noContent ? (
            <NoContent title={props.title} />
          ) : (
            <Content info={props.info} data={props.data} />
          )}
        </div>
      </div>
    );
  }
};

export default Details;
