import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Card,
  Elevation,
  Button,
  FormGroup,
  InputGroup
} from "@blueprintjs/core";
import AuthHelper from "../helpers/AuthHelper";
import axios from "axios";
import AppToaster from "./AppToaster";
import { config } from "./../config";

const baseUrl = config.apiUrl;

class Login extends Component {
  constructor(props) {
    super(props);

    this.attemptLogin = this.attemptLogin.bind(this);
  }

  componentDidMount() {}

  attemptLogin() {
    const email = document.getElementById("login-email-field").value;
    const password = document.getElementById("login-password-field").value;

    axios
      .post(`${baseUrl}log-in`, {
        user: { email: email, password: password }
      })
      .then((res) => {
        const jwt = res.data.jwt;
        AuthHelper.setUserToken(jwt);
        this.props.history.push("/");
      })
      .catch((error) => {
        if (error.response === undefined) {
          this.showToast(`Server down at the moment`);
        } else {
          this.showToast(
            `${error.response.data.info} (With error code: ${error.response.data.code})`
          );
        }
      });
  }

  showToast = (message) => {
    AppToaster.show({ message: message });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.attemptLogin();
    }
  };

  render() {
    return (
      <div className="Box-root Padding-bottom--12">
        <div className="Box-root Padding-all--12 Flex-flex Flex-alignItems--center Flex-justifyContent--center">
          <Card elevation={Elevation.THREE}>
            <h2 className="Margin-bottom--12 Text-align--center">
              Narrative Admin{" "}
            </h2>
            <FormGroup>
              <InputGroup
                onKeyPress={this.handleKeyPress}
                id="login-email-field"
                className="login-input Margin-bottom--4"
                type="text"
                placeholder="email"
              />
              <InputGroup
                onKeyPress={this.handleKeyPress}
                id="login-password-field"
                className="login-input Margin-bottom--8"
                type="password"
                placeholder="password"
              />
              <div className="Text-align--center Margin-top--8">
                <Button onClick={this.attemptLogin}> Login </Button>
              </div>
            </FormGroup>
          </Card>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
