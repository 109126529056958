import React, { Component } from "react";
import CellFactory from "../Cells/CellFactory";

let index = 0;

class PlanTable extends Component {
  componentWillUnmount() {
    index = 0;
  }
  getColumnType = (types, key) => {
    if (types === undefined || types[key] === undefined) {
      return "String";
    }

    return types[key];
  };

  buildColumnHeaders = (keys, names) => {
    let headers = [];

    keys.forEach((key) => {
      const columnName = names[key];
      headers.push(CellFactory.getColumnHeaderCell(columnName));
    });

    return headers;
  };

  buildRow = (keys, types, object) => {
    let rowVal = [];

    // Build one row cell by cell
    keys.forEach((key) => {
      const colType = this.getColumnType(types, key);
      const cellData = object[key];

      if (cellData !== undefined) {
        if (cellData.indexOf("%") > -1) {
          if (index === 1) {
            rowVal.push(CellFactory.getPercentageCell(cellData, true));
          } else {
            rowVal.push(CellFactory.getPercentageCell(cellData, false));
            index++;
          }
        } else {
          rowVal.push(CellFactory.getCell(colType, cellData));
        }
      }
    });

    // Build the last cell of row (Either empty or a menu)

    return rowVal;
  };

  render() {
    const cKeys = this.props.columnKeys;
    const headerVals = this.props.headerNames;
    const cTypes = this.props.columnTypes;
    const data = this.props.data;

    if (cKeys === undefined) {
      throw new Error("DataTable requires columnKeys prop");
    }
    if (headerVals === undefined) {
      throw new Error("DataTable requires headerNames prop");
    }
    if (data === undefined) {
      throw new Error("DataTable requires data prop");
    }

    // First make the header for the table

    const header = this.buildColumnHeaders(cKeys, headerVals);

    // Next get all the rows for the table

    let rows = [];

    data.forEach((data) => {
      rows.push(
        <tr className="db-ListViewItem">
          {this.buildRow(cKeys, cTypes, data)}
        </tr>
      );
    });

    index = 0;

    return (
      <div>
        <table className="StatTable">
          <thead>
            <tr className="Table-row db-ListViewItem db-ListViewItem-header">
              {header}
            </tr>
          </thead>
          <tbody className="Table-body">{rows}</tbody>
        </table>
      </div>
    );
  }
}

export default PlanTable;
