import React from "react";

const DateRow = (props) => {
  return (
    <div className="bs-PropertyList-row">
      <div className="bs-PropertyList-property">
        <span>{props.label}</span>
      </div>
      <div className="bs-PropertyList-definition">
        <span>{props.value}</span>
      </div>
    </div>
  );
};

export default DateRow;
