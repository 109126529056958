const EventHelper = {
  formatEvent: (event) => {
    const nameParts = event.topic.split("_");
    const isErr = nameParts[nameParts.length - 1] === "errd";
    const l = isErr ? nameParts.length - 1 : nameParts.length;
    const name = nameParts
      .slice(0, l)
      .map((l) => {
        return l.charAt(0).toUpperCase() + l.substr(1).toLowerCase();
      })
      .join(" ");

    event.email = "";
    if (event.user_id !== null) {
      event.email = event.user.email;
      event.accName = event.user.f_name + " " + event.user.l_name;
      event.user.user_id = event.user_id;
    }

    const source = JSON.parse(event.source);
    if (
      source === undefined ||
      source === null ||
      Object.keys(source).length === 0
    ) {
      return {
        ...event,
        key: event.id,
        topic_name: name,
        state: isErr
          ? { key: "Failed", level: "error" }
          : { key: "Ok", level: "good" }
      };
    } else {
      return {
        ...event,
        key: event.id,
        topic_name: name,
        state: isErr
          ? { key: "Failed", level: "error" }
          : { key: "Ok", level: "good" },
        ua: source.info.ua,
        origin: source.info.origin,
        path: source.info.path,
        ip: source.info.ip
      };
    }
  }
};

export default EventHelper;
