import React from "react";
import { Icon } from "@blueprintjs/core";

const StripeRow = (props) => {
  return (
    <div className="bs-PropertyList-row">
      <div className="bs-PropertyList-property">
        <span>{props.label}</span>
      </div>
      <div className="bs-PropertyList-definition">
        <a
          className="Flex-flex Flex-direction--row"
          href={`https://dashboard.stripe.com/customers/${props.value}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <span>{props.value}</span>
          <div className="Margin-left--4">
            <Icon icon="share" />
          </div>
        </a>
      </div>
    </div>
  );
};

export default StripeRow;
