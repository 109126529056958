import React from "react";
import { H5 } from "@blueprintjs/core";
import { MetaField } from "./MetaField";

const generateInformation = (data) => {
  let info;

  if (data !== undefined && data.meta) {
    info = Object.keys(data.meta).map((key) => {
      return (
        <div className="Flex-flex Flex-direction--row">
          <div className="bs-PropertyList-property">
            <span>{key}</span>
          </div>
          <div>
            <div className="bs-PropertyList-definition">
              {data.meta[key] && data.meta[key].map((meta) => <MetaField meta_data={meta} />)}
            </div>
          </div>
        </div>
      );
    });
  } else {
    return (
      <div className="ContentBlock Box-root Box-background--white">
        <div className="ContentState Box-root Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--center Flex-wrap--wrap">
          <span className="ContentState-title Text-color--gray Text-align--center Text-fontSize--14 Text-fontWeight--regular Text-lineHeight--20 Text-typeface--base Text-wrap--wrap Text-display--block">
            <span>
              No meta information found for this post. Click edit to add meta
              information.
            </span>
          </span>
        </div>
      </div>
    );
  }

  return info;
};

const MetaInformation = (props) => {
  return (
    <div className="Box-background--white">
      <div className="bs-PropertyList">
        <div className="Flex-flex Flex-direction--column">
          {generateInformation(props.data)}
        </div>
      </div>
    </div>
  );
};

export default MetaInformation;
