import React, { useState } from "react";
import LoadingBlock from "./Loading";
import TopMetaBar from "./TopMetaBar";
import MetaInformation from "./MetaInformation";
import MetaEditing from "./MetaEditing";

const MetaBlock = (props) => {
  const [editing, setEditing] = useState(false);

  if (props.fetching) {
    return <LoadingBlock title={"Metadata"} />;
  } else {
    return (
      <div className="Box-root Padding-bottom--12">
        <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
          <TopMetaBar setEditing={setEditing} editing={editing} />
          <MetaInformation data={props.data} />
          {/* {editing ? <MetaInformation data={props.data} /> : <MetaEditing />} */}
        </div>
      </div>
    );
  }
};

export default MetaBlock;
