import React, { Component } from "react";

import NavHeader from "./NavHeader.jsx";

import ActionHeader from "./ActionHeader/ActionHeader";

import ApiHelper from "../helpers/ApiHelper.js";
import PostHelper from "../helpers/PostHelper.js";
import Error from "../hoc/Error";
import PostViewer from "../hoc/PostViewer";
import DeleteHandler from "../hoc/DeleteHandler";
import ObjectFactory from "../helpers/ObjectFactory/ObjectFactory";

import Details from "./Details/Details";
import ImageTableSection from "./ImageTableSection";
import EventHelper from "../helpers/EventHelper.js";
import TableSection from "./Table/TableSection.jsx";
import MetaBlock from "../components/MetaBlock/MetaBlock";

class Post extends Component {
  constructor(props) {
    super(props);

    const post_id = this.props.match.params.id;
    this.state = {
      fetched: false,
      post_id: post_id,
      livePostRevisionExists: false,
      pendingPostRevisionExists: false,
      resource_id: ""
    };
  }

  componentDidMount() {
    this.fetchPost();
  }

  componentDidUpdate() {
    if (this.state.post_id !== this.props.match.params.id) {
      this.setState({ post_id: this.props.match.params.id }, () =>
        this.fetchPost()
      );
    }
  }

  fetchPost() {
    ApiHelper.getRequest("api/posts/" + this.state.post_id, null)
      .then((res) => {
        let post = PostHelper.formatPost(res.data.data);

        this.setState(
          {
            fetched: true,
            post: post,
            livePostRevisionExists: PostHelper.livePostRevisionExists(post),
            pendingPostRevisionExists: PostHelper.pendingPostRevisionExists(
              post
            )
          },
          () => {
            var resource_id = this.state.post_id;

            if (this.state.post !== undefined) {
              if (this.state.post.pending_revision) {
                resource_id += `,${this.state.post.pending_revision.id}`;
              }

              if (this.state.post.live_revision) {
                resource_id += `,${this.state.post.live_revision.id}`;
              }
            }

            this.setState({ resource_id: resource_id });
          }
        );
      })
      .catch((error) => {
        console.log(error);
        this.props.errorHandler(error);
      });

    ApiHelper.getRequest("api/posts/" + this.state.post_id + "/link-stub", null)
      .then((res) => this.setState({ postHTML: res.data }))
      .catch((error) => console.log(error));
  }

  render() {
    let resource_id = this.state.post_id;

    if (this.state.post !== undefined) {
      if (this.state.post.pending_revision) {
        resource_id += `,${this.state.post.pending_revision.id}`;
      }

      if (this.state.post.live_revision) {
        resource_id += `,${this.state.post.live_revision.id}`;
      }
    }

    let requiredImageCount;

    if (this.state.post) {
      if (this.state.post.live_revision) {
        requiredImageCount = this.state.post.live_revision.required_image_count;
      } else if (this.state.post.pending_revision) {
        requiredImageCount = this.state.post.pending_revision
          .required_image_count;
      }
    }

    const openFastPostViewer = () => {
      window.open(`postviewer/${this.state.post_id}`, "_blank")
    }

    return (
      <div>
        <NavHeader />

        <div className="World-container">
          <ActionHeader
            resource="Post"
            fetching={!this.state.fetched}
            title={
              this.state.post === undefined ? undefined : this.state.post.name
            }
            placeholder=""
            onPreviewPostClick={() =>openFastPostViewer()}
            onDeleteClick={this.props.showDeleteHandler}
            history={this.props.history}
            post_id={this.state.post_id}
            postHTML={this.state.postHTML}
          />
          <Details
            data={this.state.post}
            fetching={!this.state.fetched}
            info={ObjectFactory.post.postInfo}
            title="Details"
          />

          <Details
            data={this.state.post}
            fetching={!this.state.fetched}
            info={ObjectFactory.post.livePostRevisionInfo}
            title="Live Post Revision Info"
            noContent={!this.state.livePostRevisionExists}
          />
          <Details
            data={this.state.post}
            fetching={!this.state.fetched}
            info={ObjectFactory.post.pendingPostRevisionInfo}
            title="Pending Post Revision Info"
            noContent={!this.state.pendingPostRevisionExists}
          />

          <MetaBlock data={this.state.post} fetching={!this.state.fetched} />

          {this.state.fetched && (
            <ImageTableSection
              title="Image Info"
              tableInfo={ObjectFactory.post.imageTableInfo}
              data={this.state.post.image_info}
              requiredImageCount={requiredImageCount}
            />
          )}

          <TableSection
            resource_id={this.state.resource_id}
            objectName="events"
            title="Events"
            tableInfo={ObjectFactory.account.eventTableInfo}
            dataTransformer={EventHelper.formatEvent}
          />
        </div>
      </div>
    );
  }
}

export default DeleteHandler(PostViewer(Error(Post)));
