import React, { Component } from "react";
import { Position, Button, Popover } from "@blueprintjs/core";
import FilterInput from "./FilterOptions/FilterInput";
import FilterDate from "./FilterOptions/FilterDate";
import DateHelper from "../../helpers/DateHelper";
import FilterFailed from "./FilterOptions/FilterFailed";

class EventFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topicOn: false,
      dateOn: false,
      failedOn: false,
      topic: "",
      date: "",
      datePickerDate: null
    };
  }

  componentDidMount() {
    if (
      !(
        Object.entries(this.props.previousState).length === 0 &&
        this.props.previousState.constructor === Object
      )
    ) {
      this.setState(this.props.previousState);
    }
  }

  anyFiltersActive = () => {
    return !(this.state.topicOn || this.state.dateOn || this.state.failedOn);
  };

  stringIsNotEmpty = (string) => /\S/.test(string);

  updateFilterState = (currentState) => {
    var filters = {};

    if (currentState.topicOn === true && currentState.failedOn === false) {
      if (this.stringIsNotEmpty(currentState.topic)) {
        const topic = currentState.topic;
        const filteredTopic = topic.split(" ").join("_");
        filters["filter[topic]"] = filteredTopic;
      }
    }

    if (currentState.dateOn === true) {
      if (this.state.datePickerDate !== null) {
        filters["filter[inserted_at]"] = currentState.date;
      }
    }

    if (currentState.failedOn === true && currentState.topicOn === false) {
      filters["filter[topic]"] = "errd";
    }

    if (
      currentState.failedOn === true &&
      currentState.topicOn === true &&
      currentState.topic !== ""
    ) {
      if (this.stringIsNotEmpty(currentState.topic)) {
        const fileredTopicWithFailed =
          currentState.topic.split(" ").join("_") + "_errd";
        filters["filter[topic]"] = fileredTopicWithFailed;
      }
    }

    this.props.onChange(filters, this.state.datePickerDate);
  };

  topicToggledHandler = (isTopicChecked) => {
    this.setState({ topicOn: isTopicChecked });
  };

  topicChangedHandler = (newTopic) => {
    this.setState({ topic: newTopic });
  };

  dateToggledHandler = (isDateChecked) => {
    this.setState({ dateOn: isDateChecked });
  };

  dateChangedHandler = (newDate) => {
    if (newDate !== null) {
      const isoDate = DateHelper.getISODate(newDate);
      this.setState({ date: isoDate });
      this.setState({ datePickerDate: newDate });
    } else {
      this.setState({ date: null });
      this.setState({ datePickerDate: null });
    }
  };

  failedToggledHandler = (isFailedChecked) => {
    this.setState({ failedOn: isFailedChecked });
  };

  removeFilterCheckedHandler = () => {
    const newState = {
      topicOn: false,
      dateOn: false,
      failedOn: false,
      topic: "",
      date: "",
      datePickerDate: null
    };

    this.setState(newState);
    this.updateFilterState(newState);
  };

  okClickedHandler = () => {
    this.updateFilterState(this.state);
  };

  render() {
    const filterOptions = (
      <div className="Padding-all--8">
        <div className="Margin-top--8">
          <FilterFailed
            isChecked={this.state.failedOn}
            failedToggledHandler={this.failedToggledHandler}
          />
        </div>
        <div className="Margin-top--8">
          <FilterInput
            label={"Topic"}
            isChecked={this.state.topicOn}
            currentValue={this.state.topic}
            toggledHandler={this.topicToggledHandler}
            changedHandler={this.topicChangedHandler}
          />
        </div>
        <div className="Margin-top--8">
          <FilterDate
            isChecked={this.state.dateOn}
            currentDate={this.state.datePickerDate}
            dateToggledHandler={this.dateToggledHandler}
            dateChangedHandler={this.dateChangedHandler}
          />
        </div>
        <div className="Padding-all--2">
          <Button
            className="Flex-alignContent--center"
            onClick={this.okClickedHandler}
          >
            Ok
          </Button>
        </div>
      </div>
    );

    return (
      <div>
        <Popover content={filterOptions} position={Position.BOTTOM_LEFT}>
          <Button icon="filter"> Filter </Button>
        </Popover>
        <Button
          onClick={this.removeFilterClickedHandler}
          disabled={this.anyFiltersActive()}
          icon="filter-remove"
        />
      </div>
    );
  }
}

export default EventFilter;
