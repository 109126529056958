import React, { useState, useRef, useEffect } from "react";
import { InputGroup, Icon } from "@blueprintjs/core";
import ApiHelper from "../../../helpers/ApiHelper";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import AccountHelper from "../../../helpers/AccountHelper";

const searchAPI = (param) => ApiHelper.getRequest("api/search", param);
const searchAPIDebounced = AwesomeDebouncePromise(searchAPI, 300);

const useEffectSkipFirst = (fn, arr) => {
  const isFirst = useRef(true);

  useEffect(() => {
    if (isFirst.current) {
      isFirst.current = false;
      return;
    }

    fn();
  }, arr);
};

const SearchBox = (props) => {
  const [searchTerm, setSearchTerm] = useState("");

  const getData = async () => {
    if (searchTerm === "") {
      props.newSearchAccountsHandler([]);
      return;
    }
    const trimmedSearchTerm = searchTerm.trimRight();
    const param = {
      query: trimmedSearchTerm
    };

    const { data } = await searchAPIDebounced(param);
    const filteredData = data.filter((res) => res.type === "account");
    const filteredAccounts = AccountHelper.filterMergeAccountInformation(
      filteredData
    );

    props.newSearchAccountsHandler(filteredAccounts.slice(0, 10));
  };

  useEffectSkipFirst(() => {
    props.clearSelectedAccount();
    getData();
  }, [searchTerm]);

  return (
    <InputGroup
      style={{ bottom: "3px" }}
      placeholder="Search"
      rightElement={
        <Icon className="Margin-all--4" icon={"search"} iconSize="15" />
      }
      onChange={(e) => setSearchTerm(e.target.value)}
    />
  );
};

export default SearchBox;
