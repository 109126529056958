import React from "react";

const NoContent = (props) => {
  return (
    <div className="ContentBlock Box-root Box-background--white">
      <div className="ContentState Box-root Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--center Flex-wrap--wrap">
        <span className="ContentState-title Text-color--gray Text-align--center Text-fontSize--14 Text-fontWeight--regular Text-lineHeight--20 Text-typeface--base Text-wrap--wrap Text-display--block">
          <span>No {props.title}</span>
        </span>
      </div>
    </div>
  );
};

export default NoContent;
