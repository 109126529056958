const PostFactory = {
  imageTableInfo: {
    columnKeys: ["name", "image_id", "keywords", "available"],
    headerNames: {
      name: "Name",
      image_id: "Image ID",
      keywords: "Keywords",
      available: "Available"
    },
    columnTypes: {
      name: "Static",
      image_id: "Static",
      keywords: "Static",
      available: "Static"
    }
  },
  postInfo: [
    {
      title: "Post Info",
      rows: [
        { key: "user", label: "Account", type: "ToAccount" },
        { key: "id", label: "Post ID", type: "Code" },
        { key: "created_at", label: "Created", type: "Date" },
        { key: "updated_at", label: "Updated", type: "Date" }
      ]
    },
    {
      title: "Status Info",
      rows: [
        { key: "status", label: "Status", type: "Pill" },
        { key: "live", label: "Live", type: "Pill" }
      ]
    }
  ],
  livePostRevisionInfo: [
    {
      title: "Details",
      rows: [
        { key: "live_revision.id", label: "ID", type: "Code" },

        { key: "live_revision.status", label: "Status", type: "RevisionPill" },
        {
          key: "live_revision.created_at",
          label: "Created",
          type: "Date"
        },
        {
          key: "live_revision.updated_at",
          label: "Updated",
          type: "Date"
        },
        {
          key: "live_revision.required_image_count",
          label: "Required Image Count"
        }
      ]
    },
    {
      title: "",
      rows: [
        { key: "live_revision.seo_title", label: "Seo Title" },
        { key: "live_revision.keywords", label: "Keywords" },
        { key: "live_revision.meta", label: "Meta" }
      ]
    }
  ],
  pendingPostRevisionInfo: [
    {
      title: "Details",
      rows: [
        { key: "pending_revision.id", label: "ID", type: "Code" },

        {
          key: "pending_revision.status",
          label: "Status",
          type: "RevisionPill"
        },
        {
          key: "pending_revision.created_at",
          label: "Created",
          type: "Date"
        },
        {
          key: "pending_revision.updated_at",
          label: "Updated",
          type: "Date"
        },
        {
          key: "pending_revision.required_image_count",
          label: "Required Image Count"
        }
      ]
    },
    {
      title: "",
      rows: [
        { key: "pending_revision.seo_title", label: "Seo Title" },
        { key: "pending_revision.keywords", label: "Keywords" },
        { key: "pending_revision.meta", label: "Meta" }
      ]
    }
  ]
};

export default PostFactory;
