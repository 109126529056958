import React, { useState, useEffect } from "react";
import TopBar from "./TopBar";
import { Spinner } from "@blueprintjs/core";
import ReactHighmaps from "react-highcharts/ReactHighmaps";
import Highcharts from "highcharts";
import ApiHelper from "../../../helpers/ApiHelper";
import DateHelper from "../../../helpers/DateHelper";
const moment = require("moment-timezone");

const labelStyle = {
  borderWidth: 1,
  pointPadding: 0,
  borderColor: "#000000",

  color: "#f7fafc",
  dataLabels: {
    allowOverlap: true,
    textOutline: "none",
    style: {
      width: "200px",
      color: "grey",
      fontWeight: "normal",
      fontSize: "25px"
    },
    align: "center"
  }
};

const mainDataLabelStyle = {
  borderWidth: 1,
  pointPadding: 0,
  borderColor: "#000000",

  color: "#f7fafc",
  dataLabels: {
    allowOverlap: true,
    textOutline: "none",
    style: {
      width: "200px",
      color: "grey",
      fontWeight: "normal",
      fontSize: "23px"
    },
    align: "center"
  }
};

const generateColumns = (data) => {
  const { cohort_report } = data;
  const dataLength = cohort_report.length;
  const columns = [];
  for (let i = 0; i < dataLength + 1; i++) {
    const column = {
      x: i,
      y: -1,
      value: i == 0 ? "# Acc" : `Week ${i - 1}`,
      ...labelStyle
    };

    columns.push(column);
  }

  return columns;
};

const generateRows = (data) => {
  const getValue = (dataPoint) => {
    const { from_date, to_date } = dataPoint;
    const tz = moment.tz.guess();
    const fromDate = moment.utc(from_date).format("DD");
    const toDate = moment.utc(to_date).format("DD");
    const fromMonth = moment.utc(from_date).format("MMM");
    const toMonth = moment.utc(to_date).format("MMM");

    const dateString = `${fromMonth} ${fromDate} to ${toDate}`;
    return dateString;
  };
  const { cohort_report } = data;
  const dataLength = cohort_report.length;
  const rows = [];
  for (let i = 0; i < dataLength; i++) {
    const row = {
      x: -1,
      y: i,
      value: getValue(cohort_report[i]),
      ...labelStyle
    };

    rows.push(row);
  }

  return rows;
};

const generateCornerTile = () => {
  return {
    x: -1,
    y: -1,
    value: "Week Of",
    ...labelStyle
  };
};

const generateDataPoints = (data, normal, percent) => {
  const { cohort_report } = data;
  const dataLength = cohort_report.length;
  const dataPoints = [];

  // Generate number of accounts created in the ('0th' row)
  for (let i = 0; i < dataLength; i++) {
    const { new_accounts_created } = cohort_report[i];
    const tempDataPoint = {
      x: 0,
      y: i,
      value: new_accounts_created,
      ...mainDataLabelStyle
    };

    dataPoints.push(tempDataPoint);
  }

  // Generating the diagonal (staircase) of data
  for (let i = 0; i < dataLength; i++) {
    const { periods_following, new_accounts_created } = cohort_report[i];
    const periodsFollowingLength = periods_following.length;
    for (let j = 0; j < periodsFollowingLength; j++) {
      const { stats } = periods_following[j];
      const { user_logged_in, post_retrieved, account_updated_plan } = stats;

      let value;
      if (normal === true) {
        value = `${user_logged_in} | ${post_retrieved} | ${account_updated_plan}`;
      }

      if (percent === true) {
        const userLoggedInPercent = Math.round(
          (user_logged_in / new_accounts_created) * 100
        );
        const postRetrievedPercent = Math.round(
          (post_retrieved / new_accounts_created) * 100
        );

        const accountUpdatedPlan = Math.round(
          (account_updated_plan / new_accounts_created) * 100
        );

        value = `${userLoggedInPercent}% | ${postRetrievedPercent}% | ${accountUpdatedPlan}%`;
      }
      const tempDataPoint = {
        x: j + 1,
        y: i,
        value,
        ...mainDataLabelStyle
      };

      dataPoints.push(tempDataPoint);
    }
  }

  return dataPoints;
};

const options = (graphData) => {
  return {
    chart: {
      type: "heatmap",
      animation: {
        enabled: false
      },
      backgroundColor: "rgb(247, 250, 252)"
    },
    title: {
      text: ""
    },
    credits: {
      enabled: false
    },
    colorAxis: {
      min: 0,
      minColor: "#FFFFFF",
      maxColor: "#FFFFFF"
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: false
    },

    tooltip: {
      enabled: false
    },
    plotOptions: {
      heatmap: {
        animation: {
          enabled: false
        },
        states: {
          hover: {
            enabled: false
          }
        }
      },
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      }
    },
    tooltip: {
      formatter: function() {
        if (typeof this.point.value == "string") {
          const values = this.point.value
            .split("|")
            .map((x) => x.replace(/\s/g, ""));

          if (values.length == 3) {
            return (
              "<b>" +
              values[0] +
              "</b> Users Logged In <br><b>" +
              values[1] +
              "</b> Posts Retrieved <br><b>" +
              values[2] +
              "</b> Users Updated Plan <br><b>" +
              "</b>"
            );
          }

          return false;
        }

        return false;
      }
    },
    series: [
      {
        data: [...graphData],

        dataLabels: {
          enabled: true,
          textOutline: "none"
        },
        align: "center"
      }
    ]
  };
};

const fetchData = async (setData, setLoading, fromDate, toDate) => {
  const endPoint = "api/statistics/cohort";

  const param = {
    from_date: fromDate,
    to_date: toDate
  };

  setLoading(true);

  try {
    const res = await ApiHelper.getRequest(endPoint, param);
    const { data } = res;
    setData(data);
  } catch (err) {
    console.log(err);
  }

  setLoading(false);
};

const SubscriberRetentionBlock = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [graphData, setGraphData] = useState([]);
  const [fromDate, setFromDate] = useState(DateHelper.getRetentionFromDate());
  const [toDate, setToDate] = useState(DateHelper.getRetentionToDate());
  const [currentDates, setCurrentDates] = useState(
    DateHelper.getRetentionPickerDates(fromDate, toDate)
  );

  const [normal, setNormal] = useState(true);
  const [percent, setPercent] = useState(false);
  const onMeasureChanged = (measure) => {
    if (measure === "#") {
      setNormal(true);
      setPercent(false);
    } else {
      setPercent(true);
      setNormal(false);
    }
  };

  const onFromDateChanged = (date) => {
    const tz = moment.tz.guess();
    const tempFromDate = moment(date)
      .tz(tz)
      .toISOString();

    setFromDate(tempFromDate);
  };

  const onToDateChanged = (date) => {
    const tz = moment.tz.guess();
    const tempToDate = moment(date)
      .tz(tz)
      .toISOString();

    setToDate(tempToDate);
  };

  useEffect(() => {
    if (data) {
      const rows = generateRows(data);
      const cols = generateColumns(data);
      const finalData = generateDataPoints(data, normal, percent);
      const cornerCell = generateCornerTile();
      const graphData = [...rows, ...cols, cornerCell, ...finalData];
      setGraphData(graphData);
    }
  }, [data, normal, percent]);

  useEffect(() => {
    setCurrentDates(DateHelper.getRetentionPickerDates(fromDate, toDate));
    fetchData(setData, setLoading, fromDate, toDate);
  }, [fromDate, toDate]);

  if (loading) {
    return (
      <div className="Card-root Card--radius--all Card--shadow--medium statBackground">
        <div className={"Flex-grow--1"} style={props.style}>
          <div className="Flex-flex Flex-direction--column">
            <div className="Margin-top--8">
              <Spinner size={80} />;
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="Card-root Card--radius--all Card--shadow--medium statBackground Margin-top--20">
        <div className="Flex-flex Flex-direction--column">
          <div className="Flex-alignContent--center"></div>
          <TopBar
            currentDates={currentDates}
            onToDateChanged={onToDateChanged}
            onFromDateChanged={onFromDateChanged}
            normal={normal}
            percent={percent}
            onMeasureChanged={onMeasureChanged}
          />
          <ReactHighmaps config={options(graphData)} />
        </div>
      </div>
    );
  }
};

export default SubscriberRetentionBlock;
