import React from "react";
import { Link } from "react-router-dom";

const GoodPillCell = (props) => {
  return (
    <td
      className="Table-cell 
                    Table-cell--align--right 
                    Table-cell--verticalAlign--top 
                    Table-cell--width--minimized 
                    Table-cell--wrap--noWrap 
                    db-ListViewItem-cell 
                    db-ListViewItem-text"
    >
      <Link className="db-ListViewItem-link" to={props.link}>
        <div className="db-ListViewItem-cellContent Box-root Padding-all--8">
          <div className="Badge Box-root Box-background--blue100 Padding-horizontal--8 Padding-vertical--2 Flex-inlineFlex Flex-alignItems--center Flex-direction--row">
            <span className="Badge-text Text-color--blue600 Text-fontSize--12 Text-fontWeight--medium Text-lineHeight--16 Text-typeface--base Text-wrap--noWrap Text-display--inline">
              <span>{props.data.key}</span>
            </span>
          </div>
        </div>
      </Link>
    </td>
  );
};

export default GoodPillCell;
