import React, { Component } from "react";
import DataTable from "../../../components/Table/DataTable";
import { Spinner } from "@blueprintjs/core";

const ContentHeader = ({ title }) => (
  <div className="ContentHeader Box-root Box-background--white Box-divider--light-bottom-1 Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-direction--column">
    <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween">
      <span className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-fontWeight--medium Text-lineHeight--24 Text-typeface--base Text-wrap--wrap Text-display--inline">
        <span>{title}</span>
      </span>
    </div>
  </div>
);

class SearchTableSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const dataExists = this.props.data.length > 0;

    if (this.props.loading) {
      return (
        <div className="Box-root Padding-bottom--12">
          <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
            <ContentHeader title={this.props.title} />
            <div className="Box-root Padding-all--12">
              <Spinner size={26} />
            </div>
          </div>
        </div>
      );
    } else {
      if (dataExists) {
        return (
          <div className="Box-root Padding-bottom--12">
            <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
              <ContentHeader title={this.props.title} />
              <div>
                <DataTable
                  columnKeys={this.props.tableInfo.columnKeys}
                  headerNames={this.props.tableInfo.headerNames}
                  columnTypes={this.props.tableInfo.columnTypes}
                  data={this.props.data}
                  menuBuilder={this.props.tableInfo.menuBuilder}
                  selectedAccountHandler={this.props.selectedAccountHandler}
                  selectedAccount={this.props.selectedAccount}
                />
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="Box-root Padding-bottom--12">
            <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
              <ContentHeader title={this.props.title} />
              <div className="ContentBlock Box-root Box-background--white">
                <div className="ContentState Box-root Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--center Flex-wrap--wrap">
                  <span className="ContentState-title Text-color--gray Text-align--center Text-fontSize--14 Text-fontWeight--regular Text-lineHeight--20 Text-typeface--base Text-wrap--wrap Text-display--block">
                    <span>No {this.props.title}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

export default SearchTableSection;
