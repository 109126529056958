import React from "react";
import { Button } from "@blueprintjs/core";
import classNames from "classnames";

const SegmentButton = (props) => {
  return (
    <Button className="" onClick={() => props.onChanged(props.segment)}>
      <span
        className={classNames({
          "Text-color--blue": props.clicked === true
        })}
      >
        {props.segment}
      </span>
    </Button>
  );
};

export default SegmentButton;
