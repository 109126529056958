import React, { Component } from "react";
import { Checkbox, InputGroup } from "@blueprintjs/core";

class FilterEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: this.props.isChecked
    };
  }

  componentDidMount() {
    this.setState({ isChecked: this.props.isChecked });
  }

  handleCheckbox = () => {
    this.setState(
      (prevState) => ({
        isChecked: !prevState.isChecked
      }),
      () => this.props.emailToggledHandler(this.state.isChecked)
    );
  };

  handleEmailChange = (event) => {
    const newEmail = event.target.value;
    this.props.emailChangedHandler(newEmail);
  };

  render() {
    return (
      <div>
        <div className="Padding-all--2">
          <Checkbox
            label="Email"
            checked={this.state.isChecked}
            onChange={this.handleCheckbox}
          />
          {this.state.isChecked && (
            <InputGroup
              type="email"
              placeholder="email"
              value={this.props.currentEmail}
              onChange={this.handleEmailChange}
            />
          )}
        </div>
      </div>
    );
  }
}

export default FilterEmail;
