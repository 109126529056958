import React from "react";
import { Button } from "@blueprintjs/core";
import classNames from "classnames";

const DateButton = (props) => {
  return (
    <Button className="" onClick={() => props.onChanged(props.measure)}>
      <span
        className={classNames({
          "Text-color--blue": props.clicked === true
        })}
      >
        {props.measure}
      </span>
    </Button>
  );
};

export default DateButton;
