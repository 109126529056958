import React from "react";

const EmptyColumnHeaderCell = (props) => {
  return (
    <td id="amount" className="db-ListViewItem-cell">
      <div className="db-ListViewItem-cellContent Box-root Padding-all--8" />
    </td>
  );
};

export default EmptyColumnHeaderCell;
