const types = {
  0: "Generic",
  1: "Squarespace",
  2: "Wordpress",
  3: "Wordpress Auto"
};

const statuses = {
  0: "Ok",
  1: "No Plugin",
  2: "Bad Secret",
  3: "Bad Access"
};

const AccountHelper = {
  formatAccount: (account) => {
    let newActive = "";
    if (account.active !== undefined) {
      newActive = account.active.toString();
    }

    if (account.plan_id.split("_").length === 2) {
      account.plan_name = account.plan_id
        .split("_")
        .filter((l) => {
          return l !== "narrative";
        })
        .map((l) => {
          return (
            l.charAt(0).toUpperCase() + l.substr(1).toLowerCase() + " Month"
          );
        })
        .join(" ");
    } else {
      account.plan_name = account.plan_id
        .split("_")
        .filter((l) => {
          return l !== "narrative";
        })
        .map((l) => {
          return l.charAt(0).toUpperCase() + l.substr(1).toLowerCase();
        })
        .join(" ");
    }

    let name = "";
    let email = "";

    if (account["user"]) {
      name = account["user"]["f_name"] + " " + account["user"]["l_name"];
      email = account["user"]["email"];
      account["name"] = name;
      account["email"] = email;
    }

    if (account["publisher"]) {
      account["host"] = account["publisher"]["host"];
    }

    return {
      ...account,
      account_id: account.id,
      key: account.id,
      status: AccountHelper.statusToPill(account.active, account.status),
      currStatus: account.status,
      active: newActive
    };
  },
  getAccountPublisher: (publisher) => {
    let accountPublisher = {
      ...publisher
    };

    if (accountPublisher.categories !== null) {
      accountPublisher.categories = accountPublisher.categories.join(", ");
    }
    accountPublisher.type = types[accountPublisher.type];
    accountPublisher.status = statuses[accountPublisher.status];

    return accountPublisher;
  },
  statusToPill: (active, status) => {
    if (active && AccountHelper.isStatusNothing(status)) {
      return { key: "Active", level: "good" };
    } else if (!active && AccountHelper.isStatusNothing(status)) {
      return { key: "In Active", level: "error" };
    } else {
      // we will check for a single status,
      // de-escalating the severity of the status thus,
      // if there are multiple status' only the worst is shown
      if (AccountHelper.isStatusPendingDeletion(status)) {
        return { key: "Pending Deletion", level: "error" };
      } else if (AccountHelper.isStatusSuspiciousActivity(status)) {
        return { key: "Suspicious Activity", level: "error" };
      } else if (AccountHelper.isStatusViolatedTerms(status)) {
        return { key: "Violated Terms", level: "error" };
      } else if (AccountHelper.isStatusViolatedFairUsePolicy(status)) {
        return { key: "Subscription Suspended", level: "error" };
      } else if (AccountHelper.isStatusSubscriptionSuspended(status)) {
        return { key: "Subscription Suspended", level: "error" };
      } else if (AccountHelper.isStatusCannotChargeCard(status)) {
        return { key: "Cannot Charge Card", level: "warning" };
      }
    }
  },
  isStatusNothing: (status) => {
    return status === 0;
  },
  isStatusCannotChargeCard: (status) => {
    return (status & (1 << 0)) === 1 << 0;
  },
  isStatusSubscriptionSuspended: (status) => {
    return (status & (1 << 1)) === 1 << 1;
  },
  isStatusViolatedFairUsePolicy: (status) => {
    return (status & (1 << 2)) === 1 << 2;
  },
  isStatusViolatedTerms: (status) => {
    return (status & (1 << 3)) === 1 << 3;
  },
  isStatusSuspiciousActivity: (status) => {
    return (status & (1 << 4)) === 1 << 4;
  },
  isStatusPendingDeletion: (status) => {
    return (status & (1 << 5)) === 1 << 5;
  },
  getInitialStatusState: (currStatus) => {
    let initialState = {
      cannotChargeCardChecked: false,
      subscriptionSuspendedChecked: false,
      violatedFairUsePolicyChecked: false,
      violatedTermsChecked: false,
      suspiciousActivityChecked: false,
      pendingDeletionChecked: false
    };

    if (AccountHelper.isStatusCannotChargeCard(currStatus) === true) {
      initialState.cannotChargeCardChecked = true;
    }

    if (AccountHelper.isStatusSubscriptionSuspended(currStatus) === true) {
      initialState.subscriptionSuspendedChecked = true;
    }

    if (AccountHelper.isStatusViolatedFairUsePolicy(currStatus) === true) {
      initialState.violatedFairUsePolicyChecked = true;
    }

    if (AccountHelper.isStatusViolatedTerms(currStatus) === true) {
      initialState.violatedTermsChecked = true;
    }

    if (AccountHelper.isStatusSuspiciousActivity(currStatus) === true) {
      initialState.suspiciousActivityChecked = true;
    }

    if (AccountHelper.isStatusPendingDeletion(currStatus) === true) {
      initialState.pendingDeletionChecked = true;
    }

    return initialState;
  },
  getNewStatus: (state) => {
    let status = 0;

    if (state.cannotChargeCardChecked === true) {
      status = status | (1 << 0);
    }

    if (state.subscriptionSuspendedChecked === true) {
      status = status | (1 << 1);
    }

    if (state.violatedFairUsePolicyChecked === true) {
      status = status | (1 << 2);
    }

    if (state.violatedTermsChecked === true) {
      status = status | (1 << 3);
    }

    if (state.suspiciousActivityChecked === true) {
      status = status | (1 << 4);
    }

    if (state.pendingDeletionChecked === true) {
      status = status | (1 << 5);
    }

    return status;
  },
  getInitialActiveState: (currActive) => {
    if (currActive === "true") {
      return {
        currentActive: "Active"
      };
    } else {
      return {
        currentActive: "Inactive"
      };
    }
  },
  getInitialPlan: (account) => {
    const monthlyPlans = [
      "narrative_free",
      "narrative_lite",
      "narrative_basic",
      "narrative_pro"
    ];

    const yearlyPlans = [
      "narrative_lite_year",
      "narrative_basic_year",
      "narrative_pro_year"
    ];

    if (monthlyPlans.includes(account.plan_id)) {
      const getPlan = account.plan_id
        .split("_")
        .filter((w) => w !== "narrative")
        .map((l) => l.charAt(0).toUpperCase() + l.substr(1).toLowerCase());

      return {
        currentActive: getPlan[0]
      };
    }

    if (yearlyPlans.includes(account.plan_id)) {
      const getPlan = account.plan_id
        .split("_")
        .filter((w) => w !== "narrative" && w !== "year")
        .map((l) => l.charAt(0).toUpperCase() + l.substr(1).toLowerCase());

      return {
        currentActive: getPlan[0],
        yearlyChecked: true
      };
    }
  },
  getInitialState: (currStatus, currActive) => {
    const currentStatus = AccountHelper.getInitialStatusState(currStatus);
    const currentActive = AccountHelper.getInitialActiveState(currActive);

    const initialState = {
      ...currentStatus,
      ...currentActive
    };

    return initialState;
  },
  getNewActive: (state) => {
    if (state.currentActive === "Active") {
      return true;
    } else {
      return false;
    }
  },
  getBody: (state) => {
    return {
      account: {
        active: AccountHelper.getNewActive(state),
        status: AccountHelper.getNewStatus(state)
      }
    };
  },
  getUpdatedAccount: (res) => {
    const newActive = res.data.data.active.toString();
    const newStatus = res.data.data.status;

    const updatedAccount = {
      active: newActive,
      currStatus: newStatus
    };

    return updatedAccount;
  },
  getAccountDetailsForMerge: (account) => {
    return {
      f_name: account.user.f_name,
      l_name: account.user.l_name,
      email: account.user.email,
      plan_name: account.plan_name,
      id: account.id
    };
  },
  filterMergeAccountInformation: (accounts) => {
    const accountData = accounts
      .map((account) => account.data)
      .map((account) => {
        let plan;
        if (AccountHelper.getInitialPlan(account) === undefined) {
          plan = "No Plan";
        } else {
          const planType = AccountHelper.getInitialPlan(account);

          plan = planType.currentActive;

          if (planType.yearlyChecked === true) {
            plan += " Year";
          }
        }
        return {
          f_name: account.user.f_name,
          l_name: account.user.l_name,
          email: account.user.email,
          plan_name: plan,
          id: account.id
        };
      });

    return accountData;
  }
};

export default AccountHelper;
