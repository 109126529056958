import Cookies from "js-cookie";

const PaginationHelper = {
  setPageSize: (size) => {
    Cookies.set("page_size", size);
  },
  getPageSize: () => {
    const c = Cookies.get("page_size");
    return c === undefined ? 50 : c;
  }
};

export default PaginationHelper;
