import React, { Component } from "react";
import CellFactory from "../Cells/CellFactory";

class DataTable extends Component {
  getColumnType = (types, key) => {
    if (types === undefined || types[key] === undefined) {
      return "String";
    }

    return types[key];
  };

  getLink = (object) => {
    if (!this.props.objectLink) {
      return {
        handler: this.props.selectedAccountHandler,
        selectedAccount: this.props.selectedAccount
      };
    }

    let link = "";
    if (object["key"] !== undefined) {
      link = "/" + this.props.objectLink + "/" + object["key"];
    } else {
      switch (this.props.objectLink) {
        case "accounts":
          const id = object["account_id"] || object["id"];
          link = "/" + this.props.objectLink + "/" + id;
          break;
        case "posts":
          link = "/" + this.props.objectLink + "/" + object["id"];
          break;
        case "events":
          break;
        default:
          break;
      }
    }

    return link;
  };

  buildColumnHeaders = (keys, names, sortObj) => {
    let headers = [];

    keys.forEach((key) => {
      const actualColName = key;
      const columnName = names[key];
      if (sortObj) {
        if (key === sortObj.currentSort) {
          headers.push(
            CellFactory.getColumnHeaderCell(columnName, sortObj, actualColName)
          );
        } else {
          headers.push(
            CellFactory.getSortableColumnHeaderCell(
              columnName,
              sortObj,
              actualColName
            )
          );
        }
      } else {
        headers.push(CellFactory.getColumnHeaderCell(columnName));
      }
    });

    if (this.props.menuBuilder !== undefined) {
      headers.push(CellFactory.getEmptyColumnHeaderCell());
    }

    return headers;
  };

  buildRow = (keys, types, object) => {
    let rowVal = [];

    // Build one row cell by cell
    keys.forEach((key) => {
      const colType = this.getColumnType(types, key);
      const cellData = object[key];
      const link = this.getLink(object);

      rowVal.push(CellFactory.getCell(colType, cellData, link));
    });

    // Build the last cell of row (Either empty or a menu)
    if (this.props.menuBuilder !== undefined) {
      const infoMenu = this.props.menuBuilder(object, this.props.onClick);
      rowVal.push(CellFactory.getActionCell(infoMenu));
    }

    return rowVal;
  };

  render() {
    const cKeys = this.props.columnKeys;
    const headerVals = this.props.headerNames;
    const cTypes = this.props.columnTypes;
    const data = this.props.data;

    if (cKeys === undefined) {
      throw new Error("DataTable requires columnKeys prop");
    }
    if (headerVals === undefined) {
      throw new Error("DataTable requires headerNames prop");
    }
    if (data === undefined) {
      throw new Error("DataTable requires data prop");
    }

    // First make the header for the table

    const header = this.buildColumnHeaders(
      cKeys,
      headerVals,
      this.props.sortObj
    );

    // Next get all the rows for the table

    let rows = [];

    if (this.props.hasLink === "false") {
      data.forEach((data) => {
        rows.push(
          <tr className="Table-row db-ListViewItem bs-ActionsParent BadgeParent IconParent">
            {this.buildRow(cKeys, cTypes, data)}
          </tr>
        );
      });
    } else {
      data.forEach((data) => {
        rows.push(
          <tr className="Table-row db-ListViewItem bs-ActionsParent BadgeParent IconParent db-ListViewItem--hasLink">
            {this.buildRow(cKeys, cTypes, data)}
          </tr>
        );
      });
    }

    return (
      <div>
        <table className="Table">
          <thead className="Table-head">
            <tr className="Table-row db-ListViewItem db-ListViewItem-header">
              {header}
            </tr>
          </thead>
          <tbody className="Table-body">{rows}</tbody>
        </table>
      </div>
    );
  }
}

export default DataTable;
