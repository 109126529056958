import React from "react";
import { DateInput } from "@blueprintjs/datetime";
import DateHelper from "../../../helpers/DateHelper";
import { ButtonGroup, Button, Popover, Icon } from "@blueprintjs/core";
import classNames from "classnames";

var moment = require("moment-timezone");

const MeasureButton = (props) => {
  return (
    <Button className="" onClick={() => props.onChanged(props.measure)}>
      <span
        className={classNames({
          "Text-color--blue": props.clicked === true
        })}
      >
        {props.measure}
      </span>
    </Button>
  );
};

const TopBar = (props) => {
  return (
    <div className="Box-background--white Padding-top--12 Padding-bottom--16 Padding-horizontal--20 Text-color--white ">
      <div className="Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween">
        <div>
          <span className="Text-lineHeight--40 Text-fontWeight--medium Text-fontSize--32 Text-color--black">
            Subscriber Retention
          </span>
        </div>

        <div className="Margin-top--2 Flex-flex Flex-direction--row">
          <ButtonGroup
            className="Card-root Card--radius--all Card--shadow--medium Box-background--white"
            minimal
          >
            <MeasureButton
              measure="#"
              clicked={props.normal}
              onChanged={props.onMeasureChanged}
            />
            <MeasureButton
              measure="%"
              clicked={props.percent}
              onChanged={props.onMeasureChanged}
            />
          </ButtonGroup>
        </div>

        <div className="Flex-flex Flex-direction--row Margin-top--4">
          <div>
            <span className="Text-lineHeight--40 Text-fontWeight--medium Text-fontSize--14 Text-color--black">
              From
            </span>
          </div>

          <div className="Margin-left--8 Margin-top--2">
            <DateInput
              formatDate={(date) => moment(date).format("LL")}
              onChange={(e) => props.onFromDateChanged(e)}
              parseDate={(str, locale) => moment(str, "LL").toDate()}
              placeholder={"M/D/YYYY"}
              value={props.currentDates[0]}
            />
          </div>

          <div className="Margin-left--8">
            <span className="Text-lineHeight--40 Text-fontWeight--medium Text-fontSize--14 Text-color--black">
              To
            </span>
          </div>

          <div className="Margin-left--8 Margin-top--2">
            <DateInput
              formatDate={(date) => moment(date).format("LL")}
              onChange={(e) => props.onToDateChanged(e)}
              parseDate={(str) => new Date(str)}
              placeholder={"M/D/YYYY"}
              value={props.currentDates[1]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
