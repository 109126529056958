import React from "react";
import { Tag } from "@blueprintjs/core";
import StatusHelper from "./StatusHelper";
import DateHelper from "./DateHelper";
import PlanHelper from "./PlanHelper";

const filterHelper = {
  getActiveFilters: (filters) => {
    if (filters != null) {
      if (
        Object.entries(filters).length === 0 &&
        filters.constructor === Object
      ) {
        return null;
      }
    }

    const activeFilters = [];
    const formattedFilters = {};
    if (filters != null) {
      Object.entries(filters).forEach(([key, value]) => {
        if (key === "filter[plan_id]") {
          formattedFilters.plan_id = `Plan: ${PlanHelper.getPlan(value)}`;
        }

        if (key === "filter[status]") {
          formattedFilters.status = `Status: ${StatusHelper.getStatus(value)}`;
        }

        if (key === "filter[active]") {
          if (value === "true") {
            formattedFilters.active = `Active Accounts`;
          }

          if (value === "false") {
            formattedFilters.inactive = `Inactive Accounts`;
          }
        }

        if (key === "filter[inserted_at]") {
          formattedFilters.inserted_at = `On or after ${DateHelper.formatDateWithoutTime(
            value
          )}`;
        }

        if (key === "filter[email]") {
          formattedFilters.email = `Email: ${value}`;
        }

        if (key === "filter[topic]") {
          if (value === "errd") {
            formattedFilters.topicx = `Showing: Failed Events`;
          } else {
            if (value.substr(value.length - 4) === "errd") {
              formattedFilters.topic = `Topic: ${value
                .slice(0, -4)
                .split("_")
                .join(" ")}`;
              formattedFilters.topicx = `Showing: Failed Events`;
            } else {
              formattedFilters.topic = `Topic: ${value.split("_").join(" ")}`;
            }
          }
        }
      });
    }

    Object.values(formattedFilters).forEach((value) =>
      activeFilters.push(
        <div className="row">
          <Tag icon="filter-keep" round="true">
            {value}
          </Tag>
        </div>
      )
    );

    return activeFilters;
  },
  getFilterState: (filters) => {
    const state = {};

    if (filters != null) {
      Object.entries(filters).forEach(([key, value]) => {
        if (key === "filter[plan_id]") {
          state.planIDOn = true;
          state.planID = value;
        }

        if (key === "filter[status]") {
          state.statusOn = true;
          state.status = value;
        }

        if (key === "filter[active]") {
          state.activeOn = true;
          state.active = value;
        }

        if (key === "filter[inserted_at]") {
          state.dateOn = true;
          state.date = value;
        }

        if (key === "filter[email]") {
          state.emailOn = true;
          state.email = value;
        }

        if (key === "filter[topic]") {
          if (value === "errd") {
            state.failedOn = true;
          } else {
            state.topicOn = true;

            if (value.substr(value.length - 4) === "errd") {
              state.failedOn = true;
              state.topic = value
                .slice(0, -5)
                .split("_")
                .join(" ");
            } else {
              state.topic = value.split("_").join(" ");
            }
          }
        }
      });
    }

    return state;
  }
};

export default filterHelper;
