import React, { Component } from "react";
import NavHeader from "./NavHeader.jsx";
import PageableTable from "./Table/PageableTable.jsx";

import ApiHelper from "../helpers/ApiHelper.js";
import Error from "../hoc/Error";

class Coupons extends Component {
  constructor(props) {
    super(props);

    this.state = { fetched: false, pagination: { number: 1, size: 8 } };

    this.fetchCurrentPage = this.fetchCurrentPage.bind(this);
    this.incrementPage = this.incrementPage.bind(this);
    this.decrementPage = this.decrementPage.bind(this);
  }

  componentDidMount() {
    this.fetchCurrentPage(this.state.pagination);
  }

  fetchCurrentPage(pagination) {
    ApiHelper.getRequest(
      "api/coupons",
      ApiHelper.buildPaginationParams(pagination.number, pagination.size)
    )
      .then((res) => {
        const pagination = res.data.pagination;
        const coupons = res.data.data.map((coupon) => {
          return {
            ...coupon,
            key: coupon.coupon_id,
            plan_id: coupon.plan_id
              .split("_")
              .filter((l) => {
                return l !== "narrative";
              })
              .map((l) => {
                return l.charAt(0).toUpperCase() + l.substr(1).toLowerCase();
              })
              .join(" ")
          };
        });
        this.setState({
          fetched: true,
          coupons: coupons,
          pagination: pagination
        });
      })
      .catch((error) => {
        console.log(error);
        // Enable the line of code below when coupons work on the server side
        this.props.errorHandler(error);
      });
  }

  incrementPage() {
    if (this.state.pagination.total === this.state.pagination.number) return;
    const newPage = {
      ...this.state.pagination,
      number: this.state.pagination.number + 1
    };
    this.setState({ fetched: false, pagination: newPage });
    this.fetchCurrentPage(newPage);
  }

  decrementPage() {
    if (this.state.pagination.number === 1) return;
    const newPage = {
      ...this.state.pagination,
      number: this.state.pagination.number - 1
    };
    this.setState({ fetched: false, pagination: newPage });
    this.fetchCurrentPage(newPage);
  }

  render() {
    return (
      <div>
        <NavHeader />
        <div className="World-container">
          <PageableTable
            className="container-box"
            fetching={!this.state.fetched}
            pagination={this.state.pagination}
            resourceName="Coupons"
            nextPage={this.incrementPage}
            prevPage={this.decrementPage}
            headerColomns={{
              id: "id",
              discount: "discount",
              period: "period",
              duration: "duration",
              maxRedemption: "Max Redemption",
              firstPromoter: "first promoter"
            }}
            data={this.state.accounts}
          />
        </div>
      </div>
    );
  }
}

export default Error(Coupons);
