import React from "react";

const DefaultTitle = (props) => {
  return (
    <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween">
      <div className="ContentHeader-center Box-root Flex-flex Flex-direction--column Flex-justifyContent--center">
        <span className="ContentHeader-title Text-color--dark Text-fontSize--20 Text-fontWeight--medium Text-lineHeight--24 Text-typeface--base Text-wrap--wrap Text-display--inline">
          {props.props.title !== undefined && <span>{props.props.title}</span>}
          {props.props.title === undefined && (
            <span>{props.props.placeholder}</span>
          )}
        </span>
      </div>
    </div>
  );
};

export default DefaultTitle;
