import React from "react";

const allPlans = {
  Pro: "Pro",
  narrative_Pro_Year: "Pro Year",
  Basic: "Basic",
  narrative_Basic_Year: "Basic Year",
  Lite: "Lite",
  narrative_Lite_Year: "Lite Year",
  Free_4: "Free 4"
};

const planHelper = {
  getPlans: () => {
    let plans = [];

    Object.entries(allPlans).forEach(([key, value]) =>
      plans.push(<option value={key}> {value} </option>)
    );

    return plans;
  },
  getPlan: (key) => {
    return allPlans[key];
  }
};

export default planHelper;
