import React from "react";
import { Spinner } from "@blueprintjs/core";

const Loading = (props) => {
  return (
    <div className="Box-root Padding-bottom--12">
      <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
        <div className="ContentHeader Box-root Box-background--white Box-divider--light-bottom-1 Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-direction--column">
          <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween">
            <div className="ContentHeader-center Box-root Flex-flex Flex-direction--column Flex-justifyContent--center">
              <span className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-fontWeight--medium Text-lineHeight--24 Text-typeface--base Text-wrap--wrap Text-display--inline">
                <span>Metadata</span>
              </span>
            </div>
          </div>
        </div>
        <div className="ContentBlock Box-root Box-background--white">
          <div className="bs-PropertyList">
            <Spinner size={26} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
