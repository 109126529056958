import React, { Component } from "react";
import Aux from "./Aux";
import { Button, Classes, H3, Intent, Overlay } from "@blueprintjs/core";
import classNames from "classnames";
import AuthHelper from "../helpers/AuthHelper";
import { createHashHistory } from "history";

const Error = (WrappedComponent) => {
  return class extends Component {
    state = {
      error: null,
      errorMessage: "",
      errorCode: ""
    };

    setErrorHandler = (error) => {
      if (
        error === undefined ||
        error.response === undefined ||
        error.response.data === undefined
      ) {
        // Do something here
        this.setState(
          {
            error: true,
            errorMessage: "Server Down"
          },
          () => {
            AuthHelper.removeUserToken();
            const history = createHashHistory();
            history.replace({ pathname: "/login" });
          }
        );
      } else {
        this.setState({
          error: error.response,
          errorMessage:
            error.response.data.info !== undefined
              ? error.response.data.info
              : error.response.statusText,
          errorCode:
            error.response.data.code !== undefined
              ? error.response.data.code
              : error.response.status
        });
      }
    };

    closedErrorHandler = () => {
      this.setState({
        error: null,
        errorMessage: "",
        errorCode: ""
      });
    };

    render() {
      const classes = classNames(
        Classes.CARD,
        Classes.ELEVATION_4,
        "Text-align--center",
        "container"
      );

      const errorModal = (
        <Overlay
          isOpen={this.state.error}
          onClose={this.handleClose}
          className={Classes.OVERLAY_SCROLL_CONTAINER}
          usePortal
          hasBackdrop
          {...this.state}
        >
          <div className={classes}>
            <H3> Error: {this.state.errorCode} </H3>
            <p>
              <b> Error Message: </b> {this.state.errorMessage}
            </p>

            <br />
            <div className="Text-align--center">
              <Button intent={Intent.DANGER} onClick={this.closedErrorHandler}>
                Close
              </Button>
            </div>
          </div>
        </Overlay>
      );

      return (
        <Aux>
          {errorModal}
          <WrappedComponent
            {...this.props}
            errorHandler={this.setErrorHandler}
          />
        </Aux>
      );
    }
  };
};

export default Error;
