import MenuBuilder from "./MenuBuilder.js";

const SearchFactory = {
  accountTableInfo: {
    columnKeys: ["name", "host", "email", "plan_id"],
    headerNames: {
      name: "Name",
      host: "Host",
      email: "Email",
      plan_id: "Plan"
    },
    columnTypes: {},
    objectLink: "accounts",
    menuBuilder: MenuBuilder.accountMenu
  },
  postTableInfo: {
    columnKeys: ["name", "status"],
    headerNames: {
      name: "Name",
      status: "Status"
    },
    columnTypes: {},
    objectLink: "posts",
    menuBuilder: MenuBuilder.postMenu
  },
  eventTableInfo: {
    columnKeys: ["topic", "occurred_at", "transaction_id"],
    headerNames: {
      topic: "Title",
      occurred_at: "Occurred At",
      transaction_id: "Transaction ID"
    },
    columnTypes: {},
    objectLink: "events",
    menuBuilder: MenuBuilder.postMenu
  },
  mergeInfo: {
    columnKeys: ["f_name", "l_name", "email"],
    headerNames: {
      f_name: "First Name",
      l_name: "Last Name",
      email: "Email"
    },
    columnTypes: {
      f_name: "SelectAcc",
      l_name: "SelectAcc",
      email: "SelectAcc"
    }
  }
};

export default SearchFactory;
