import React from "react";
import SearchTableSection from "./SearchTableSection";
import ObjectFactory from "../../../helpers/ObjectFactory/ObjectFactory";

const SearchResults = (props) => {
  return (
    <SearchTableSection
      title={"Accounts"}
      loading={false}
      tableInfo={ObjectFactory.search.mergeInfo}
      data={props.results}
      selectedAccountHandler={props.selectedAccountHandler}
      selectedAccount={props.selectedAccount}
    />
  );
};

export default SearchResults;
