import React, { Component } from "react";
import Aux from "../Aux";
import classNames from "classnames";
import {
  Button,
  Classes,
  Intent,
  Overlay,
  H5,
  FormGroup,
  Icon,
  H4,
  Spinner,
  Divider,
  Alignment,
  Radio,
  RadioGroup
} from "@blueprintjs/core";
import SearchBox from "./Search/SearchBox";
import SearchResults from "./Search/SearchResults";
import Header from "./Header";

const initialState = {
  accountConfirmed: false,
  editConfirmed: false,
  editLoading: false,
  editSuccess: false,
  editError: false,
  showForm: false,
  accountDetails: null,
  searchAccounts: null,
  selectedAccount: []
};

const MergeAccount = (WrappedComponent) => {
  return class extends Component {
    state = {
      accountConfirmed: false,
      editConfirmed: false,
      editLoading: false,
      editSuccess: false,
      editError: false,
      showForm: false,
      accountDetails: null,
      searchAccounts: null,
      selectedAccount: []
    };

    closeFormValid = () => {
      return !this.state.editLoading === true;
    };

    showMergeAccount = (accountDetails) => {
      console.log(accountDetails);
      this.setState({
        showForm: true,
        accountDetails: accountDetails
      });
    };

    closeMergeAccount = () => {
      this.setState({ showForm: false });
      this.setState(initialState);
    };

    submitHandler = () => {
      this.setState({
        editLoading: true,
        editConfirmed: true
      });
    };

    newSearchAccountsHandler = (searchAccounts) => {
      this.setState({ searchAccounts: searchAccounts });
    };

    selectedAccountHandler = (selectedAccount) => {
      const getAcc = this.state.searchAccounts.filter(
        (acc) =>
          acc.f_name === selectedAccount ||
          acc.l_name === selectedAccount ||
          acc.email === selectedAccount
      )[0];
      this.setState({ selectedAccount: getAcc });
    };

    clearSelectedAccount = () => {
      this.setState({ selectedAccount: [] });
    };

    continueHandler = () => {
      this.setState({ accountConfirmed: true });
    };

    render() {
      console.log(this.state.selectedAccount);
      console.log(this.state.accountDetails);
      let accountDetails;
      let selectedAccountDetails;
      if (this.state.accountDetails) {
        accountDetails = Object.keys(this.state.accountDetails)
          .filter((x) => x !== "id")
          .map((x) => this.state.accountDetails[x])
          .map((x) => {
            return (
              <input type="radio" name="gender" value={x}>
                {x}
              </input>
            );
          });
      }

      if (this.state.selectedAccount) {
        selectedAccountDetails = Object.keys(this.state.selectedAccount)
          .filter((x) => x !== "id")
          .map((x) => this.state.selectedAccount[x])
          .map((x) => {
            return (
              <input type="radio" name="gender" value={x}>
                {x}
              </input>
            );
          });
      }

      const classes = classNames(
        Classes.CARD,
        Classes.ELEVATION_4,
        "Text-align--center",
        "container"
      );

      const formContent = (
        <div className={classes}>
          <FormGroup
            label={
              <span className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-fontWeight--medium Text-lineHeight--24 Text-typeface--base Text-wrap--wrap Text-display--inline">
                <span> Merging Account: </span>
              </span>
            }
            labelFor="text-input"
          >
            <div className="Flex-flex Flex-direction--column">
              <div className="Margin-top--12" />
              <SearchBox
                newSearchAccountsHandler={this.newSearchAccountsHandler}
                clearSelectedAccount={this.clearSelectedAccount}
              />
              <div className="Margin-top--12" />
              {this.state.searchAccounts && (
                <SearchResults
                  results={this.state.searchAccounts}
                  selectedAccountHandler={this.selectedAccountHandler}
                  selectedAccount={this.state.selectedAccount}
                />
              )}
            </div>
          </FormGroup>

          <br />
          <div className="Text-align--center">
            <Button intent={Intent.DANGER} onClick={this.closeMergeAccount}>
              Close
            </Button>
            <Button
              className="Margin-left--12"
              intent={Intent.PRIMARY}
              onClick={this.continueHandler}
              disabled={this.state.selectedAccount.length === 0}
            >
              Continue
            </Button>
          </div>
        </div>
      );

      const nextFormContent = (
        <div className={classes}>
          <FormGroup
            label={
              <span className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-fontWeight--medium Text-lineHeight--24 Text-typeface--base Text-wrap--wrap Text-display--inline">
                <span> Merging Account: </span>
              </span>
            }
            labelFor="text-input"
          >
            <div className="Flex-flex Flex-direction--column">
              <div className="Margin-top--12" />
              <div className="Flex-flex Flex-direction--row">
                <div className="Flex-flex Flex-direction--column">
                  <Header title="Main Account" />
                  <div className="Margin-top--2" />
                  <RadioGroup
                    onChange={this.handleMealChange}
                    selectedValue={this.state.mealType}
                  >
                    <Radio
                      label="Soup"
                      value="one"
                      alignIndicator={Alignment.RIGHT}
                    />
                    <Radio
                      label="Salad"
                      value="two"
                      alignIndicator={Alignment.RIGHT}
                    />
                    <Radio
                      label="Sandwich"
                      value="three"
                      alignIndicator={Alignment.RIGHT}
                    />
                  </RadioGroup>
                </div>
                <Divider />
                <div className="Flex-flex Flex-direction--column">
                  <Header title="Merge Account" />
                  <div className="Margin-top--2" />
                  <RadioGroup
                    onChange={this.handleMealChange}
                    selectedValue={this.state.mealType}
                  >
                    <Radio
                      className="Flex-flex Flex-direction--row Flex-alignItems--flexEnd"
                      label="Soup"
                      value="one"
                      alignIndicator={Alignment.LEFT}
                    />
                    <Radio
                      className="Flex-flex Flex-direction--row Flex-alignItems--flexEnd"
                      label="Salad"
                      value="two"
                      alignIndicator={Alignment.LEFT}
                    />
                    <Radio
                      className="Flex-flex Flex-direction--row Flex-alignItems--flexEnd"
                      label="Sandwich"
                      value="three"
                      alignIndicator={Alignment.LEFT}
                    />
                  </RadioGroup>
                </div>
              </div>
            </div>
          </FormGroup>

          <br />
          <div className="Text-align--center">
            <Button intent={Intent.DANGER} onClick={this.closeMergeAccount}>
              Close
            </Button>

            <Button
              className="Margin-left--12"
              intent={Intent.PRIMARY}
              onClick={this.submitHandler}
            >
              Confirm Merge
            </Button>
          </div>
        </div>
      );

      const loading = (
        <div className={classes}>
          <Spinner size={48} />
        </div>
      );

      const successScreen = (
        <div className={classes}>
          <Icon icon="tick" iconSize="80" intent={Intent.SUCCESS} />
          <H4> Account plan successfully updated</H4>
          <div className="Text-align--center">
            <Button intent={Intent.PRIMARY} onClick={this.closeMergeAccount}>
              Ok
            </Button>
          </div>
        </div>
      );

      const errorScreen = (
        <div className={classes}>
          <Icon icon="error" iconSize="80" intent={Intent.DANGER} />
          <div className="Margin-top--8">
            <H4> Account plan unsuccessfully updated </H4>
            <H5> Error Message: {this.state.errorMessage} </H5>
          </div>

          <div className="Text-align--center">
            <Button intent={Intent.PRIMARY} onClick={this.closeMergeAccount}>
              Ok
            </Button>
          </div>
        </div>
      );

      const editForm = (
        <Overlay
          isOpen={this.state.showForm}
          onClose={this.closeMergeAccount}
          className={Classes.OVERLAY_SCROLL_CONTAINER}
          canEscapeKeyClose={this.closeFormValid()}
          canOutsideClickClose={this.closeFormValid()}
        >
          {!this.state.accountConfirmed === true && formContent}
          {this.state.accountConfirmed &&
            !this.state.editConfirmed === true &&
            nextFormContent}
          {this.state.editLoading === true && loading}
          {this.state.editSuccess === true && successScreen}
          {this.state.editError === true && errorScreen}
        </Overlay>
      );

      return (
        <Aux>
          {this.state.showForm === true && editForm}
          <WrappedComponent
            {...this.props}
            showMergeAccount={this.showMergeAccount}
          />
        </Aux>
      );
    }
  };
};

export default MergeAccount;
