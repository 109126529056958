import React from "react";
import LifetimeStatBlock from "./LifetimeStatBlock";
const LifetimeBlock = (props) => {
  return (
    <div className="Padding-bottom--12 Margin-top--20">
      <div className="Card-root Card--radius--all Card--shadow--medium Box-background--white Padding-top--12 Padding-bottom--16 Padding-horizontal--20 Text-color--white Flex-flex Flex-direction--column">
        <div className="Flex-flex Flex-direction--row">
          <LifetimeStatBlock
            style={{ borderRight: "1px solid rgba(0,0,0,.2)" }}
            align="Text-align--left"
            resource="account"
          />

          <LifetimeStatBlock
            style={{ borderRight: "1px solid rgba(0,0,0,.2)" }}
            align="Text-align--center"
            resource="post"
          />

          <LifetimeStatBlock align="Text-align--right" resource="image" />
        </div>
      </div>
    </div>
  );
};

export default LifetimeBlock;
