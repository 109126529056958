const EventFactory = {
  detailInfo: [
    {
      title: "Event Info",
      rows: [
        { key: "user", label: "Name", type: "ToUserFromEvent" },
        { key: "id", label: "ID", type: "Code" },
        {
          key: "transaction_id",
          label: "Transaction ID",
          type: "Code"
        },
        { key: "user_id", label: "User ID", type: "Code" },
        { key: "account_id", label: "Account ID", type: "Code" },
        { key: "initialized_at", label: "Initialized" },
        { key: "occurred_at", label: "Occurred" },
        { key: "inserted_at", label: "Created", type: "Date" },
        { key: "updated_at", label: "Updated", type: "Date" }
      ]
    },
    {
      title: "Source Info",
      rows: [
        { key: "ip", label: "IP", type: "Code" },
        { key: "ua", label: "User Agent" },
        { key: "origin", label: "Origin" },
        { key: "path", label: "Path" }
      ]
    }
  ]
};

export default EventFactory;
