import * as React from "react";
import Helmet from "react-helmet";
import { config } from "../config";

const FastPostViewer = ({match: {params}}) => {
  const { id } = params;

  return (
    <>
      <Helmet
        title={id}
        script={[
          {
            src: `${config.publicUrl}core/embed/r/${id}.js`,
            type: "text/javascript"
          }
        ]}
      />
      <div className="nar-root" data-post-id={id}>
        <script type="text/javascript" />
      </div>
    </>
  );
};

export default FastPostViewer;
