import React from "react";
import LoadingBlock from "./LoadingBlock";
import ContentHeader from "./ContentHeader";
import Content from "./Content";
import NoContent from "./NoContent";
import PublisherContentHeader from "./PublisherContentHeader";

const PublisherDetails = (props) => {
  if (props.fetching) {
    return <LoadingBlock title={props.title} />;
  } else {
    return (
      <div className="Box-root Padding-bottom--12">
        <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
          {!props.publisherExists === true ? (
            <ContentHeader title={props.title} />
          ) : (
            <PublisherContentHeader
              history={props.history}
              title={props.title}
              showDeleteHandler={props.showDeleteHandler}
              showEditPublisher={props.showEditPublisher}
              setNoPublisher={props.setNoPublisher}
              data={props.data}
              updatePublisherDetails={props.updatePublisherDetails}
            />
          )}
          {!props.publisherExists ? (
            <NoContent title={props.title} />
          ) : (
            <Content info={props.info} data={props.data} />
          )}
        </div>
      </div>
    );
  }
};

export default PublisherDetails;
