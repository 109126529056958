import React, { Component } from "react";
import { Checkbox, InputGroup } from "@blueprintjs/core";

class FilterInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: this.props.isChecked
    };
  }

  componentDidMount() {
    this.setState({ isChecked: this.props.isChecked });
  }

  handleCheckbox = () => {
    this.setState(
      (prevState) => ({
        isChecked: !prevState.isChecked
      }),
      () => this.props.toggledHandler(this.state.isChecked)
    );
  };

  handleInputChange = (event) => {
    const newInput = event.target.value;
    this.props.changedHandler(newInput);
  };
  render() {
    return (
      <div>
        <div className="Padding-all--2">
          <Checkbox
            label={this.props.label}
            checked={this.state.isChecked}
            onChange={this.handleCheckbox}
          />
          {this.state.isChecked && (
            <InputGroup
              type="text"
              placeholder={this.props.label}
              value={this.props.currentValue}
              onChange={this.handleInputChange}
              rightElement={this.props.rightElement}
            />
          )}
        </div>
      </div>
    );
  }
}

export default FilterInput;
