import React from "react";
import { Button } from "@blueprintjs/core";

const TopMetaBar = (props) => {
  return (
    <div className="ContentHeader Box-root Box-background--white Box-divider--light-bottom-1 Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-direction--column">
      <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween">
        <div>
          <span className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-fontWeight--medium Text-lineHeight--24 Text-typeface--base Text-wrap--wrap Text-display--inline">
            <span>Metadata</span>
          </span>
        </div>
        <div>
          <Button
            disabled={true}
            icon={"edit"}
            onClick={() => props.setEditing(true)}
          >
            Edit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TopMetaBar;
