import React from "react";
import ColumnHeader from "./ColumnHeader";
import RowFactory from "../Row/RowFactory";

const getVal = (obj, path) => {
  return path
    .replace(/\[|\]\.?/g, ".")
    .split(".")
    .filter((s) => s)
    .reduce((acc, val) => acc && acc[val], obj);
};

const getType = (obj) => {
  if (obj["type"] === undefined) {
    return "String";
  } else {
    return obj["type"];
  }
};

const buildRows = (data, object) => {
  let rows = [];

  data.forEach((data) => {
    let val = getVal(object, data["key"]);

    const type = getType(data);
    const label = data["label"];
    const value = val;

    rows.push(RowFactory.getRow(type, label, value));
  });

  return rows;
};

const Content = (props) => {
  const body = props.info.map((colInfo) => (
    <div className="bs-PropertyList-column">
      <ColumnHeader title={colInfo["title"]} />
      <div>{buildRows(colInfo["rows"], props.data)}</div>
    </div>
  ));

  return (
    <div className="ContentBlock Box-root Box-background--white">
      <div className="bs-PropertyList">
        <div className="bs-PropertyList-columns">{body}</div>
      </div>
    </div>
  );
};

export default Content;
