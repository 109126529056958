const env = {
  dev: {
    apiUrl: "http://localhost:4000/admin/",
    publicUrl: "http://localhost:4000/admin/"
  },
  prod: {
    apiUrl: "https://service.getnarrativeapp.com/admin/",
    publicUrl: "https://service.getnarrativeapp.com/"
  }
};

const stage =
  process.env.REACT_APP_STAGE === undefined
    ? "dev"
    : process.env.REACT_APP_STAGE;

console.log(process);
export const config = {
  ...env[stage]
};
