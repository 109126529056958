/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable no-multi-assign */
import React, { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import classNames from "classnames";
import { Spinner } from "@blueprintjs/core";
import ApiHelper from "../../../helpers/ApiHelper";

const moment = require("moment-timezone");

const options = (firstGraph, secondGraph, currentSeg) => {
  let firstDates;
  let secondDates;

  if (currentSeg === "Hourly") {
    firstDates = firstGraph.range.map((x) =>
      moment(x.before)
        .tz(moment.tz.guess())
        .format("ddd Do, h:mm a")
    );

    secondDates = secondGraph.range.map((x) =>
      moment(x.before)
        .tz(moment.tz.guess())
        .format("ddd Do, h:mm a")
    );
  }

  if (currentSeg === "Daily" || currentSeg === "Weekly") {
    firstDates = firstGraph.range.map((x) =>
      moment(x.before)
        .tz(moment.tz.guess())
        .format("MMM ddd Do")
    );

    secondDates = secondGraph.range.map((x) =>
      moment(x.before)
        .tz(moment.tz.guess())
        .format("MMM ddd Do")
    );
  }

  if (currentSeg === "Yearly" || currentSeg === "Monthly") {
    firstDates = firstGraph.range.map((x) =>
      moment(x.before)
        .tz(moment.tz.guess())
        .format("MMM Do YY")
    );

    secondDates = secondGraph.range.map((x) =>
      moment(x.before)
        .tz(moment.tz.guess())
        .format("MMM Do YY")
    );
  }

  return {
    chart: {
      backgroundColor: "rgb(247, 250, 252)",
      // backgroundColor: "white",
      borderWidth: 0,
      type: "area",
      margin: [2, 0, 2, 0],
      style: {
        overflow: "visible"
      },
      height: 90,
      width: 600,
      className: "fitEqual",

      // small optimalization, saves 1-2 ms each sparkline
      skipClone: true
    },
    title: {
      text: ""
    },
    credits: {
      enabled: false
    },
    xAxis: {
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      startOnTick: false,
      endOnTick: false,
      tickPositions: []
    },
    yAxis: {
      endOnTick: false,
      startOnTick: false,
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      tickPositions: [0]
    },
    legend: {
      enabled: false
    },
    tooltip: {
      backgroundColor: "rgba(247, 250, 252, 0.1)",
      borderWidth: 1,
      shadow: false,
      useHTML: true,
      hideDelay: 0,
      shared: true,
      padding: 5,
      positioner(w, h, point) {
        return { x: point.plotX - w / 2, y: point.plotY - h };
      },
      formatter() {
        let s = `<b> ${this.x} </b>`;
        if (this.points && this.points[0]) {
          s += `<br/> ${firstDates[this.x]} : ${this.points[0].y}`;
        }

        if (this.points && this.points[1]) {
          s += `<br/> ${secondDates[this.x]} : ${this.points[1].y}`;
        }

        return s;
      }
    },
    plotOptions: {
      series: {
        animation: true,
        lineWidth: 3,
        shadow: false,
        states: {
          hover: {
            lineWidth: 3
          }
        },
        marker: {
          radius: 1,
          states: {
            hover: {
              radius: 2
            }
          }
        },
        fillOpacity: 0.0
      },
      column: {
        negativeColor: "#910000",
        borderColor: "silver"
      }
    },
    series: [
      {
        data: firstGraph.range.map((x) => x.value),
        color: "rgba(88, 106, 218, 1)"
      },
      {
        data: secondGraph.range.map((x) => x.value),
        color: "rgba(88, 106, 218, 0.3)"
      }
    ]
  };
};

const segmentUnitObj = {
  Hourly: "hour",
  Daily: "day",
  Weekly: "week",
  Monthly: "month",
  Yearly: "year"
};

const topicObj = {
  Accounts: "account_created",
  Posts: "post_created",
  Images: "image_created",
  "Posts Retrieved": "post_retrieved"
};

const segmentValue = 1;

const getApiDates = (dates) => dates.toSend;

const StatBlock = (props) => {
  const [loading, setLoading] = useState(true);
  const [afterStat, setAfter] = useState();
  const [beforeStat, setBefore] = useState();
  const [firstGraph, setFirstGraph] = useState([]);
  const [secondGraph, setSecondGraph] = useState([]);
  const [sign, setSign] = useState();
  const [change, setChange] = useState();

  const fetchData = async () => {
    setLoading(true);
    const firstGraphParam = {
      topic: topicObj[props.resource],
      "segment[unit]": segmentUnitObj[props.currentSeg],
      "segment[value]": segmentValue,
      before: getApiDates(props.firstDate)[1],
      after: getApiDates(props.firstDate)[0]
    };

    const secondGraphParam = {
      topic: topicObj[props.resource],
      "segment[unit]": segmentUnitObj[props.currentSeg],
      "segment[value]": segmentValue,
      before: getApiDates(props.secondDate)[1],
      after: getApiDates(props.secondDate)[0]
    };

    const endPoint = "api/statistics/range";

    try {
      setSign(null);
      const firstGraphResult = await ApiHelper.getRequest(
        endPoint,
        firstGraphParam
      );

      const secondGraphResult = await ApiHelper.getRequest(
        endPoint,
        secondGraphParam
      );

      const firstData = firstGraphResult.data;
      const secondData = secondGraphResult.data;

      let change = "N/A";

      if (secondData.total !== 0) {
        change =
          Math.round(
            ((firstData.total - secondData.total) / secondData.total) * 100 * 10
          ) / 10;
      }

      if (change !== "N/A") {
        if (change > 0) {
          setSign(true);
          change = `+${change}`;
        } else {
          setSign(false);
        }
        change = change += "%";
      }

      setChange(change);
      setAfter(firstData.total);
      setBefore(secondData.total);
      setFirstGraph(firstData);
      setSecondGraph(secondData);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.currentSeg, props.currentRange, props.firstDate, props.secondDate]);

  if (loading) {
    return (
      <div
        style={{ borderBottom: "1px solid rgba(0,0,0,.2)" }}
        className="statBackground"
      >
        <div className="Margin-left--12 Padding-all--12">
          <div className="Flex-grow--1" style={props.style}>
            <div className="Flex-flex Flex-direction--column">
              <div className="Margin-top--8">
                <Spinner size={80} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      style={{ borderBottom: "1px solid rgba(0,0,0,.2)" }}
      className="statBackground"
    >
      <div className="Margin-left--12">
        <div className="Flex-flex Flex-direction--row">
          <div className="Flex-flex Flex-direction--column fitEqual">
            <span className="Text-lineHeight--40 Text-fontWeight--medium Text-fontSize--24 Text-color--black">
              {props.resource}
            </span>
            <div className="Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween">
              <div className="Flex-grow--1">
                <span className="Text-lineHeight--72 Text-fontWeight--regular Text-fontSize--48 statBlue-1">
                  {afterStat}
                </span>
              </div>
              <div className="Flex-grow--1">
                <span className="Text-lineHeight--72 Text-fontWeight--regular Text-fontSize--48">
                  <span
                    className={classNames({
                      statGreen: sign === true,
                      "Text-color--red": sign === false
                    })}
                  >
                    {change}
                  </span>
                </span>
              </div>
              <div className="Flex-grow--1">
                <span className="Text-lineHeight--72 Text-fontWeight--regular Text-fontSize--48 statBlue-2">
                  {beforeStat}
                </span>
              </div>
            </div>
          </div>
          <div className="Margin-top--20">
            <HighchartsReact
              highcharts={Highcharts}
              options={options(firstGraph, secondGraph, props.currentSeg)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatBlock;
