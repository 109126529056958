const AdminHelper = {
  getAdmins: (res) => {
    const formattedAdmins = res.data.data.map(
      (admin) =>
        (admin = {
          name: admin.f_name + " " + admin.l_name,
          email: admin.email,
          id: admin.id
        })
    );

    return formattedAdmins;
  }
};

export default AdminHelper;
