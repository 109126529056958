import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import NavHeader from "./NavHeader.jsx";
import PageableTable from "./Table/PageableTable.jsx";
import PostHelper from "../helpers/PostHelper.js";
import ApiHelper from "../helpers/ApiHelper.js";

import Error from "../hoc/Error";
import PostViewer from "../hoc/PostViewer";
import ObjectFactory from "../helpers/ObjectFactory/ObjectFactory";

import PaginationHelper from "../helpers/PaginationHelper.js";

const sorts = {
  name: {
    ascending: "name",
    descending: "-name"
  },
  live: {
    ascending: "live",
    descending: "-live"
  },
  status: {
    ascending: "status",
    descending: "-status"
  },
  accountName: {
    ascending: "user_f_name,user_l_name",
    descending: "-user_f_name,-user_l_name"
  },
  created_at: {
    ascending: "inserted_at",
    descending: "-inserted_at"
  },
  updated_at: {
    ascending: "updated_at",
    descending: "-updated_at"
  }
};

class Posts extends Component {
  constructor(props) {
    super(props);

    const queryParams = this.props.location.search
      .replace(/(^\?)/, "")
      .split("&")
      .map(
        function(n) {
          return (n = n.split("=")), (this[n[0]] = n[1]), this;
        }.bind({})
      )[0];

    this.state = {
      fetched: false,
      pagination: { number: 1, size: PaginationHelper.getPageSize() },
      account_id: queryParams["account"] || queryParams["account_id"],
      filters: {},
      datePickerDate: null,
      currentSort: "created_at",
      descending: true
    };

    this.fetchCurrentPage = this.fetchCurrentPage.bind(this);
    this.incrementPage = this.incrementPage.bind(this);
    this.decrementPage = this.decrementPage.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setPagination = this.setPagination.bind(this);
  }

  setFilter(filters, datePickerDate) {
    this.setState({
      fetched: false,
      filters: filters,
      datePickerDate: datePickerDate
    });
    this.fetchCurrentPage(this.state.pagination, filters);
  }

  componentDidMount() {
    this.fetchCurrentPage(this.state.pagination);
  }

  fetchCurrentPage(pagination, filters) {
    const pageParams = ApiHelper.buildPaginationParams(
      pagination.number,
      pagination.size
    );
    var params = {};
    if (this.state.account_id === undefined) {
      params = {
        ...pageParams,
        ...filters
      };
    } else {
      params = {
        ...pageParams,
        ...filters,
        "filter[account_id]": this.state.account_id
      };
    }

    if (this.state.descending === true) {
      if (sorts[this.state.currentSort]["descending"] !== "") {
        params = {
          ...params,
          sort: sorts[this.state.currentSort]["descending"]
        };
      }
    } else {
      if (sorts[this.state.currentSort]["ascending"] !== "") {
        params = {
          ...params,
          sort: sorts[this.state.currentSort]["ascending"]
        };
      }
    }

    ApiHelper.getRequest("api/posts", params)
      .then((res) => {
        const pagination = res.data.pagination;
        const posts = res.data.data.map((post) => {
          return PostHelper.formatSimplePost(post);
        });

        this.setState(
          { fetched: true, posts: posts, pagination: pagination },
          () => {
            if (
              this.state.pagination.number > this.state.pagination.total &&
              this.state.pagination.total !== 0
            ) {
              const newPagination = {
                ...this.state.pagination,
                number: this.state.pagination.total
              };

              this.fetchCurrentPage(newPagination, filters);
            }
          }
        );
      })
      .catch((error) => {
        console.log(error);
        this.props.errorHandler(error);
      });
  }

  incrementPage() {
    if (this.state.pagination.total === this.state.pagination.number) return;
    const newPage = {
      ...this.state.pagination,
      number: this.state.pagination.number + 1
    };
    this.setState({ fetched: false, pagination: newPage });
    this.fetchCurrentPage(newPage);
  }

  decrementPage() {
    if (this.state.pagination.number === 1) return;
    const newPage = {
      ...this.state.pagination,
      number: this.state.pagination.number - 1
    };
    this.setState({ fetched: false, pagination: newPage });
    this.fetchCurrentPage(newPage);
  }

  setPagination(size) {
    const newPage = {
      ...this.state.pagination,
      size: size,
      number: 1
    };
    this.setState({ fetched: false, pagination: newPage }, () =>
      this.fetchCurrentPage(newPage, this.state.filters)
    );
  }

  switchSort = () => {
    this.setState(
      (prevState) => ({
        descending: !prevState.descending
      }),
      () => this.componentDidMount(this.state.pagination, this.state.filters)
    );
  };

  switchCurrentSort = (newSort) => {
    this.setState({ currentSort: newSort }, () =>
      this.componentDidMount(this.state.pagination, this.state.filters)
    );
  };

  getSortObj = () => {
    return {
      descending: this.state.descending,
      switchSort: this.switchSort,
      currentSort: this.state.currentSort,
      switchCurrentSort: this.switchCurrentSort
    };
  };

  render() {
    return (
      <div>
        <NavHeader />
        <div className="World-container">
          <PageableTable
            className="container-box"
            fetching={!this.state.fetched}
            resourceName="Posts"
            pagination={this.state.pagination}
            nextPage={this.incrementPage}
            prevPage={this.decrementPage}
            tableInfo={ObjectFactory.posts(
              this.state.posts,
              this.props.showPostViewer
            )}
            setFilter={this.setFilter}
            setPagination={this.setPagination}
            filters={this.state.filters}
            datePickerDate={this.state.datePickerDate}
            sortObj={this.getSortObj()}
          />
        </div>
      </div>
    );
  }
}

export default PostViewer(Error(withRouter(Posts)));
