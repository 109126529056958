import React from "react";
import {
  MenuItem,
  Menu,
  Popover,
  Position,
  Button,
  Classes,
  Overlay,
  Spinner,
  Icon,
  H4,
  Intent,
  H5
} from "@blueprintjs/core";

import classNames from "classnames";

import LoadingBlock from "./LoadingBlock";
import Content from "./Content";
import FirstPromoterForm from "./FirstPromoterForm";
import ApiHelper from "../../helpers/ApiHelper";
import FirstPromoterContentHeader from "./FirstPromoterContentHeader";

const FirstPromoterDetails = (props) => {
  const initialState = {
    editLoading: false,
    editConfirmed: false,
    editSuccess: false,
    editError: false,
    errorMessage: null
  };

  const [isOpen, setIsOpen] = React.useState(false);

  const [formState, setFormState] = React.useState(initialState);

  // TODO - Extract to shared component

  const loading = (
    <div
      className={classNames(
        Classes.CARD,
        Classes.ELEVATION_4,
        "Text-align--center",
        "container"
      )}
    >
      <Spinner size={48} />
    </div>
  );

  // TODO - Extract to shared component

  const successScreen = (
    <div
      className={classNames(
        Classes.CARD,
        Classes.ELEVATION_4,
        "Text-align--center",
        "container"
      )}
    >
      <Icon icon="tick" iconSize="80" intent={Intent.SUCCESS} />
      <H4> Account details successfully updated</H4>
      <div className="Text-align--center">
        <Button
          intent={Intent.PRIMARY}
          onClick={() => {
            setIsOpen(false);
            setFormState(initialState);
          }}
        >
          Ok
        </Button>
      </div>
    </div>
  );

  // TODO - Extract to shared component

  const errorScreen = (
    <div
      className={classNames(
        Classes.CARD,
        Classes.ELEVATION_4,
        "Text-align--center",
        "container"
      )}
    >
      <Icon icon="error" iconSize="80" intent={Intent.DANGER} />
      <div className="Margin-top--8">
        <H4> Account details unsuccessfully updated </H4>
        <H5> Error Message: {formState.errorMessage} </H5>
      </div>

      <div className="Text-align--center">
        <Button
          intent={Intent.PRIMARY}
          onClick={() => {
            setIsOpen(false);
            setFormState(initialState);
          }}
        >
          Ok
        </Button>
      </div>
    </div>
  );

  const submitHandler = (values) => {
    setFormState({
      ...formState,
      editLoading: true,
      editConfirmed: true
    });

    const link = `api/users/${props.data.id}/update_fp_coupon`;

    ApiHelper.sendEditRequest(link, { coupon: values.referral_ref_id })
      .then((res) => {
        setFormState({
          ...formState,
          editLoading: false,
          editSuccess: true
        });
        props.updateUserDetails(res.data.data);
      })
      .catch((err) => {
        setFormState({
          ...formState,
          editLoading: false,
          editError: true,
          errorMessage: err.response.data.info
        });
      });
  };

  if (props.fetching) {
    return <LoadingBlock title={props.title} />;
  } else {
    return (
      <div className="Box-root Padding-bottom--12">
        <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
          <FirstPromoterContentHeader
            title={props.title}
            openEditModal={() => setIsOpen(true)}
          />
          <Content info={props.info} data={props.data} />
          <Overlay
            isOpen={isOpen}
            onClose={setIsOpen}
            className={Classes.OVERLAY_SCROLL_CONTAINER}
          >
            {!formState.editConfirmed && (
              <FirstPromoterForm
                initialValues={{ referral_ref_id: props.data.referral_ref_id }}
                onCloseForm={() => setIsOpen(!isOpen)}
                onSubmit={submitHandler}
              />
            )}
            {formState.editLoading && loading}
            {formState.editSuccess && successScreen}
            {formState.editError && errorScreen}
          </Overlay>
        </div>
      </div>
    );
  }
};

export default FirstPromoterDetails;
