import React from "react";
import { Menu, MenuItem } from "@blueprintjs/core";
import { Link } from "react-router-dom";

const MenuBuilder = {
  accountMenu: (object) => {
    return (
      <Menu>
        <Link to={"/accounts/" + object.account_id}>
          <MenuItem className="Text-color--black" text="View Account" />
        </Link>

        <Link to={"/posts?account_id=" + object.account_id}>
          <MenuItem className="Text-color--black" text="View Posts" />
        </Link>
      </Menu>
    );
  },
  postMenu: (object, postPreviewHandler) => {
    return (
      <Menu>
        <Link to={"/posts/" + object.id}>
          <MenuItem className="Text-color--black" text="View Post" />
        </Link>

        <MenuItem
          onClick={() => postPreviewHandler(object.id)}
          text="Preview Post"
        />
      </Menu>
    );
  },
  userMenu: (object) => {
    return (
      <Menu>
        <Link to={"/users/" + object.id}>
          <MenuItem className="Text-color--black" text="View User" />
        </Link>

        <MenuItem href="" text="Send Password Reset" />
      </Menu>
    );
  },
  eventMenu: (object) => {
    return (
      <Menu>
        <Link to={"/events/" + object.id}>
          <MenuItem className="Text-color--black" text="View Event" />
        </Link>

        <Link to={"/events?account_id=" + object.account_id}>
          <MenuItem className="Text-color--black" text="View Transaction" />
        </Link>

        <Link to={"/events?account_id=" + object.account_id}>
          <MenuItem className="Text-color--black" text="View Account" />
        </Link>
      </Menu>
    );
  },
  adminMenu: (object) => {
    return (
      <Menu>
        <MenuItem href="" text="Delete Admin" />
        <MenuItem href="" text="Send Password Reset" />
      </Menu>
    );
  }
};

export default MenuBuilder;
