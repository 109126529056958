import React, { Component } from "react";
import NavHeader from "./NavHeader.jsx";
import PlanStats from "./Stats/PlanStats";
import LifetimeBlock from "./Stats/LifetimeStats/LifetimeBlock.js";
import DateBlock from "./Stats/DateStats/DateBlock.js";
import ActiveSubscriberBlock from "./Stats/ActiveSubscribers/ActiveSubscriberBlock";
import SubscriberRetentionBlock from "./Stats/SubscriberRetention/SubscriberRetentionBlock.js";

class Home extends Component {
  render() {
    return (
      <div>
        <NavHeader {...this.props} />
        <div className="World-container">
          <ActiveSubscriberBlock />
          <SubscriberRetentionBlock />
          <LifetimeBlock />
          <DateBlock />
          {/* <PlanStats /> */}
        </div>
      </div>
    );
  }
}

export default Home;
