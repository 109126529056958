import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  Alignment,
  Navbar,
  Button,
  ButtonGroup,
  HTMLSelect,
  Popover,
  Position
} from "@blueprintjs/core";
import SearchBox from "./Search/SearchBox";
import AuthHelper from "../helpers/AuthHelper";

class NavHeader extends Component {
  constructor(props) {
    super(props);
    this.goToLink = this.goToLink.bind(this);
    this.navButton = this.navButton.bind(this);
  }

  goToLink(e) {
    const link = e.target.parentElement.dataset.link;
    console.log(link);
    this.props.history.push(link);
  }

  navButton(link, title) {
    const className = this.props.location.pathname === link ? "active" : "";
    return (
      <Button className={className} data-link={link} onClick={this.goToLink}>
        {title}
      </Button>
    );
  }

  goToAdminPage = () => {
    const link = "/admins";
    this.props.history.push(link);
  };

  logoutHandler = () => {
    AuthHelper.removeUserToken();
    const link = "/login";
    this.props.history.push(link);
  };

  render() {
    const elements = (
      <div className="Padding-all--8 Text-align--center">
        <div className="Margin-top--8">
          <Button icon="database" onClick={this.goToAdminPage}>
            {" "}
            Admin Database{" "}
          </Button>
        </div>
        <div className="Margin-top--8">
          <Button
            className="fitBtn"
            onClick={this.logoutHandler}
            icon="log-out"
          >
            {" "}
            Log Out
          </Button>
        </div>
      </div>
    );

    return (
      <Navbar className="World-nav" fixedToTop>
        <Navbar.Group align={Alignment.LEFT}>
          <HTMLSelect options={["Narrative Production", "Narrative Beta"]} />
        </Navbar.Group>
        <Navbar.Group className="Margin-left--20" align={Alignment.LEFT}>
          <ButtonGroup>
            {this.navButton("/", "Home")}
            {this.navButton("/accounts", "Accounts")}
            {this.navButton("/posts", "Posts")}
            {this.navButton("/coupons", "Coupons")}
            {this.navButton("/events", "Events")}
          </ButtonGroup>
        </Navbar.Group>
        <Navbar.Group className="Margin-left--20" align={Alignment.LEFT}>
          <SearchBox {...this.props} setDimensions={this.getDimensions} />
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          <Popover content={elements} position={Position.BOTTOM_LEFT}>
            <Button className="roundBtn" large="true" fill="true" icon="user" />
          </Popover>
        </Navbar.Group>
      </Navbar>
    );
  }
}

export default withRouter(NavHeader);
