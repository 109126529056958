import React from "react";
import classNames from "classnames";

const StaticCallback = (props) => {
  const getData = Object.values(props.link.selectedAccount).filter(
    (x) => x === props.data
  );

  const selected = getData[0] ? true : false;
  const tdClassName = classNames(
    "Table-cell",
    "Table-cell--align--right",
    "Table-cell--verticalAlign--top",
    "Table-cell--wrap--noWrap",
    "db-ListViewItem-cell",
    "db-ListViewItem-text",
    { "Box-background--blue100": selected }
  );

  return (
    <td onClick={() => props.link.handler(props.data)} className={tdClassName}>
      <div className="db-ListViewItem-cellContent Box-root Padding-all--8">
        <span className="db-ListViewItem-text Text-color--gray Text-fontSize--14 Text-lineHeight--20 Text-typeface--base Text-wrap--wrap Text-display--inline">
          <span>{props.data}</span>
        </span>
      </div>
    </td>
  );
};

export default StaticCallback;
