import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import NavHeader from "./NavHeader.jsx";
import PageableTable from "./Table/PageableTable.jsx";
import EventHelper from "../helpers/EventHelper.js";
import ApiHelper from "../helpers/ApiHelper.js";
import PaginationHelper from "../helpers/PaginationHelper.js";
import Error from "../hoc/Error";
import ObjectFactory from "../helpers/ObjectFactory/ObjectFactory";
import DateHelper from "../helpers/DateHelper.js";

const sorts = {
  topic_name: {
    ascending: "topic",
    descending: "-topic"
  },
  state: {
    ascending: "",
    descending: ""
  },
  email: {
    ascending: "",
    descending: ""
  },
  transaction_id: {
    ascending: "transaction_id",
    descending: "-transaction_id"
  },
  occurred_at: {
    ascending: "occurred_at",
    descending: "-occurred_at"
  }
};

class Events extends Component {
  constructor(props) {
    super(props);

    const queryParams = this.props.location.search
      .replace(/(^\?)/, "")
      .split("&")
      .map(
        function(n) {
          return (n = n.split("=")), (this[n[0]] = n[1]), this;
        }.bind({})
      )[0];

    this.state = {
      fetched: false,
      pagination: { number: 1, size: PaginationHelper.getPageSize() },
      transaction_id: queryParams["transaction_id"],
      account_id: queryParams["account_id"],
      resource_id: queryParams["account"],
      currentSort: "occurred_at",
      descending: true
    };

    this.fetchCurrentPage = this.fetchCurrentPage.bind(this);
    this.incrementPage = this.incrementPage.bind(this);
    this.decrementPage = this.decrementPage.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.setFilter = this.setFilter.bind(this);
  }

  componentDidMount() {
    this.fetchCurrentPage(this.state.pagination, this.state.filters);
  }

  fetchCurrentPage(pagination, filters) {
    const pageParams = ApiHelper.buildPaginationParams(
      pagination.number,
      pagination.size
    );

    var params = {};
    if (this.state.transaction_id !== undefined) {
      params = {
        ...pageParams,

        "filter[transaction_id]": this.state.transaction_id
      };
    } else if (this.state.account_id !== undefined) {
      params = {
        ...pageParams,
        "filter[account_id]": this.state.transaction_id
      };
    } else if (this.state.resource_id !== undefined) {
      params = {
        ...pageParams,
        "filter[resource_id]": this.state.resource_id
      };
    } else {
      params = {
        ...pageParams,
        ...filters
      };
    }

    if (this.state.descending === true) {
      if (sorts[this.state.currentSort]["descending"] !== "") {
        params = {
          ...params,
          sort: sorts[this.state.currentSort]["descending"]
        };
      }
    } else {
      if (sorts[this.state.currentSort]["ascending"] !== "") {
        params = {
          ...params,
          sort: sorts[this.state.currentSort]["ascending"]
        };
      }
    }

    ApiHelper.getRequest("api/events", params)
      .then((res) => {
        const pagination = res.data.pagination;
        const events = res.data.data.map((event) => {
          return EventHelper.formatEvent(event);
        });

        this.setState(
          {
            fetched: true,
            events: events,
            pagination: pagination
          },
          () => {
            if (
              this.state.pagination.number > this.state.pagination.total &&
              this.state.pagination.total !== 0
            ) {
              const newPagination = {
                ...this.state.pagination,
                number: this.state.pagination.total
              };

              this.fetchCurrentPage(newPagination, filters);
            }
          }
        );
      })
      .catch((error) => {
        console.log(error);
        this.props.errorHandler(error);
      });
  }

  incrementPage() {
    if (this.state.pagination.total === this.state.pagination.number) return;
    const newPage = {
      ...this.state.pagination,
      number: this.state.pagination.number + 1
    };
    this.setState({ fetched: false, pagination: newPage });
    this.fetchCurrentPage(newPage, this.state.filters);
  }

  decrementPage() {
    if (this.state.pagination.number === 1) return;
    const newPage = {
      ...this.state.pagination,
      number: this.state.pagination.number - 1
    };
    this.setState({ fetched: false, pagination: newPage });
    this.fetchCurrentPage(newPage, this.state.filters);
  }

  setFilter(filters, datePickerDate) {
    this.setState(
      { fetched: false, filters: filters, datePickerDate: datePickerDate },
      () => this.fetchCurrentPage(this.state.pagination, filters)
    );
  }

  setPagination(size) {
    const newPage = {
      ...this.state.pagination,
      size: size,
      number: 1
    };
    this.setState({ fetched: false, pagination: newPage }, () =>
      this.fetchCurrentPage(newPage, this.state.filters)
    );
  }

  switchSort = () => {
    this.setState(
      (prevState) => ({
        descending: !prevState.descending
      }),
      () => this.componentDidMount(this.state.pagination, this.state.filters)
    );
  };

  switchCurrentSort = (newSort) => {
    this.setState({ currentSort: newSort }, () =>
      this.componentDidMount(this.state.pagination, this.state.filters)
    );
  };

  getSortObj = () => {
    return {
      descending: this.state.descending,
      switchSort: this.switchSort,
      currentSort: this.state.currentSort,
      switchCurrentSort: this.switchCurrentSort
    };
  };

  render() {
    console.log(this.state.events);
    return (
      <div>
        <NavHeader />
        <div className="World-container">
          <PageableTable
            className="container-box"
            fetching={!this.state.fetched}
            pagination={this.state.pagination}
            resourceName="Events"
            nextPage={this.incrementPage}
            prevPage={this.decrementPage}
            tableInfo={ObjectFactory.events(this.state.events)}
            setFilter={this.setFilter}
            setPagination={this.setPagination}
            filters={this.state.filters}
            datePickerDate={this.state.datePickerDate}
            sortObj={this.getSortObj()}
          />
        </div>
      </div>
    );
  }
}

export default Error(withRouter(Events));
