import React from "react";
import { MetaField } from "../MetaBlock/MetaField";

const MetaCell = ({data}) => {
  return (
    <td className="db-ListViewItem-cell db-ListViewItem-text">
      <div className="db-ListViewItem-cellContent Box-root Padding-all--8">
        <span>
          {data && data.post_features && data.post_features.map((post_feature) =>
            <MetaField meta_data={post_feature}/>)}
        </span>
      </div>
    </td>
  );
};

export default MetaCell;
