import React, { Component } from "react";
import { Checkbox } from "@blueprintjs/core";

class FilterFailed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: this.props.isChecked
    };
  }

  componentDidMount() {
    this.setState({ isChecked: this.props.isChecked });
  }

  handleCheckbox = () => {
    this.setState(
      (prevState) => ({
        isChecked: !prevState.isChecked
      }),
      () => this.props.failedToggledHandler(this.state.isChecked)
    );
  };

  render() {
    return (
      <div>
        <div className="Padding-all--2">
          <Checkbox
            checked={this.state.isChecked}
            onClick={this.handleCheckbox}
            label="Failed Events"
          />
        </div>
      </div>
    );
  }
}

export default FilterFailed;
