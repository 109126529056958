import Cookies from "js-cookie";

const AuthHelper = {
  setUserToken: (token) => {
    Cookies.set("jwt", token);
  },
  getUserToken: () => {
    return Cookies.get("jwt");
  },
  removeUserToken: () => {
    Cookies.remove("jwt");
  }
};

export default AuthHelper;
