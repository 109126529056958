import React from "react";
import { Spinner } from "@blueprintjs/core";

const Loading = (props) => {
  return (
    <div className={"Flex-grow--1"} style={props.style}>
      <div className="Flex-flex Flex-direction--column">
        <div className="Margin-top--8">
          <Spinner size={80} />;
        </div>
      </div>
    </div>
  );
};

export default Loading;
