import React, { Component } from "react";
import Aux from "../Aux";
import classNames from "classnames";
import {
  Button,
  Classes,
  Intent,
  Overlay,
  H5,
  FormGroup,
  InputGroup,
  Icon,
  H4,
  Spinner
} from "@blueprintjs/core";
import ApiHelper from "../../helpers/ApiHelper";
import InputField from "./InputField";

const initialState = {
  editConfirmed: false,
  editLoading: false,
  editSuccess: false,
  editError: false,
  showForm: false,
  placeholderFirstName: "",
  firstName: "",
  firstNameValid: false,
  placeholderLastName: "",
  lastName: "",
  lastNameValid: false,
  placeholderEmail: "",
  email: "",
  emailValid: false,
  errorMessage: "",
  updatePublisherDetails: null
};

const EditUser = (WrappedComponent) => {
  return class extends Component {
    state = {
      editConfirmed: false,
      editLoading: false,
      editSuccess: false,
      editError: false,
      showForm: false,
      placeholderFirstName: "",
      firstName: "",
      firstNameValid: false,
      placeholderLastName: "",
      lastName: "",
      lastNameValid: false,
      placeholderEmail: "",
      email: "",
      emailValid: false,
      errorMessage: "",
      updateUserDetails: null
    };

    closeFormValid = () => {
      return !this.state.editLoading === true;
    };

    showEditUser = (placeholderInfo, updateUserDetails) => {
      this.setState(placeholderInfo);
      this.setState({
        showForm: true,
        updateUserDetails: updateUserDetails
      });
    };

    closeEditUser = () => {
      this.setState({ showForm: false });
      this.setState(initialState);
    };

    firstNameChanged = (e) => {
      this.setState(
        { firstName: e.target.value.trimLeft().trimRight() },
        () => {
          this.validateFirstName();
        }
      );
    };

    validateFirstName = () => {
      if (
        this.state.firstName &&
        this.state.firstName.length > 0 &&
        this.state.firstName.split(" ").length === 1
      ) {
        this.setState({ firstNameValid: true });
      } else {
        this.setState({ firstNameValid: false });
      }
    };

    lastNameChanged = (e) => {
      this.setState({ lastName: e.target.value.trimLeft().trimRight() }, () => {
        this.validateLastName();
      });
    };

    validateLastName = () => {
      if (
        this.state.lastName &&
        this.state.lastName.length > 0 &&
        this.state.lastName.split(" ").length === 1
      ) {
        this.setState({ lastNameValid: true });
      } else {
        this.setState({ lastNameValid: false });
      }
    };

    emailChanged = (e) => {
      this.setState({ email: e.target.value.trimLeft().trimRight() }, () => {
        this.validateEmail();
      });
    };

    emailRegexCheck = (email) => {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    };

    validateEmail = () => {
      if (
        this.state.email &&
        this.state.email.length > 0 &&
        this.state.email.split(" ").length === 1 &&
        this.emailRegexCheck(this.state.email) === true
      ) {
        this.setState({ emailValid: true });
      } else {
        this.setState({ emailValid: false });
      }
    };

    submitValid = () => {
      return (
        this.state.firstNameValid === true ||
        this.state.lastNameValid === true ||
        this.state.emailValid === true
      );
    };

    submitHandler = () => {
      this.setState({
        editLoading: true,
        editConfirmed: true
      });

      const userID = window.location.pathname.substring(1);
      const link = "api/" + userID.toString();

      let body = {
        user: {}
      };

      if (this.state.firstNameValid === true) {
        body.user = {
          ...body.user,
          f_name: this.state.firstName
        };
      }

      if (this.state.lastNameValid === true) {
        body.user = {
          ...body.user,
          l_name: this.state.lastName
        };
      }

      if (this.state.emailValid === true) {
        body.user = {
          ...body.user,
          email: this.state.email
        };
      }

      ApiHelper.sendEditRequest(link, body)
        .then((res) => {
          console.log(res);
          this.setState({ editLoading: false }, () =>
            this.setState({ editSuccess: true }, () => {
              this.state.updateUserDetails(res.data.data);
            })
          );
        })
        .catch((err) => {
          this.setState({ editLoading: false }, () =>
            this.setState({
              editError: true,
              errorMessage: err.response.data.info
            })
          );
        });
    };

    render() {
      const classes = classNames(
        Classes.CARD,
        Classes.ELEVATION_4,
        "Text-align--center",
        "container"
      );

      const formContent = (
        <div className={classes}>
          <FormGroup
            label={
              <span className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-fontWeight--medium Text-lineHeight--24 Text-typeface--base Text-wrap--wrap Text-display--inline">
                <span> Editing User Details: </span>
              </span>
            }
            labelFor="text-input"
          >
            <div className="Flex-flex Flex-direction--column">
              <InputField
                status="First Name:"
                placeholder={this.state.placeholderFirstName}
                valid={this.state.firstNameValid}
                changed={this.firstNameChanged}
              />
              <InputField
                status="Last Name:"
                placeholder={this.state.placeholderLastName}
                valid={this.state.lastNameValid}
                changed={this.lastNameChanged}
              />
              <InputField
                status="Email:"
                placeholder={this.state.placeholderEmail}
                valid={this.state.emailValid}
                changed={this.emailChanged}
              />
            </div>
          </FormGroup>

          <br />
          <div className="Text-align--center">
            <Button intent={Intent.DANGER} onClick={this.closeEditUser}>
              Close
            </Button>
            <Button
              className="Margin-left--12"
              intent={Intent.PRIMARY}
              onClick={this.submitHandler}
              disabled={!this.submitValid()}
            >
              Confirm Edit
            </Button>
          </div>
        </div>
      );

      const loading = (
        <div className={classes}>
          <Spinner size={48} />
        </div>
      );

      const successScreen = (
        <div className={classes}>
          <Icon icon="tick" iconSize="80" intent={Intent.SUCCESS} />
          <H4> User details successfully updated</H4>
          <div className="Text-align--center">
            <Button intent={Intent.PRIMARY} onClick={this.closeEditUser}>
              Ok
            </Button>
          </div>
        </div>
      );

      const errorScreen = (
        <div className={classes}>
          <Icon icon="error" iconSize="80" intent={Intent.DANGER} />
          <div className="Margin-top--8">
            <H4> User details unsuccessfully updated </H4>
            {this.state.errorMessage !== "" && (
              <H5> Error Message: {this.state.errorMessage} </H5>
            )}
          </div>

          <div className="Text-align--center">
            <Button intent={Intent.PRIMARY} onClick={this.closeEditUser}>
              Ok
            </Button>
          </div>
        </div>
      );

      const editForm = (
        <Overlay
          isOpen={this.state.showForm}
          onClose={this.closeEditUser}
          className={Classes.OVERLAY_SCROLL_CONTAINER}
          canEscapeKeyClose={this.closeFormValid()}
          canOutsideClickClose={this.closeFormValid()}
        >
          {!this.state.editConfirmed === true && formContent}
          {this.state.editLoading === true && loading}
          {this.state.editSuccess === true && successScreen}
          {this.state.editError === true && errorScreen}
        </Overlay>
      );

      return (
        <Aux>
          {this.state.showForm === true && editForm}
          <WrappedComponent {...this.props} showEditUser={this.showEditUser} />
        </Aux>
      );
    }
  };
};

export default EditUser;
