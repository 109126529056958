import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button } from "@blueprintjs/core";

const CopyButton = (props) => {
  const title = props.copyTitle === undefined ? "Copy ID" : props.copyTitle;
  return (
    <CopyToClipboard text={props.text} onCopy={props.onCopy}>
      <Button className={props.className}> {title}</Button>
    </CopyToClipboard>
  );
};

export default CopyButton;
