import React, { Component } from "react";

import NavHeader from "../NavHeader.jsx";
import TableSection from "../Table/TableSection.jsx";
import ActionHeader from "../ActionHeader/ActionHeader";
import Details from "../Details/Details";
import PublisherDetails from "../Details/PublisherDetails.js";

import ApiHelper from "../../helpers/ApiHelper.js";
import AccountHelper from "../../helpers/AccountHelper.js";
import UserHelper from "../../helpers/UserHelper.js";
import PostHelper from "../../helpers/PostHelper.js";
import PromiseHelper from "../../helpers/PromiseHelper";
import ObjectFactory from "../../helpers/ObjectFactory/ObjectFactory";
import EventHelper from "../../helpers/EventHelper.js";

import Error from "../../hoc/Error";
import DeletePublisher from "../../hoc/Publisher/DeletePublisher";
import EditPublisher from "../../hoc/Publisher/EditPublisher";
import EditAccount from "../../hoc/Account/EditAccount";
import PostViewer from "../../hoc/PostViewer";
import EditPlan from "../../hoc/Account/EditPlan";
import MergeAccount from "../../hoc/Account/MergeAccount";
import FirstPromoterDetails from "../Details/FirstPromoterDetails.js";

class Account extends Component {
  constructor(props) {
    super(props);

    const account_id = this.props.match.params.id;
    this.state = { fetched: false, account_id, accountPublisherExists: false };
  }

  componentDidMount() {
    this.fetchAccount();
  }

  componentDidUpdate() {
    if (this.state.account_id !== this.props.match.params.id) {
      this.setState({ account_id: this.props.match.params.id }, () =>
        this.fetchAccount()
      );
    }
  }

  async fetchAccount() {
    console.log("fetching");
    const pageParams = ApiHelper.buildPaginationParams(1, 1);
    const params = {
      ...pageParams,
      "filter[account_id]": this.state.account_id
    };

    const res = await PromiseHelper.promiseAll([
      ApiHelper.getRequest(`api/accounts/${this.state.account_id}`),
      ApiHelper.getRequest(`api/accounts/${this.state.account_id}/publisher`),
      ApiHelper.getRequest(`api/users/`, params)
    ]);

    // Maybe error handling here?
    const account = AccountHelper.formatAccount(res[0].data.data);
    const name = res[2].data.data[0].f_name + " " + res[2].data.data[0].l_name;
    if (res[1].data !== undefined) {
      const accountPublisher = AccountHelper.getAccountPublisher(
        res[1].data.data
      );
      this.setState({
        fetched: true,
        account,
        accountPublisher,
        accountPublisherExists: true,
        name: name
      });
    } else {
      this.setState({
        fetched: true,
        account,
        accountPublisherExists: false,
        name: name
      });
    }
  }

  setNoPublisher = () => {
    this.setState({ accountPublisherExists: false });
  };

  updatePublisherDetails = (host) => {
    const oldPublisher = this.state.accountPublisher;
    const newPublisher = {
      ...oldPublisher,
      host: host
    };
    this.setState({ accountPublisher: newPublisher });
  };

  updateAccountDetails = (newAccDetails) => {
    const newAcc = {
      ...this.state.account,
      active: newAccDetails.active,
      currStatus: newAccDetails.currStatus
    };

    this.setState({ account: newAcc });
  };

  updateAccountPlanDetails = (newAccDetails) => {
    this.setState({ account: newAccDetails });
  };

  render() {
    let host;
    if (
      this.state.account !== undefined &&
      this.state.account.publisher !== undefined
    ) {
      host = this.state.account.publisher.host;
    }

    var resource_id = this.state.account_id;

    if (this.state.account !== undefined) {
      resource_id += `,${this.state.account.user_id}`;

      if (this.state.account.publisher !== undefined) {
        resource_id += `,${this.state.account.publisher.id}`;
      }
    }

    return (
      <div>
        <NavHeader />

        <div className="World-container">
          <ActionHeader
            resource={"Account"}
            fetching={!this.state.fetched}
            title={host}
            placeholder="No Host"
            name={this.state.name}
            onEditClick={() =>
              this.props.showEditAccount(
                this.state.account.currStatus,
                this.state.account.active,
                this.updateAccountDetails
              )
            }
            onEditPlanClick={() =>
              this.props.showEditPlan(
                AccountHelper.getInitialPlan(this.state.account),
                this.updateAccountPlanDetails
              )
            }
            onMergeAccountClick={() =>
              this.props.showMergeAccount(
                AccountHelper.getAccountDetailsForMerge(this.state.account)
              )
            }
            account_id={this.state.account_id}
          />
          <Details
            data={this.state.account}
            fetching={!this.state.fetched}
            info={ObjectFactory.account.detailInfo}
            title="Details"
          />

          <PublisherDetails
            data={this.state.accountPublisher}
            fetching={!this.state.fetched}
            info={ObjectFactory.account.publisherDetailInfo}
            title="Publisher Details"
            noContent={!this.state.accountPublisher}
            showDeleteHandler={this.props.showDeleteHandler}
            showEditPublisher={this.props.showEditPublisher}
            history={this.props.history}
            publisherExists={this.state.accountPublisherExists}
            setNoPublisher={this.setNoPublisher}
            updatePublisherDetails={this.updatePublisherDetails}
          />
          <TableSection
            account_id={this.state.account_id}
            objectName="users"
            title="Users"
            tableInfo={ObjectFactory.account.userTableInfo}
            dataTransformer={UserHelper.formatUser}
          />
          <TableSection
            account_id={this.state.account_id}
            objectName="posts"
            title="Posts"
            tableInfo={ObjectFactory.account.postTableInfo}
            dataTransformer={PostHelper.transformPost}
            onClick={this.props.showPostViewer}
          />
          <TableSection
            // account_id={this.state.account_id}
            linkAccId={this.state.account_id}
            resource_id={resource_id}
            objectName="events"
            title="Events"
            tableInfo={ObjectFactory.account.eventTableInfo}
            dataTransformer={EventHelper.formatEvent}
          />
        </div>
      </div>
    );
  }
}

export default MergeAccount(
  EditPlan(
    PostViewer(EditAccount(EditPublisher(DeletePublisher(Error(Account)))))
  )
);
