import React from "react";

const allStatuses = {
  "0": "Active",
  "1": "Cannot Charge Card",
  "2": "Subscription Suspended",
  "3": "Violated Terms",
  "4": "Suspicious Activity",
  "5": "Pending Deletion"
};

const statusHelper = {
  getStatus: (key) => {
    return allStatuses[key];
  },
  getOptions: () => {
    let options = [];

    Object.entries(allStatuses).forEach(([key, value]) =>
      options.push(<option value={key}> {value} </option>)
    );

    return options;
  }
};

export default statusHelper;
