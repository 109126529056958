import React from "react";
import Loading from "./Loading";

import AccountTitle from "./Titles/AccountTitle";
import DefaultTitle from "./Titles/DefaultTitle";

import PostMenu from "./Menus/PostMenu";
import UserMenu from "./Menus/UserMenu";
import AccountMenu from "./Menus/AccountMenu";
import EventMenu from "./Menus/EventMenu";

const ActionHeaderFactory = {
  getLoader: () => {
    return <Loading />;
  },
  getTitle: (props) => {
    switch (props.resource) {
      case "Account":
        return <AccountTitle props={props} />;
      default:
        return <DefaultTitle props={props} />;
    }
  },
  getMenuContent: (props) => {
    switch (props.resource) {
      case "Post":
        return <PostMenu props={props} />;
      case "User":
        return <UserMenu props={props} />;
      case "Account":
        return <AccountMenu props={props} />;
      case "Event":
        return <EventMenu props={props} />;
      default:
        break;
    }
  }
};

export default ActionHeaderFactory;
