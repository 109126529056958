import React, { Component } from "react";
import Aux from "../Aux";
import classNames from "classnames";
import {
  Button,
  Classes,
  Intent,
  Overlay,
  H5,
  FormGroup,
  Icon,
  H4,
  Spinner
} from "@blueprintjs/core";
import ApiHelper from "../../helpers/ApiHelper";
import InputField from "../User/InputField";

const initialState = {
  editConfirmed: false,
  editLoading: false,
  editSuccess: false,
  editError: false,
  showForm: false,
  placeholderHost: "",
  host: "",
  hostValid: false,
  errorMessage: "",
  updatePublisherDetails: null
};

const EditPublisher = (WrappedComponent) => {
  return class extends Component {
    state = {
      editConfirmed: false,
      editLoading: false,
      editSuccess: false,
      editError: false,
      showForm: false,
      placeholderHost: "",
      host: "",
      hostValid: false,
      errorMessage: "",
      updatePublisherDetails: null
    };

    closeFormValid = () => {
      return !this.state.editLoading === true;
    };

    showEditPublisher = (placeholderHost, updatePublisherDetails) => {
      this.setState({
        showForm: true,
        placeholderHost: placeholderHost,
        updatePublisherDetails: updatePublisherDetails
      });
    };

    closeEditPublisher = () => {
      this.setState({ showForm: false });
      this.setState(initialState);
    };

    hostChanged = (e) => {
      this.setState({ host: e.target.value.trimLeft().trimRight() }, () => {
        this.validateHostName();
      });
    };

    validateHostName = () => {
      if (
        this.state.host &&
        this.state.host.length > 0 &&
        this.state.host.split(" ").length === 1
      ) {
        this.setState({ hostValid: true });
      } else {
        this.setState({ hostValid: false });
      }
    };

    submitValid = () => {
      return this.state.hostValid === true;
    };

    submitHandler = () => {
      this.setState({
        editLoading: true,
        editConfirmed: true
      });

      const accountID = window.location.pathname.substring(1);

      const link = "api/" + accountID.toString() + "/publisher";

      const body = {
        publisher: {
          host: this.state.host
        }
      };

      ApiHelper.sendEditRequest(link, body)
        .then((res) => {
          this.setState({ editLoading: false }, () =>
            this.setState({ editSuccess: true }, () => {
              this.state.updatePublisherDetails(res.data.data.host);
            })
          );
        })
        .catch((err) => {
          this.setState({ editLoading: false }, () =>
            this.setState({
              editError: true,
              errorMessage: err.response.data.info
            })
          );
        });
    };

    render() {
      const classes = classNames(
        Classes.CARD,
        Classes.ELEVATION_4,
        "Text-align--center",
        "container"
      );

      const formContent = (
        <div className={classes}>
          <FormGroup
            label={
              <span className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-fontWeight--medium Text-lineHeight--24 Text-typeface--base Text-wrap--wrap Text-display--inline">
                <span> Editing Publisher Details: </span>
              </span>
            }
            labelFor="text-input"
          >
            <div className="Flex-flex Flex-direction--column">
              <InputField
                status="Host:"
                placeholder={this.state.placeholderHost}
                valid={this.state.hostValid}
                changed={this.hostChanged}
              />
            </div>
          </FormGroup>

          <br />
          <div className="Text-align--center">
            <Button intent={Intent.DANGER} onClick={this.closeEditPublisher}>
              Close
            </Button>
            <Button
              className="Margin-left--12"
              intent={Intent.PRIMARY}
              onClick={this.submitHandler}
              disabled={!this.submitValid()}
            >
              Confirm Edit
            </Button>
          </div>
        </div>
      );

      const loading = (
        <div className={classes}>
          <Spinner size={48} />
        </div>
      );

      const successScreen = (
        <div className={classes}>
          <Icon icon="tick" iconSize="80" intent={Intent.SUCCESS} />
          <H4> Publisher details successfully updated</H4>
          <div className="Text-align--center">
            <Button intent={Intent.PRIMARY} onClick={this.closeEditPublisher}>
              Ok
            </Button>
          </div>
        </div>
      );

      const errorScreen = (
        <div className={classes}>
          <Icon icon="error" iconSize="80" intent={Intent.DANGER} />
          <div className="Margin-top--8">
            <H4> Publisher details unsuccessfully updated </H4>
            <H5> Error Message: {this.state.errorMessage} </H5>
          </div>

          <div className="Text-align--center">
            <Button intent={Intent.PRIMARY} onClick={this.closeEditPublisher}>
              Ok
            </Button>
          </div>
        </div>
      );

      const editForm = (
        <Overlay
          isOpen={this.state.showForm}
          onClose={this.closeEditPublisher}
          className={Classes.OVERLAY_SCROLL_CONTAINER}
          canEscapeKeyClose={this.closeFormValid()}
          canOutsideClickClose={this.closeFormValid()}
        >
          {!this.state.editConfirmed === true && formContent}
          {this.state.editLoading === true && loading}
          {this.state.editSuccess === true && successScreen}
          {this.state.editError === true && errorScreen}
        </Overlay>
      );

      return (
        <Aux>
          {this.state.showForm === true && editForm}
          <WrappedComponent
            {...this.props}
            showEditPublisher={this.showEditPublisher}
          />
        </Aux>
      );
    }
  };
};

export default EditPublisher;
