import React, { Component } from "react";
import { Button, ButtonGroup, Spinner } from "@blueprintjs/core";
import DataTable from "./DataTable.jsx";
import PaginationOptions from "./PaginationOptions.jsx";
import FilterHelper from "../../helpers/FilterHelper";
import FilterFactory from "../Filter/FilterFactory";

// PageableTable presents a list of objects, with pageable options on the bottom.
// It uses DataTable, and expects the DataTable props to be passed in via tableInfo

const ContentHeader = ({
  setPagination,
  setActiveFilters,
  hasFilters,
  filter
}) => {
  let filterCheck = false;

  if (hasFilters != null) {
    filterCheck = !(
      Object.entries(hasFilters).length === 0 &&
      hasFilters.constructor === Object
    );
  }

  return (
    <div className="ContentHeader Box-root Box-background--white Box-divider--light-bottom-1 Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-direction--column">
      <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween">
        {filter}
        <PaginationOptions onChange={setPagination} />
      </div>
      {filterCheck && (
        <div
          className="Margin-top--12 Box-root Flex-flex Flex-direction--row"
          id="activeFilters"
        >
          <div />
          {setActiveFilters}
        </div>
      )}
    </div>
  );
};

class PageableTable extends Component {
  render() {
    if (this.props.fetching) {
      return (
        <div className="Box-root Padding-bottom--12">
          <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
            <ContentHeader />
            <div className="ContentBlock Box-root Box-background--white Padding-all--64">
              <Spinner size={26} />
            </div>
          </div>
        </div>
      );
    } else {
      var objectCount = "";
      if (this.props.pagination.size >= this.props.pagination.count) {
        objectCount =
          this.props.pagination.count + "/" + this.props.pagination.count;
      } else {
        objectCount =
          this.props.pagination.size + "/" + this.props.pagination.count;
      }

      var bodyContent;
      if (this.props.pagination.total === 0) {
        bodyContent = (
          <div className="ContentState Box-root Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--center Flex-wrap--wrap Box-divider--light-bottom-1">
            <span className="ContentState-title Text-color--gray Text-align--center Text-fontSize--14 Text-fontWeight--regular Text-lineHeight--20 Text-typeface--base Text-wrap--wrap Text-display--block">
              <span>No {this.props.resourceName} </span>
            </span>
          </div>
        );
      } else {
        bodyContent = (
          <DataTable
            columnKeys={this.props.tableInfo.columnKeys}
            headerNames={this.props.tableInfo.headerNames}
            columnTypes={this.props.tableInfo.columnTypes}
            data={this.props.tableInfo.data}
            objectLink={this.props.tableInfo.objectLink}
            menuBuilder={this.props.tableInfo.menuBuilder}
            onClick={this.props.tableInfo.onClick}
            sortObj={this.props.sortObj}
          />
        );
      }

      const filter = FilterFactory.getFilter(
        this.props.resourceName,
        this.props.setFilter,
        this.props.filters,
        this.props.datePickerDate
      );

      return (
        <div className="Box-root Padding-bottom--12">
          <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
            <ContentHeader
              setPagination={this.props.setPagination}
              setActiveFilters={FilterHelper.getActiveFilters(
                this.props.filters
              )}
              hasFilters={this.props.filters}
              filter={filter}
            />
            <div className="ContentBlock Box-root Box-background--white ">
              {bodyContent}

              <div className="Box-root Flex-flex Flex-alignItems--center Flex-justifyContent--spaceBetween">
                <div className="Box-root Padding-all--20 Flex-flex Flex-alignItems--center">
                  <span className="Text-color--default Text-fontSize--14 Text-fontWeight--regular Text-lineHeight--20 Text-typeface--base Text-wrap--wrap Text-display--inline">
                    <span>
                      <span className="Text-color--default Text-fontSize--14 Text-fontWeight--medium Text-lineHeight--20 Text-typeface--base Text-wrap--wrap Text-display--inline">
                        {objectCount}
                      </span>{" "}
                      results
                    </span>
                  </span>
                </div>
                <div className="Box-root Padding-all--20 Flex-flex Flex-alignItems--center">
                  <span className="Text-color--default Text-fontSize--14 Text-fontWeight--regular Text-lineHeight--20 Text-typeface--base Text-wrap--wrap Text-display--inline">
                    <span>
                      <span className="Text-color--default Text-fontSize--14 Text-fontWeight--medium Text-lineHeight--20 Text-typeface--base Text-wrap--wrap Text-display--inline">
                        {this.props.pagination.number}/
                        {this.props.pagination.total}
                      </span>{" "}
                      pages
                    </span>
                  </span>
                </div>
                <div className="Box-root Padding-horizontal--20 Padding-vertical--16">
                  <div className="Box-root">
                    <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--flexStart">
                      <ButtonGroup>
                        <Button
                          onClick={this.props.prevPage}
                          disabled={this.props.pagination.number === 1}
                        >
                          Prev
                        </Button>
                        <Button
                          onClick={this.props.nextPage}
                          disabled={
                            this.props.pagination.number ===
                            this.props.pagination.total
                          }
                        >
                          Next
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default PageableTable;
