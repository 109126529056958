import React from "react";
import { Spinner } from "@blueprintjs/core";
import ContentHeader from "./ContentHeader";

const LoadingBlock = (props) => {
  return (
    <div className="Box-root Padding-bottom--12">
      <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
        <ContentHeader title={props.title} />
        <div className="ContentBlock Box-root Box-background--white">
          <div className="bs-PropertyList">
            <Spinner size={26} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingBlock;
