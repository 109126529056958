import React, { Component } from "react";
import Aux from "./Aux";
import classNames from "classnames";
import { Button, Classes, Intent, Overlay, H5 } from "@blueprintjs/core";
import ApiHelper from "../helpers/ApiHelper";

const DeleteHandler = (WrappedComponent) => {
  return class extends Component {
    state = {
      deleteHandlerOpen: false
    };

    showDeleteHandler = (resource, history) => {
      console.log(history);
      this.setState({
        deleteHandlerOpen: true,
        resource: resource,
        history: history
      });
    };

    closeDeleteHandler = () => {
      this.setState({ deleteHandlerOpen: false });
    };

    submitHandler = () => {
      const link = "api/" + window.location.pathname.substring(1);
      ApiHelper.sendDeleteRequest(link);
      this.state.history.push("/posts");
    };

    render() {
      const classes = classNames(
        Classes.CARD,
        Classes.ELEVATION_4,
        "Text-align--center",
        "container"
      );

      const popupBox = (
        <Overlay
          isOpen={this.state.deleteHandlerOpen}
          onClose={this.closeDeleteHandler}
          className={Classes.OVERLAY_SCROLL_CONTAINER}
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
        >
          <div className={classes}>
            <H5>
              {" "}
              Are you sure you want to delete this {this.state.resource}?{" "}
            </H5>

            <br />
            <div className="Text-align--center">
              <Button intent={Intent.DANGER} onClick={this.closeDeleteHandler}>
                Close
              </Button>
              <Button
                className="Margin-left--12"
                intent={Intent.PRIMARY}
                onClick={this.submitHandler}
              >
                Submit
              </Button>
            </div>
          </div>
        </Overlay>
      );
      return (
        <Aux>
          {popupBox}
          <WrappedComponent
            {...this.props}
            showDeleteHandler={this.showDeleteHandler}
          />
        </Aux>
      );
    }
  };
};

export default DeleteHandler;
