import React, { Component } from "react";
import DataTable from "./Table/DataTable";
import { Spinner, ProgressBar, Intent } from "@blueprintjs/core";

const ContentHeader = ({ title, data, requiredImageCount }) => {
  const truePosts =
    data.filter((image) => image.available === "true").length /
    requiredImageCount;

  let intent;
  if (truePosts === 1) {
    intent = Intent.SUCCESS;
  } else if (truePosts > 1) {
    intent = Intent.DANGER;
  } else {
    intent = Intent.PRIMARY;
  }

  return (
    <div className="ContentHeader Box-root Box-background--white Box-divider--light-bottom-1 Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-direction--column">
      <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween">
        <span className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-fontWeight--medium Text-lineHeight--24 Text-typeface--base Text-wrap--wrap Text-display--inline">
          <span>{title}</span>
        </span>
        <div className="Flex-flex Flex-direction--row">
          <div
            className="Flex-flex Flex-direction--column"
            style={{ width: "100px", marginTop: "-5px" }}
          >
            <span
              className="Badge-text Text-color--blue600 Text-fontSize--12 Text-fontWeight--medium Text-lineHeight--16 Text-typeface--base Text-wrap--noWrap Text-display--inline"
              style={{ textAlign: "center" }}
            >
              <span>{`${Math.round(truePosts * 100, 2)} %`}</span>
            </span>
            <ProgressBar intent={intent} animate={false} value={truePosts} />
          </div>
          <div className="Margin-left--20 Badge Box-root Box-background--blue100 Padding-horizontal--8 Padding-vertical--2 Flex-inlineFlex Flex-alignItems--center Flex-direction--row">
            <span className="Badge-text Text-color--blue600 Text-fontSize--12 Text-fontWeight--medium Text-lineHeight--16 Text-typeface--base Text-wrap--noWrap Text-display--inline">
              <span>{`${data.length}/${requiredImageCount}`}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

class SearchTableSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const dataExists = this.props.data.length > 0;

    if (this.props.loading) {
      return (
        <div className="Box-root Padding-bottom--12">
          <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
            <ContentHeader title={this.props.title} />
            <div className="Box-root Padding-all--12">
              <Spinner size={26} />
            </div>
          </div>
        </div>
      );
    } else {
      if (dataExists) {
        return (
          <div className="Box-root Padding-bottom--12">
            <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
              <ContentHeader
                title={this.props.title}
                data={this.props.data}
                requiredImageCount={this.props.requiredImageCount}
              />
              <div>
                <DataTable
                  columnKeys={this.props.tableInfo.columnKeys}
                  headerNames={this.props.tableInfo.headerNames}
                  columnTypes={this.props.tableInfo.columnTypes}
                  data={this.props.data}
                  objectLink={this.props.tableInfo.objectLink}
                  menuBuilder={this.props.tableInfo.menuBuilder}
                />
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="Box-root Padding-bottom--12">
            <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
              <div className="ContentHeader Box-root Box-background--white Box-divider--light-bottom-1 Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-direction--column">
                <span className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-fontWeight--medium Text-lineHeight--24 Text-typeface--base Text-wrap--wrap Text-display--inline">
                  <span>{this.props.title}</span>
                </span>
              </div>
              <div className="ContentBlock Box-root Box-background--white">
                <div className="ContentState Box-root Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--center Flex-wrap--wrap">
                  <span className="ContentState-title Text-color--gray Text-align--center Text-fontSize--14 Text-fontWeight--regular Text-lineHeight--20 Text-typeface--base Text-wrap--wrap Text-display--block">
                    <span>No {this.props.title}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

export default SearchTableSection;
