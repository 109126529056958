import React from "react";

const EmptyCell = () => {
  return (
    <td
      className="Table-cell 
                    Table-cell--align--right 
                    Table-cell--verticalAlign--top 
                    Table-cell--width--minimized 
                    Table-cell--wrap--noWrap 
                    db-ListViewItem-cell 
                    db-ListViewItem-text"
    >
      <div className="db-ListViewItem-cellContent Box-root Padding-all--8" />
    </td>
  );
};

export default EmptyCell;
