import React from "react";

export const MetaField = ({meta_data}) => {
  return (
    <div className="Badge Box-root Box-background--blue100 Text-color--blue600
      Padding-horizontal--8 Padding-vertical--2 Flex-inlineFlex Flex-alignItems--center
      Flex-direction--row Margin-top--2 Margin-bottom--2 Margin-left-4 Margin-right--4">
      {meta_data}
    </div>
  );
};
