import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const DiffSearchCard = (props) => {
  const subListing = props.subTitles.map((subTitle) => {
    return <li>{subTitle}</li>;
  });

  return (
    <Link to={props.link}>
      <div class={props.className}>
        <div class="card-header">
          {props.objectType}: {props.title}
        </div>
        <div class="card-main">
          <div class="main-description">
            <ul className="dashedList">{subListing}</ul>
          </div>
        </div>
      </div>
    </Link>
  );
};

DiffSearchCard.propTypes = {
  link: PropTypes.string.isRequired,
  subTitles: PropTypes.array.isRequired,
  objectType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
};

export default DiffSearchCard;
