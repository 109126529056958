import React, { Component } from "react";
import Aux from "./Aux";
import {
  Button,
  Classes,
  Intent,
  Overlay,
  FormGroup,
  InputGroup,
  Icon
} from "@blueprintjs/core";
import classNames from "classnames";
import axios from "axios";
import AuthHelper from "../helpers/AuthHelper";
import { config } from "./../config";

const baseUrl = config.apiUrl;

const Form = (WrappedComponent) => {
  return class extends Component {
    state = {
      showForm: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      firstNameValid: false,
      lastNameValid: false,
      emailValid: false,
      passwordValid: false,
      data: null
    };

    getData = (data) => {
      console.log(data);
      this.setState({ data: data });
    };

    showFormHandler = () => {
      this.setState({ showForm: true });
    };

    closeFormHandler = () => {
      this.setState({
        showForm: false,
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        firstNameValid: false,
        lastNameValid: false,
        emailValid: false,
        passwordValid: false,
        data: null
      });
    };

    firstNameChanged = (e) => {
      this.setState({ firstName: e.target.value.trimLeft().trimRight() }, () =>
        this.validateFirstName()
      );
    };

    lastNameChanged = (e) => {
      this.setState({ lastName: e.target.value }, () => {
        this.validateLastName();
      });
    };

    emailChanged = (e) => {
      this.setState({ email: e.target.value }, () => {
        this.validateEmail();
      });
    };

    passwordChanged = (e) => {
      this.setState({ password: e.target.value }, () => {
        this.validatePassword();
      });
    };

    validateFirstName = () => {
      if (
        this.state.firstName &&
        this.state.firstName.length > 0 &&
        this.state.firstName.split(" ").length === 1
      ) {
        this.setState({ firstNameValid: true });
      } else {
        this.setState({ firstNameValid: false });
      }
    };

    validateLastName = () => {
      if (
        this.state.lastName &&
        this.state.lastName.length > 0 &&
        this.state.lastName.split(" ").length === 1
      ) {
        this.setState({ lastNameValid: true });
      } else {
        this.setState({ lastNameValid: false });
      }
    };

    validateEmail = () => {
      if (
        this.state.email &&
        this.state.email.length > 0 &&
        this.state.email.split(" ").length === 1 &&
        !this.state.data.includes(this.state.email)
      ) {
        this.setState({ emailValid: true });
      } else {
        this.setState({ emailValid: false });
      }
    };

    validatePassword = () => {
      if (
        this.state.password &&
        this.state.password.length > 7 &&
        this.state.password.split(" ").length === 1
      ) {
        this.setState({ passwordValid: true });
      } else {
        this.setState({ passwordValid: false });
      }
    };

    submitValid = () => {
      return (
        this.state.firstNameValid === true &&
        this.state.lastNameValid === true &&
        this.state.emailValid === true &&
        this.state.passwordValid === true
      );
    };

    submitHandler = async () => {
      const body = {
        user: {
          f_name: this.state.firstName,
          l_name: this.state.lastName,
          email: this.state.email,
          unsafe_password: this.state.password
        }
      };

      const url = `${baseUrl}api/admin-users`;

      try {
        await axios({
          method: "post",
          url: url,
          data: body,
          headers: {
            Authorization: "Bearer " + AuthHelper.getUserToken()
          }
        });

        this.setState({
          showForm: false,
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          firstNameValid: false,
          lastNameValid: false,
          emailValid: false,
          passwordValid: false,
          data: null
        });
      } catch (error) {
        this.setState({ error: error });
        console.log(error);
      }
    };

    render() {
      const classes = classNames(
        Classes.CARD,
        Classes.ELEVATION_4,
        "Text-align--center",
        "container"
      );

      const form = (
        <Overlay
          isOpen={this.state.showForm}
          onClose={this.handleClose}
          className={Classes.OVERLAY_SCROLL_CONTAINER}
          canEscapeKeyClose={"true"}
          canOutsideClickClose={"true"}
        >
          <div className={classes}>
            <FormGroup
              label="Creating New Admin: Enter required details"
              labelFor="text-input"
              labelInfo="(required)"
            >
              <div className="Margin-top--12" />
              <InputGroup
                id="text-input"
                placeholder="First Name"
                rightElement={
                  this.state.firstNameValid === true ? (
                    <Icon
                      className="Margin-all--4"
                      icon={"tick"}
                      iconSize="20"
                    />
                  ) : (
                    <Icon
                      className="Margin-all--4"
                      icon={"cross"}
                      iconSize="20"
                    />
                  )
                }
                type="text"
                onChange={this.firstNameChanged}
              />
              <InputGroup
                className="Margin-top--8"
                id="text-input"
                placeholder="Last Name"
                rightElement={
                  this.state.lastNameValid === true ? (
                    <Icon
                      className="Margin-all--4"
                      icon={"tick"}
                      iconSize="20"
                    />
                  ) : (
                    <Icon
                      className="Margin-all--4"
                      icon={"cross"}
                      iconSize="20"
                    />
                  )
                }
                type="text"
                onChange={this.lastNameChanged}
              />
              <InputGroup
                className="Margin-top--8"
                id="text-input"
                placeholder="Email"
                type="email"
                rightElement={
                  this.state.emailValid === true ? (
                    <Icon
                      className="Margin-all--4"
                      icon={"tick"}
                      iconSize="20"
                    />
                  ) : (
                    <Icon
                      className="Margin-all--4"
                      icon={"cross"}
                      iconSize="20"
                    />
                  )
                }
                onChange={this.emailChanged}
              />
              <InputGroup
                className="Margin-top--8"
                id="text-input"
                placeholder="Password"
                rightElement={
                  this.state.passwordValid === true ? (
                    <Icon
                      className="Margin-all--4"
                      icon={"tick"}
                      iconSize="20"
                    />
                  ) : (
                    <Icon
                      className="Margin-all--4"
                      icon={"cross"}
                      iconSize="20"
                    />
                  )
                }
                type="password"
                onChange={this.passwordChanged}
              />
            </FormGroup>

            <br />
            <div className="Text-align--center">
              <Button intent={Intent.DANGER} onClick={this.closeFormHandler}>
                Close
              </Button>
              <Button
                className="Margin-left--12"
                intent={Intent.PRIMARY}
                disabled={!this.submitValid()}
                onClick={this.submitHandler}
              >
                Submit
              </Button>
            </div>
          </div>
        </Overlay>
      );

      return (
        <Aux>
          {form}
          <WrappedComponent
            {...this.props}
            showFormHandler={this.showFormHandler}
            showForm={this.state.showForm}
            getData={this.getData}
          />
        </Aux>
      );
    }
  };
};

export default Form;
