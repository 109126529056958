import React from "react";
import { Icon } from "@blueprintjs/core";
import { Link } from "react-router-dom";

const ToAccountRow = (props) => {
  return (
    <div className="bs-PropertyList-row">
      <div className="bs-PropertyList-property">
        <span>{props.label}</span>
      </div>
      <div className="bs-PropertyList-definition">
        <Link
          className="Flex-flex Flex-direction--row"
          to={`/accounts/${props.value.accountID}`}
        >
          <span>{props.value.f_name + " " + props.value.l_name}</span>
          <div className="Margin-left--4">
            <Icon icon="arrow-right" />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ToAccountRow;
