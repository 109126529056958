import ApiHelper from "./ApiHelper";

const statuses = {
  "0": "Ok",
  "1": "Preparing Revision"
};

const postRevisionStatuses = {
  "0": "Active",
  "1": "Insufficient Images",
  "2": "Preparing HTML",
  "3": "Rendered",
  "4": "Failed",
  "5": "Archive"
};

const PostHelper = {
  transformPost: (post) => {
    let level;
    let key;
    if (post.live === true) {
      key = "Live";
      level = "good";
    } else {
      key = "Inactive";
      level = "bad";
    }
    return {
      ...post,
      key: post.id,
      statusCombined: { key: key, level: level }
    };
  },
  formatSimplePost: (post) => {
    let transformedPost = PostHelper.transformPost(post);

    transformedPost.live = transformedPost.live
      .toString()
      .replace(/^\w/, (c) => c.toUpperCase());
    transformedPost.status = statuses[transformedPost.status];

    const name = post.f_name + " " + post.l_name;
    transformedPost.accountName = name;
    
    return transformedPost;
  },
  formatPost: (post) => {
    let transformedPost = PostHelper.transformPost(post);

    transformedPost.live = transformedPost.live
      .toString()
      .replace(/^\w/, (c) => c.toUpperCase());
    transformedPost.status = statuses[transformedPost.status];

    if (transformedPost.pending_revision !== null) {
      if (transformedPost.pending_revision.keywords !== null) {
        transformedPost.pending_revision.keywords = transformedPost.pending_revision.keywords.join(
          ", "
        );
      }

      if (transformedPost.pending_revision.status !== null) {
        transformedPost.pending_revision.status =
          postRevisionStatuses[transformedPost.pending_revision.status];
      }
    }

    if (transformedPost.live_revision !== null) {
      if (transformedPost.live_revision.keywords !== null) {
        transformedPost.live_revision.keywords = transformedPost.live_revision.keywords.join(
          ", "
        );
      }

      if (transformedPost.live_revision.status !== null) {
        transformedPost.live_revision.status =
          postRevisionStatuses[transformedPost.live_revision.status];
      }
    }

    if (transformedPost.image_info !== undefined) {
      transformedPost.image_info.forEach((image) => {
        image.keywords = image.keywords.join(", ");
        image.available = image.available.toString();
      });
    }

    if (transformedPost.user !== null) {
      const { f_name, l_name } = transformedPost.user;
      const name = f_name + " " + l_name;
      transformedPost.accountName = name;
    }

    if (transformedPost.user !== null) {
      transformedPost.user.accountID = transformedPost.account_id;
    }

    return transformedPost;
  },
  livePostRevisionExists: (post) => {
    return post.live_revision !== null;
  },
  pendingPostRevisionExists: (post) => {
    return post.pending_revision !== null;
  },
  whichPill: (value) => {
    const key = Object.keys(postRevisionStatuses).find(
      (key) => postRevisionStatuses[key] === value
    );

    const numberKey = parseInt(key);

    if (numberKey === 0) {
      return true;
    } else if (numberKey === 4) {
      return "Failed";
    } else {
      return false;
    }
  }
};

export default PostHelper;
