import React, { useState } from "react";
import DateButtons from "./DateButtons";
import StatBlock from "./StatBlock";
import DateHelper from "../../../helpers/DateHelper";

const DateBlock = (props) => {
  const [currentRange, setCurrentRange] = useState("24h");
  const [currentSeg, setCurrentSeg] = useState("Hourly");
  const [firstDate, setCurrentFirstDate] = useState(
    DateHelper.getFirstDate("24h")
  );
  const [secondDate, setCurrentSecondDate] = useState(
    DateHelper.getSecondDate("24h")
  );

  const onChangedHandler = (range, seg, firstDate, secondDate) => {
    setCurrentRange(range);
    setCurrentSeg(seg);
    setCurrentFirstDate(firstDate);
    setCurrentSecondDate(secondDate);
  };

  return (
    <div className="Card-root Card--radius--all Card--shadow--medium Margin-bottom--12 Margin-top--12 statsBackground">
      <div className="Flex-flex Flex-direction--column">
        <div className="Box-background--white Padding-top--12 Padding-bottom--16 Padding-horizontal--20 Text-color--white ">
          <DateButtons onChangedHandler={onChangedHandler} />
        </div>

        <StatBlock
          resource={"Accounts"}
          currentSeg={currentSeg}
          currentRange={currentRange}
          firstDate={firstDate}
          secondDate={secondDate}
        />
        <StatBlock
          resource={"Posts"}
          currentSeg={currentSeg}
          currentRange={currentRange}
          firstDate={firstDate}
          secondDate={secondDate}
        />
        <StatBlock
          resource={"Images"}
          currentSeg={currentSeg}
          currentRange={currentRange}
          firstDate={firstDate}
          secondDate={secondDate}
        />

        <StatBlock
          resource={"Posts Retrieved"}
          currentSeg={currentSeg}
          currentRange={currentRange}
          firstDate={firstDate}
          secondDate={secondDate}
        />
      </div>
    </div>
  );
};

export default DateBlock;
