import React, { Component } from "react";
import { InputGroup, Card, Elevation } from "@blueprintjs/core";
import ReactDOM from "react-dom";
import ApiHelper from "../../helpers/ApiHelper";
import Aux from "../../hoc/Aux";
import SearchHelper from "../../helpers/SearchHelper";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import SearchResults from "./SearchResults";

import { Redirect } from "react-router";

import { createBrowserHistory } from "history";
import AppToaster from "../AppToaster";

// searchAPIDebounced is for ensuring that we restrict the api calls so
// when the user stops typing the call is then sent after a brief delay.

const searchAPI = (param) => ApiHelper.getRequest("api/search", param);
const searchAPIDebounced = AwesomeDebouncePromise(searchAPI, 300);

const selectors = [
  "plan_id:",
  "host:",
  "email:",
  "subscription:",
  "post_name:"
];

class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {},
      searchCards: [],
      inputText: "",
      loading: false,
      noResult: false,
      colonSeen: false,
      hasPrefix: true,
      inputFocus: false,
      selector: "",
      currentParams: {},
      navigate: false,
      onSearch: false
    };
  }

  componentDidMount() {
    this.setStyle(ReactDOM.findDOMNode(this).getBoundingClientRect());
  }

  setStyle = (dimensions) => {
    const style = {
      position: "absolute",
      top: `${dimensions.top + 31}px`,
      left: `${dimensions.left}px`,
      width: `${dimensions.width + 190}px`
    };

    this.setState({ style: style });
  };

  inputChangedHandler = async (event) => {
    console.log(this.props);
    const searchQuery = event.target.value.trimRight();

    // If the search query is a plan then get the value of the corresponding key
    // in the search helper. For example if the input is Pro Year then this becomes
    // narrative_pro_year to make searching more accurate.

    const planSearchQuery = SearchHelper.getPlan(searchQuery);
    const finalSearchQuery =
      planSearchQuery != null ? planSearchQuery : searchQuery;

    let selector;
    let param;

    this.setState({ inputText: finalSearchQuery.toLowerCase() }, () => {
      if (this.isColonSeen(finalSearchQuery)) {
        selector = finalSearchQuery.split(":");
        this.setState({ colonSeen: true });
        this.setState({ hasPrefix: true });

        let selectorParam = selector[0].trimLeft().trimRight();
        let queryParam = selector[1].trimLeft().trimRight();

        const planQuery = SearchHelper.getPlan(queryParam);

        const finalQueryParam = planQuery != null ? planQuery : queryParam;

        if (finalQueryParam.length > 0) {
          param = {
            selector: selectorParam,
            query: finalQueryParam
          };

          this.setState({ currentParams: param });

          this.getData(param);
        }
      } else {
        this.setState({ colonSeen: false });
        this.setState({ hasPrefix: this.hasPrefix(finalSearchQuery) });

        param = {
          query: this.state.inputText
        };

        this.setState({ currentParams: param });

        this.getData(param);
      }
    });
  };

  getData = async (param) => {
    this.setState({ loading: true });

    const response = await searchAPIDebounced(param);

    console.log(response);

    this.setState({ loading: false });

    if (response === "redirect") {
      this.props.history.push("/login");
    }

    if (response === undefined || response.data.length === 0) {
      this.setState({
        searchCards: [
          <div className="Margin-top--16">
            <p> No Results!</p>
          </div>
        ],
        noResult: true
      });

      return;
    }

    this.setState({
      searchCards: SearchHelper.getSearchCards(response, this.props),
      noResult: false
    });
  };

  isColonSeen = (text) => {
    if (text.includes(":") && text.split(":").length === 2) {
      return true;
    }

    return false;
  };

  hasPrefix = () => {
    return selectors
      .map((selector) => selector.substring(0, this.state.inputText.length))
      .map((subSelector) => subSelector === this.state.inputText)
      .reduce((init = false, currVal) => init || currVal);
  };

  getSelectorListItems = () => {
    // let booleanMask = selectors
    //     .map(selector => selector.substring(0, this.state.inputText.length))
    //     .map(subSelector => subSelector === this.state.inputText);

    // return selectors
    // .filter((item, i) => booleanMask[i])
    // .map(item => <li> {item} </li>);

    var input = this.state.inputText;
    return selectors
      .filter((selector) => selector.substring(0, input.length) === input)
      .map((item) => <li> {item} </li>);
  };

  inputSelected = (e) => {
    this.setState({ inputFocus: true });
  };

  inputDeselected = (e) => {
    this.setState({ inputFocus: false });
  };

  enterPressed = (e) => {
    let code = e.keyCode || e.which;

    // Pressed enter
    if (code === 13) {
      if (this.state.inputText !== "") {
        const history = createBrowserHistory();
        history.push(
          "/search/" + ApiHelper.getParams(this.state.currentParams)
        );
        history.go(0);
      } else {
        AppToaster.show({
          message: "Please enter a valid search query"
        });
      }
    }
  };

  render() {
    const divStyle = {
      marginTop: "-25px",
      marginBottom: "-20px"
    };

    const searchResults = (
      <SearchResults
        loading={this.state.loading}
        searchCards={this.state.searchCards}
      />
    );

    const selectorList = (
      <ul className="Margin-top--4 Padding-all--4">
        {this.getSelectorListItems()}
      </ul>
    );

    const result =
      this.state.colonSeen || !this.state.hasPrefix
        ? searchResults
        : selectorList;

    const { navigate } = this.state;

    if (navigate) {
      const url = "/search/" + ApiHelper.getParams(this.state.currentParams);
      return <Redirect to={url} push={true} />;
    }

    return (
      <Aux>
        <div>
          <InputGroup
            onClick={this.inputSelected}
            onChange={this.inputChangedHandler}
            onKeyPress={this.enterPressed}
            className="searchBox"
            leftIcon="search"
          />
        </div>

        {this.state.inputFocus && (
          <div>
            <Card
              className="scroll"
              style={this.state.style}
              interactive={false}
              elevation={Elevation.TWO}
            >
              <div style={divStyle}>{result}</div>
            </Card>
          </div>
        )}

        {this.state.inputFocus && (
          <div className="searchBackDrop" onClick={this.inputDeselected} />
        )}
      </Aux>
    );
  }
}

export default SearchBox;
