import React, { Component } from "react";
import NavHeader from "../NavHeader";
import PaginationHelper from "../../helpers/PaginationHelper";
import AdminTable from "./AdminTable";
import ApiHelper from "../../helpers/ApiHelper";
import AdminHelper from "../../helpers/AdminHelper";
import ObjectFactory from "../../helpers/ObjectFactory/ObjectFactory";

class Admins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetched: false,
      pagination: { number: 1, size: PaginationHelper.getPageSize() }
    };
  }

  componentDidMount() {
    this.fetchCurrentPage(this.state.pagination);
  }

  fetchCurrentPage = (pagination) => {
    const pageParams = ApiHelper.buildPaginationParams(
      pagination.number,
      pagination.size
    );
    const params = { ...pageParams };

    ApiHelper.getRequest("api/admin-users", params)
      .then((res) => {
        if (res === "redirect") {
          this.props.history.push("/login");
        }

        console.log(res);
        const pagination = res.data.pagination;
        const admins = AdminHelper.getAdmins(res);

        this.setState(
          {
            fetched: true,
            admins: admins,
            pagination: pagination
          },
          () => {
            if (
              this.state.pagination.number > this.state.pagination.total &&
              this.state.pagination.total !== 0
            ) {
              const newPagination = {
                ...this.state.pagination,
                number: this.state.pagination.total
              };

              this.fetchCurrentPage(newPagination);
            }
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  incrementPage = () => {
    if (this.state.pagination.total === this.state.pagination.number) return;
    const newPage = {
      ...this.state.pagination,
      number: this.state.pagination.number + 1
    };
    this.setState({ fetched: false, pagination: newPage });
    this.fetchCurrentPage(newPage);
  };

  decrementPage = () => {
    if (this.state.pagination.number === 1) return;
    const newPage = {
      ...this.state.pagination,
      number: this.state.pagination.number - 1
    };
    this.setState({ fetched: false, pagination: newPage });
    this.fetchCurrentPage(newPage);
  };

  render() {
    console.log(this.state.admins);
    return (
      <div>
        <NavHeader />
        <div className="World-container">
          <AdminTable
            className="container-box"
            fetching={!this.state.fetched}
            pagination={this.state.pagination}
            resourceName="Admins"
            nextPage={this.incrementPage}
            prevPage={this.decrementPage}
            tableInfo={ObjectFactory.admins(this.state.admins)}
            fetchPage={this.fetchCurrentPage}
          />
        </div>
      </div>
    );
  }
}

export default Admins;
