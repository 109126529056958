import React from "react";
import Aux from "../../../hoc/Aux";
import { Icon } from "@blueprintjs/core";

const AccountTitle = (props) => {
  return (
    <Aux>
      <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween">
        <div className="ContentHeader-center Box-root Flex-flex Flex-direction--column Flex-justifyContent--center">
          <span className="ContentHeader-title Text-color--dark Text-fontSize--32 Text-fontWeight--medium Text-lineHeight--24 Text-typeface--base Text-wrap--wrap Text-display--inline">
            <span> {props.props.name}</span>
          </span>
        </div>
      </div>
      <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween Margin-top--24">
        <div className="ContentHeader-center Box-root Flex-flex Flex-direction--column Flex-justifyContent--center">
          <span className="ContentHeader-title Text-color--dark Text-fontSize--20 Text-fontWeight--regular Text-lineHeight--24 Text-typeface--base Text-wrap--wrap Text-display--inline">
            {props.props.title !== undefined && (
              <a
                className="Flex-flex Flex-direction--row"
                href={`https://${props.props.title}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <span>{props.props.title} </span>
                <div
                  className="Margin-left--8"
                  style={{ position: "relative", bottom: "3px" }}
                >
                  <Icon icon="share" />
                </div>
              </a>
            )}
            {props.props.title === undefined && (
              <span>{props.props.placeholder}</span>
            )}
          </span>
        </div>
      </div>
    </Aux>
  );
};

export default AccountTitle;
