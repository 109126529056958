import React, { useState, useEffect } from "react";
import classNames from "classnames";
import ApiHelper from "../../../helpers/ApiHelper";
import Loading from "./Loading";

const capitalize = ([s, ...tring]) => [s.toUpperCase(), ...tring].join("");

const LifetimeStatBlock = (props) => {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState("");

  const fetchData = async () => {
    setLoading(true);
    const endPoint = "api/statistics/count";
    const param = {
      resources: props.resource
    };

    try {
      const result = await ApiHelper.getRequest(endPoint, param);
      const { data } = result;
      setResult(data[props.resource]);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div
        className={classNames("Flex-grow--1", props.align)}
        style={props.style}
      >
        <div className="Flex-flex Flex-direction--column">
          <span className="Text-lineHeight--40 Text-fontWeight--medium Text-fontSize--32 Text-color--black">
            {capitalize(props.resource) + "s"}
          </span>
          <div className="Margin-top--4">
            <span className="Text-lineHeight--72 Text-fontWeight--regular Text-fontSize--48 Text-color--blue">
              {result}
            </span>
          </div>
        </div>
      </div>
    );
  }
};

export default LifetimeStatBlock;
