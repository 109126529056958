import React from "react";
import { Button } from "@blueprintjs/core";
import CopyButton from "./CopyButton";
import AppToaster from "../../AppToaster";

const PostMenu = (props) => {
  return (
    <div className="Margin-top--8">
      <CopyButton
        text={props.props.post_id}
        onCopy={() =>
          AppToaster.show({ message: "Post ID copied to clipboard" })
        }
      />
      <CopyButton
        className={"Margin-left--16"}
        text={props.props.postHTML}
        onCopy={() =>
          AppToaster.show({ message: "Post HTML copied to clipboard" })
        }
        copyTitle={"Copy Post HTML"}
      />
      <Button
        className="Margin-left--16"
        onClick={() => props.props.onDeleteClick("post", props.props.history)}
      >
        Delete Post
      </Button>
      <Button
        className="Margin-left--16"
        onClick={() => props.props.onPreviewPostClick(props.props.post_id)}
      >
        Preview Post
      </Button>
    </div>
  );
};

export default PostMenu;
