import React, { Component } from "react";
import { Menu, MenuItem, Spinner } from "@blueprintjs/core";

import NavHeader from "./NavHeader.jsx";

import ActionHeader from "./ActionHeader/ActionHeader";
import Details from "./Details/Details";

import ApiHelper from "../helpers/ApiHelper.js";
import EventHelper from "../helpers/EventHelper.js";
import ObjectFactory from "../helpers/ObjectFactory/ObjectFactory";
import Error from "../hoc/Error";

class Event extends Component {
  constructor(props) {
    super(props);

    const event_id = this.props.match.params.id;
    this.state = { fetched: false, event_id: event_id };
  }

  componentDidMount() {
    this.fetchAccount();
  }

  componentDidUpdate() {
    if (this.state.event_id !== this.props.match.params.id) {
      this.setState({ event_id: this.props.match.params.id }, () =>
        this.fetchAccount()
      );
    }
  }

  fetchAccount() {
    ApiHelper.getRequest("api/events/" + this.state.event_id, null)
      .then((res) => {
        const event = EventHelper.formatEvent(res.data.data);
        this.setState({ fetched: true, event: event });
      })
      .catch((error) => {
        console.log(error);
        this.props.errorHandler(error);
      });
  }

  render() {
    return (
      <div>
        <NavHeader />

        <div className="World-container">
          <ActionHeader
            resource="Event"
            fetching={!this.state.fetched}
            title={
              this.state.event === undefined
                ? undefined
                : this.state.event.topic
            }
            placeholder=""
            event_id={this.state.event_id}
          />

          <Details
            data={this.state.event}
            fetching={!this.state.fetched}
            info={ObjectFactory.event.detailInfo}
            title="Details"
          />

          <div className="Box-root Padding-bottom--12">
            <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
              <div className="ContentHeader Box-root Box-background--white Box-divider--light-bottom-1 Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-direction--column">
                <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween">
                  <span className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-fontWeight--medium Text-lineHeight--24 Text-typeface--base Text-wrap--wrap Text-display--inline">
                    <span>Data</span>
                  </span>
                </div>
              </div>

              {this.state.fetched && (
                <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
                  <div className="Padding-all--20">
                    <pre>
                      <code
                        style={{
                          "font-family": "monospace"
                        }}
                      >
                        {JSON.stringify(this.state.event.data, null, 2)}
                      </code>
                    </pre>
                  </div>
                </div>
              )}
              {!this.state.fetched && (
                <div className="Box-root Padding-all--12">
                  <Spinner size={26} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Error(Event);
