const UserFactory = {
  detailInfo: [
    {
      title: "User Info",
      rows: [
        { key: "account_id", label: "Account ID", type: "ToAccountFromUser" },
        { key: "id", label: "User ID", type: "Code" },
        { key: "f_name", label: "First Name" },
        { key: "l_name", label: "Last Name" },
        { key: "email", label: "Email", type: "Email" },
        { key: "inserted_at", label: "Created", type: "Date" },
        { key: "updated_at", label: "Updated", type: "Date" }
      ]
    },
    {
      title: "App usage",
      rows: [
        { key: "app_usage.p_count", label: "Processor Count" },
        { key: "app_usage.os_ver", label: "Operating System" },
        { key: "app_usage.m_count", label: "Memory" },
        { key: "app_usage.l_p_count", label: "Local Posts" },
        { key: "app_usage.e_l_count", label: "Exported Layout" },
        { key: "app_usage.e_c_count", label: "Exported Collage" },
        { key: "app_usage.app_ver", label: "App Version" }
      ]
    }
  ],
  firstPromoterInfo: [
    {
      title: "Details",
      rows: [{ key: "referral_ref_id", label: "Coupon Code", type: "Code" }]
    },
    {
      title: "",
      rows: []
    }
  ]
};

export default UserFactory;
