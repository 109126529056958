import React, { Component } from "react";
import Aux from "../Aux";
import classNames from "classnames";
import {
  Button,
  Classes,
  Intent,
  Overlay,
  H5,
  FormGroup,
  Icon,
  H4,
  Spinner,
  HTMLSelect,
  InputGroup,
  Checkbox
} from "@blueprintjs/core";
import OptionCheckbox from "./OptionCheckbox";
import AccountHelper from "../../helpers/AccountHelper";
import ApiHelper from "../../helpers/ApiHelper";

const initialState = {
  editConfirmed: false,
  editLoading: false,
  editSuccess: false,
  editError: false,
  showForm: false,
  currentActive: ""
};

const EditPlan = (WrappedComponent) => {
  return class extends Component {
    state = {
      editConfirmed: false,
      editLoading: false,
      editSuccess: false,
      editError: false,
      showForm: false,
      currentActive: "",
      yearlyChecked: false,
      couponCode: "",
      couponCodeValid: false,
      updateAccountPlanDetails: null
    };

    couponChanged = (e) => {
      this.setState(
        { couponCode: e.target.value.trimLeft().trimRight() },
        () => {
          this.validateCoupon();
        }
      );
    };

    validateCoupon = () => {
      if (
        this.state.couponCode &&
        this.state.couponCode.length > 0 &&
        this.state.couponCode.split(" ").length === 1
      ) {
        this.setState({ couponCodeValid: true });
      } else {
        this.setState({ couponCodeValid: false });
      }
    };

    activeChangedHandler = (e) => {
      this.setState({ currentActive: e.currentTarget.value });
    };

    yearlyHandler = () => {
      this.setState((prevState) => ({
        yearlyChecked: !prevState.yearlyChecked
      }));
    };

    closeFormValid = () => {
      return !this.state.editLoading === true;
    };

    showEditPlan = (initialPlan, updateAccountPlanDetails) => {
      this.setState({
        showForm: true,
        updateAccountPlanDetails: updateAccountPlanDetails
      });

      this.setState(initialPlan);
    };

    closeEditPlan = () => {
      this.setState({ showForm: false });
      this.setState(initialState);
    };

    submitHandler = () => {
      this.setState({
        editLoading: true,
        editConfirmed: true
      });

      const obj = {
        Free: "narrative_free",
        Lite: "narrative_lite",
        Basic: "narrative_basic",
        Pro: "narrative_pro"
      };

      const accountID = window.location.pathname.substring(1);
      const link = "api/" + accountID.toString() + "/subscription";

      const body = {
        account: {
          plan_id: obj[this.state.currentActive],
          yearly: this.state.yearlyChecked
        }
      };

      if (this.state.couponCodeValid === true) {
        body.account.coupon = this.state.couponCode;
      }

      console.log(body);

      ApiHelper.sendEditRequest(link, body)
        .then((res) => {
          this.setState({ editLoading: false }, () =>
            this.setState({ editSuccess: true }, () => {
              this.state.updateAccountPlanDetails(
                AccountHelper.formatAccount(res.data.account)
              );
            })
          );
        })
        .catch((err) => {
          console.log(err);
          this.setState({ editLoading: false }, () =>
            this.setState({
              editError: true,
              errorMessage: err.response.data.info
            })
          );
        });
    };

    render() {
      const classes = classNames(
        Classes.CARD,
        Classes.ELEVATION_4,
        "Text-align--center",
        "container"
      );

      const formContent = (
        <div className={classes}>
          <FormGroup
            label={
              <span className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-fontWeight--medium Text-lineHeight--24 Text-typeface--base Text-wrap--wrap Text-display--inline">
                <span> Editing Account Plan: </span>
              </span>
            }
            labelFor="text-input"
          >
            <div className="Flex-flex Flex-direction--column">
              <div className="Margin-top--12" />
              <div className="Flex-flex Flex-direction--row">
                <span>{"Active:"}</span>
                <HTMLSelect
                  fill
                  className="Margin-left--8 Bottom-5"
                  options={["Free", "Lite", "Basic", "Pro"]}
                  value={this.state.currentActive}
                  onChange={this.activeChangedHandler}
                />
              </div>

              <div className="Margin-top--12" />
              <div className="Flex-flex Flex-direction--row">
                <div>
                  <span>{"Yearly:"}</span>
                </div>
                <div className="Flex-flex Flex-direction--column">
                  <Checkbox
                    checked={this.state.yearlyChecked}
                    onChange={this.yearlyHandler}
                    className="Margin-left--4"
                    style={{ bottom: "5px", left: "5px" }}
                  />
                </div>
              </div>

              <div className="Margin-top--12" />
              <div className="Flex-flex Flex-direction--row Flex-justifyContent--flexStart">
                <div>
                  <span>{"Coupon:"}</span>
                </div>
                <div className="Margin-left--4">
                  <InputGroup
                    style={{ bottom: "3px" }}
                    rightElement={
                      this.state.couponCodeValid === true ? (
                        <Icon
                          className="Margin-all--4"
                          icon={"tick"}
                          iconSize="20"
                        />
                      ) : (
                        <Icon
                          className="Margin-all--4"
                          icon={"cross"}
                          iconSize="20"
                        />
                      )
                    }
                    onChange={this.couponChanged}
                  />
                </div>
              </div>
            </div>
          </FormGroup>

          <br />
          <div className="Text-align--center">
            <Button intent={Intent.DANGER} onClick={this.closeEditPlan}>
              Close
            </Button>
            <Button
              className="Margin-left--12"
              intent={Intent.PRIMARY}
              onClick={this.submitHandler}
            >
              Confirm Edit
            </Button>
          </div>
        </div>
      );

      const loading = (
        <div className={classes}>
          <Spinner size={48} />
        </div>
      );

      const successScreen = (
        <div className={classes}>
          <Icon icon="tick" iconSize="80" intent={Intent.SUCCESS} />
          <H4> Account plan successfully updated</H4>
          <div className="Text-align--center">
            <Button intent={Intent.PRIMARY} onClick={this.closeEditPlan}>
              Ok
            </Button>
          </div>
        </div>
      );

      const errorScreen = (
        <div className={classes}>
          <Icon icon="error" iconSize="80" intent={Intent.DANGER} />
          <div className="Margin-top--8">
            <H4> Account plan unsuccessfully updated </H4>
            <H5> Error Message: {this.state.errorMessage} </H5>
          </div>

          <div className="Text-align--center">
            <Button intent={Intent.PRIMARY} onClick={this.closeEditPlan}>
              Ok
            </Button>
          </div>
        </div>
      );

      const editForm = (
        <Overlay
          isOpen={this.state.showForm}
          onClose={this.closeEditPlan}
          className={Classes.OVERLAY_SCROLL_CONTAINER}
          canEscapeKeyClose={this.closeFormValid()}
          canOutsideClickClose={this.closeFormValid()}
        >
          {!this.state.editConfirmed === true && formContent}
          {this.state.editLoading === true && loading}
          {this.state.editSuccess === true && successScreen}
          {this.state.editError === true && errorScreen}
        </Overlay>
      );

      return (
        <Aux>
          {this.state.showForm === true && editForm}
          <WrappedComponent {...this.props} showEditPlan={this.showEditPlan} />
        </Aux>
      );
    }
  };
};

export default EditPlan;
