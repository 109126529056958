const postSorts = {
  name: {
    ascending: "name",
    descending: "-name"
  },
  statusCombined: {
    ascending: "status",
    descending: "-status"
  },
  created_at: {
    ascending: "inserted_at",
    descending: "-inserted_at"
  },
  updated_at: {
    ascending: "updated_at",
    descending: "-updated_at"
  }
};

const eventSorts = {
  topic_name: {
    ascending: "topic",
    descending: "-topic"
  },
  inserted_at: {
    ascending: "inserted_at",
    descending: "-inserted_at"
  },
  updated_at: {
    ascending: "updated_at",
    descending: "-updated_at"
  },
  transaction_id: {
    ascending: "transaction_id",
    descending: "-transaction_id"
  }
};

const getSortObj = (objectName) => {
  switch (objectName) {
    case "posts":
      return postSorts;
    case "users":
      return;
    case "events":
      return eventSorts;
    default:
      return;
  }
};

const getSort = {
  getSortObj: (objectName) => {
    switch (objectName) {
      case "posts":
        return postSorts;
      case "users":
        return;
      case "events":
        return eventSorts;
      default:
        return;
    }
  },
  getDefaultState: (objectName) => {
    switch (objectName) {
      case "posts":
        return ["created_at", true];
      case "users":
        return ["created_at", true];
      case "events":
        return ["inserted_at", true];
      default:
        return;
    }
  }
};

export default getSort;
