import React, { Component } from "react";
import { Checkbox, HTMLSelect } from "@blueprintjs/core";
import StatusHelper from "../../../helpers/StatusHelper";

class FilterStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: this.props.isChecked
    };
  }

  componentDidMount() {
    this.setState({ isChecked: this.props.isChecked });
    StatusHelper.getOptions();
  }

  handleCheckbox = () => {
    this.setState(
      (prevState) => ({
        isChecked: !prevState.isChecked
      }),
      () => this.props.statusToggledHandler(this.state.isChecked)
    );
  };

  handleStatusHTMLSelectChange = (event) => {
    const newStatus = event.currentTarget.value;
    this.props.statusChangedHandler(newStatus);
  };

  render() {
    return (
      <div>
        <div className="Padding-all--2">
          <Checkbox
            label="Status"
            checked={this.state.isChecked}
            onChange={this.handleCheckbox}
          />
          {this.state.isChecked && (
            <HTMLSelect
              className="Flex-flex"
              value={this.props.currentStatus}
              onChange={this.handleStatusHTMLSelectChange}
              disabled={!this.state.isChecked}
            >
              {StatusHelper.getOptions()}
            </HTMLSelect>
          )}
        </div>
      </div>
    );
  }
}

export default FilterStatus;
