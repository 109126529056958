import React from "react";
import { NumericInput } from "@blueprintjs/core";
const moment = require("moment-timezone");

const TopBar = (props) => {
  let current;
  let target;
  let difference;
  let perDayRequired;

  if (props.target && props.current) {
    current = props.current;
    target = props.target[1];
    difference = target - current;
    perDayRequired = Math.floor(
      difference / moment(props.target[0]).diff(moment(), "days")
    );
  }
  const onRateChange = (e) => {
    if (e <= 0) {
      props.setGrowthRate(8);
    } else {
      props.setGrowthRate(e);
    }
  };
  return (
    <div className="Box-background--white Padding-top--12 Padding-bottom--16 Padding-horizontal--20 Text-color--white ">
      <div className="Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween ">
        <div>
          <span className="Text-lineHeight--40 Text-fontWeight--medium Text-fontSize--32 Text-color--black">
            Active Subscribers
          </span>
        </div>

        <div className="Flex-flex Flex-direction--row Margin-top--4">
          <div>
            <span className="Text-lineHeight--40 Text-fontWeight--medium Text-fontSize--20 Text-color--black">
              Current: {current}
            </span>
          </div>

          <div className="Margin-left--32">
            <span className="Text-lineHeight--40 Text-fontWeight--medium Text-fontSize--20 Text-color--black">
              Target: {target}
            </span>
          </div>

          <div className="Margin-left--32">
            <span className="Text-lineHeight--40 Text-fontWeight--medium Text-fontSize--20 Text-color--black">
              Difference: {difference} ({perDayRequired} per day)
            </span>
          </div>
        </div>

        <div className="Flex-flex Flex-direction--row Margin-top--8">
          <div style={{ marginTop: "-5px" }}>
            <span className="Text-lineHeight--40 Text-fontWeight--medium Text-fontSize--20 Text-color--black">
              Growth Rate (%)
            </span>
          </div>

          <div className="Margin-left--8">
            <NumericInput
              style={{ width: "40px" }}
              min={1}
              value={props.growthRate}
              allowNumericCharactersOnly={true}
              onValueChange={(e) => onRateChange(e)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
