import MenuBuilder from "./MenuBuilder.js";

const TableInfoFactory = {
  accounts: (data) => {
    return {
      columnKeys: [
        "name",
        "email",
        "host",
        "status",
        "inserted_at",
        "plan_name",
        "post_count"
      ],
      headerNames: {
        name: "Name",
        email: "Email",
        host: "host",
        status: "Status",
        inserted_at: "Created At",
        plan_name: "Plan",
        post_count: "Post Count"
      },
      columnTypes: { status: "Pill", inserted_at: "Date" },
      data: data,
      objectLink: "accounts",
      menuBuilder: MenuBuilder.accountMenu
    };
  },
  events: (data) => {
    return {
      columnKeys: [
        "topic_name",
        "state",
        "email",
        "transaction_id",
        "occurred_at"
      ],
      headerNames: {
        topic_name: "Topic",
        state: "Level",
        email: "User",
        transaction_id: "Transaction",
        occurred_at: "Occurred At"
      },
      columnTypes: {
        occurred_at: "OccurredDate",
        updated_at: "Date",
        state: "Pill"
      },
      objectLink: "events",
      menuBuilder: MenuBuilder.eventMenu,
      data: data
    };
  },
  posts: (data, onClick) => {
    return {
      columnKeys: [
        "name",
        "meta",
        "live",
        "status",
        "accountName",
        "inserted_at",
        "updated_at"
      ],
      headerNames: {
        name: "Name",
        meta: "Metadata",
        live: "Live",
        status: "Status",
        accountName: "Account Name",
        inserted_at: "Created At",
        updated_at: "Updated At"
      },
      columnTypes: { status: "Pill", inserted_at: "Date", updated_at: "Date", meta: "Meta" },
      objectLink: "posts",
      menuBuilder: MenuBuilder.postMenu,
      data: data,
      onClick: onClick
    };
  },
  users: (data) => {
    return {
      columnKeys: [
        "f_name",
        "l_name",
        "email",
        "account_id",
        "inserted_at",
        "updated_at"
      ],
      headerNames: {
        f_name: "First Name",
        l_name: "Last Name",
        email: "Email",
        inserted_at: "created at",
        updated_at: "updated at"
      },
      columnTypes: { inserted_at: "Date", updated_at: "Date" },
      data: data,
      objectLink: "users",
      menuBuilder: MenuBuilder.userMenu
    };
  },
  admins: (data) => {
    return {
      columnKeys: ["name", "email"],
      headerNames: {
        name: "Name",
        email: "Email"
      },
      columnTypes: { name: "Static", email: "Static" },
      data: data,
      objectLink: "admins",
      menuBuilder: MenuBuilder.adminMenu
    };
  }
};

export default TableInfoFactory;
