import React, { Component } from "react";
import { Checkbox } from "@blueprintjs/core";
import { DatePicker } from "@blueprintjs/datetime";

class FilterDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: this.props.isChecked
    };
  }

  componentDidMount() {
    this.setState({ isChecked: this.props.isChecked });
  }

  handleCheckbox = () => {
    this.setState(
      (prevState) => ({
        isChecked: !prevState.isChecked
      }),
      () => this.props.dateToggledHandler(this.state.isChecked)
    );
  };

  handleDateChange = (newDate) => {
    this.props.dateChangedHandler(newDate);
  };

  render() {
    return (
      <div>
        <div className="Padding-all--2">
          <Checkbox
            label="Date"
            checked={this.state.isChecked}
            onChange={this.handleCheckbox}
          />
          {this.state.isChecked && (
            <DatePicker
              value={this.props.currentDate}
              canClearSelection="true"
              showActionsBar="true"
              onChange={this.handleDateChange}
            />
          )}
        </div>
      </div>
    );
  }
}

export default FilterDate;
