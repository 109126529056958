import * as React from "react";
import classNames from "classnames";
import { Classes, InputGroup, Button, Intent } from "@blueprintjs/core";
import { Formik, Form, ErrorMessage } from "formik";

const FirstPromoterForm = ({ initialValues, onCloseForm, onSubmit }) => {
  return (
    <div
      className={classNames(
        Classes.CARD,
        Classes.ELEVATION_4,
        "Text-align--center",
        "container"
      )}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ values, handleChange, isSubmitting }) => (
          <Form>
            <div className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-fontWeight--medium Text-lineHeight--24 Text-typeface--base Text-wrap--wrap Margin-bottom--12">
              <span> Editing First Promoter Details: </span>
            </div>
            <InputGroup
              placeholder="Coupon Code"
              type="text"
              name="referral_ref_id"
              value={values.referral_ref_id}
              onChange={handleChange}
            />
            <ErrorMessage name="referral_ref_id" component="div" />
            <div className="Text-align--center Margin-top--12">
              <Button intent={Intent.DANGER} onClick={onCloseForm}>
                Close
              </Button>
              <Button
                className="Margin-left--12"
                intent={Intent.PRIMARY}
                disabled={isSubmitting}
                type="submit"
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FirstPromoterForm;
