import React from "react";

const StatCell = (props) => {
  return (
    <td
      className="Table-cell 
                Table-cell--align--right 
                Table-cell--verticalAlign--top 
                Table-cell--width--minimized 
                Table-cell--wrap--noWrap 
                StatCell
                db-ListViewItem-text"
      style={{ lineHeight: "60px" }}
    >
      <div className="db-ListViewItem-cellContent Box-root Padding-all--8">
        <span className="db-ListViewItem-text Text-color--black Text-fontSize--32 Text-lineHeight--20 Text-typeface--base Text-wrap--wrap Text-display--inline Text-fontWeight--bold">
          <span>{props.data}</span>
        </span>
      </div>
    </td>
  );
};

export default StatCell;
