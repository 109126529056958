var moment = require("moment-timezone");

const DateHelper = {
  formatDate: (date) => {
    return moment.utc(date).format("DD/MM/YYYY h:mm:ss a");
  },
  getMomentDate: (date) => {
    const tz = moment.tz.guess();
    const newDate = moment(date)
      .tz(tz)
      .format("DD/MM/YYYY, h:mm:ss a");
    // console.log(`Old date was ${date}, new date is ${newDate} `);
    return newDate;
  },
  getISODate: (date) => {
    return new Date(
      date.getTime() + Math.abs(date.getTimezoneOffset() * 60000)
    ).toISOString();
  },
  formatDateWithoutTime: (date) => {
    return moment.utc(date).format("DD/MM/YYYY");
  },
  getDayDates: (which) => {
    if (which === 1) {
      const obj = {
        toSend: [],
        toShowOnButton: []
      };

      const tz = moment.tz.guess();

      const newDate = moment()
        .startOf("minute")
        .tz(tz);

      const otherDate = moment()
        .startOf("minute")
        .tz(tz)
        .subtract(1, "days");

      obj["toSend"] = [otherDate.toISOString(), newDate.toISOString()];

      obj["toShowOnButton"] = [otherDate.format("LL"), newDate.format("LL")];

      obj["toDatePicker"] = obj.toShowOnButton.map((x) => new Date(x));
      return obj;
    } else {
      const obj = {
        toSend: [],
        toShowOnButton: []
      };

      const tz = moment.tz.guess();

      const newDate = moment()
        .startOf("minute")
        .tz(tz)
        .subtract(1, "days");

      const otherDate = moment()
        .startOf("minute")
        .tz(tz)
        .subtract(2, "days");

      obj["toSend"] = [otherDate.toISOString(), newDate.toISOString()];

      obj["toShowOnButton"] = [otherDate.format("LL"), newDate.format("LL")];

      obj["toDatePicker"] = obj.toShowOnButton.map((x) => new Date(x));

      return obj;
    }
  },
  getWeekDates: (which) => {
    if (which === 1) {
      const obj = {
        toSend: [],
        toShowOnButton: []
      };

      const tz = moment.tz.guess();

      const newDate = moment()
        .tz(tz)
        .endOf("day");

      const otherDate = moment()
        .startOf("day")
        .tz(tz)
        .subtract(1, "week");

      obj["toSend"] = [otherDate.toISOString(), newDate.toISOString()];

      obj["toShowOnButton"] = [otherDate.format("LL"), newDate.format("LL")];

      obj["toDatePicker"] = obj.toShowOnButton.map((x) => new Date(x));

      return obj;
    } else {
      const obj = {
        toSend: [],
        toShowOnButton: []
      };

      const tz = moment.tz.guess();

      const newDate = moment()
        .tz(tz)
        .endOf("day")
        .subtract(1, "week");

      const otherDate = moment()
        .startOf("day")
        .tz(tz)
        .subtract(2, "week");

      obj["toSend"] = [otherDate.toISOString(), newDate.toISOString()];

      obj["toShowOnButton"] = [otherDate.format("LL"), newDate.format("LL")];

      obj["toDatePicker"] = obj.toShowOnButton.map((x) => new Date(x));

      return obj;
    }
  },
  getMonthDates: (which) => {
    if (which === 1) {
      const obj = {
        toSend: [],
        toShowOnButton: []
      };

      const tz = moment.tz.guess();

      const newDate = moment()
        .tz(tz)
        .endOf("day");

      const otherDate = moment()
        .startOf("day")
        .tz(tz)
        .subtract(1, "month");

      obj["toSend"] = [otherDate.toISOString(), newDate.toISOString()];

      obj["toShowOnButton"] = [otherDate.format("LL"), newDate.format("LL")];

      obj["toDatePicker"] = obj.toShowOnButton.map((x) => new Date(x));

      return obj;
    } else {
      const obj = {
        toSend: [],
        toShowOnButton: []
      };

      const tz = moment.tz.guess();

      const newDate = moment()
        .endOf("day")
        .tz(tz)
        .subtract(1, "month");

      const otherDate = moment()
        .startOf("day")
        .tz(tz)
        .subtract(2, "month");

      obj["toSend"] = [otherDate.toISOString(), newDate.toISOString()];

      obj["toShowOnButton"] = [otherDate.format("LL"), newDate.format("LL")];

      obj["toDatePicker"] = obj.toShowOnButton.map((x) => new Date(x));

      return obj;
    }
  },
  getWeekToDayDates: (which) => {
    if (which === 1) {
      const obj = {
        toSend: [],
        toShowOnButton: []
      };

      const tz = moment.tz.guess();

      const newDate = moment().tz(tz);

      const otherDate = moment()
        .startOf("week")
        .tz(tz)
        .add(1, "day");

      obj["toSend"] = [otherDate.toISOString(), newDate.toISOString()];

      obj["toShowOnButton"] = [otherDate.format("LL"), newDate.format("LL")];

      obj["toDatePicker"] = obj.toShowOnButton.map((x) => new Date(x));

      return obj;
    } else {
      const obj = {
        toSend: [],
        toShowOnButton: []
      };

      const tz = moment.tz.guess();

      const newDate = moment()
        .tz(tz)
        .subtract(1, "week");

      const otherDate = moment()
        .startOf("week")
        .tz(tz)
        .add(1, "day")
        .subtract(1, "week");

      obj["toSend"] = [otherDate.toISOString(), newDate.toISOString()];

      obj["toShowOnButton"] = [otherDate.format("LL"), newDate.format("LL")];

      obj["toDatePicker"] = obj.toShowOnButton.map((x) => new Date(x));

      return obj;
    }
  },
  getMonthToDayDates: (which) => {
    if (which === 1) {
      const obj = {
        toSend: [],
        toShowOnButton: []
      };

      const tz = moment.tz.guess();

      const newDate = moment().tz(tz);

      const otherDate = moment()
        .tz(tz)
        .startOf("Month");

      obj["toSend"] = [otherDate.toISOString(), newDate.toISOString()];

      obj["toShowOnButton"] = [otherDate.format("LL"), newDate.format("LL")];

      obj["toDatePicker"] = obj.toShowOnButton.map((x) => new Date(x));

      return obj;
    } else {
      const obj = {
        toSend: [],
        toShowOnButton: []
      };

      const tz = moment.tz.guess();

      const newDate = moment()
        .tz(tz)
        .subtract(1, "month");

      const otherDate = moment()
        .tz(tz)
        .subtract(1, "month")
        .startOf("Month");

      obj["toSend"] = [otherDate.toISOString(), newDate.toISOString()];

      obj["toShowOnButton"] = [otherDate.format("LL"), newDate.format("LL")];

      obj["toDatePicker"] = obj.toShowOnButton.map((x) => new Date(x));

      return obj;
    }
  },
  getAllDates: (which) => {
    const obj = {
      toSend: [],
      toShowOnButton: []
    };

    const tz = moment.tz.guess();

    const newDate = moment().tz(tz);

    const otherDate = moment("01-01-2018");

    obj["toSend"] = [otherDate.toISOString(), newDate.toISOString()];

    obj["toShowOnButton"] = [otherDate.format("LL"), newDate.format("LL")];

    obj["toDatePicker"] = obj.toShowOnButton.map((x) => new Date(x));

    return obj;
  },
  getFirstDate: (measure) => {
    switch (measure) {
      case "24h":
        return DateHelper.getDayDates(1);
      case "1w":
        return DateHelper.getWeekDates(1);
      case "4w":
        return DateHelper.getMonthDates(1);
      case "Wtd":
        return DateHelper.getWeekToDayDates(1);
      case "Mtd":
        return DateHelper.getMonthToDayDates(1);
      case "All":
        return DateHelper.getAllDates();
      default:
        break;
    }
  },
  getSecondDate: (measure) => {
    switch (measure) {
      case "24h":
        return DateHelper.getDayDates(2);
      case "1w":
        return DateHelper.getWeekDates(2);
      case "4w":
        return DateHelper.getMonthDates(2);
      case "Wtd":
        return DateHelper.getWeekToDayDates(2);
      case "Mtd":
        return DateHelper.getMonthToDayDates(2);
      case "All":
        return DateHelper.getAllDates();
      default:
        break;
    }
  },
  getSubscriberDates: () => {
    const tz = moment.tz.guess();
    let fromDate;
    fromDate = moment("06-01-2019").toISOString();

    // Safari Bug where toIsoString doesn't work
    if (fromDate == null) {
      fromDate = "2019-05-31T12:00:00.000Z";
    }

    const todaysDate = moment()
      .tz(tz)
      .toISOString();

    const param = {
      from_date: fromDate,
      to_date: todaysDate
    };

    return param;
  },
  getRetentionFromDate: () => {
    const tz = moment.tz.guess();

    // Get date 4 weeks back and the monday
    const prevDate = moment()
      .tz(tz)
      .subtract(1, "month")
      .day(1)
      .toISOString();

    return prevDate;
  },
  getRetentionToDate: () => {
    const tz = moment.tz.guess();
    const todaysDate = moment()
      .tz(tz)
      .toISOString();

    return todaysDate;
  },
  getRetentionPickerDates: (fromDate, toDate) => {
    const dates = [fromDate, toDate];
    const tz = moment.tz.guess();
    const jsDates = dates.map((date) => new Date(date));
    const datesMapped = dates.map((date) => new Date(moment(date).tz(tz)));

    return datesMapped;
  }
};

export default DateHelper;
