import React from "react";

const EmptyRow = (props) => {
  return (
    <div className="bs-PropertyList-row">
      <div className="bs-PropertyList-property">
        <span>{props.label}</span>
      </div>
      <div className="bs-PropertyList-definition bs-is-empty">
        <span>None</span>
      </div>
    </div>
  );
};

export default EmptyRow;
