import React, { Component } from "react";
import { Popover, Position, Button } from "@blueprintjs/core";
import DateHelper from "../../helpers/DateHelper";
import FilterPlan from "./FilterOptions/FilterPlan";
import FilterStatus from "./FilterOptions/FilterStatus";
import FilterActive from "./FilterOptions/FilterActive";
import FilterEmail from "./FilterOptions/FilterEmail";
import FilterDate from "./FilterOptions/FilterDate";

class AccountFilter extends Component {
  state = {
    planIDOn: false,
    statusOn: false,
    activeOn: false,
    emailOn: false,
    dateOn: false,
    planID: "Pro",
    status: "0",
    active: "true",
    email: "",
    date: "",
    datePickerDate: null
  };

  componentDidMount() {
    if (
      !(
        Object.entries(this.props.previousState).length === 0 &&
        this.props.previousState.constructor === Object
      )
    ) {
      this.setState(this.props.previousState);
    }
  }

  anyFiltersActive = () => {
    return !(
      this.state.planIDOn ||
      this.state.statusOn ||
      this.state.activeOn ||
      this.state.emailOn ||
      this.state.dateOn
    );
  };

  updateFilterState = (currentState) => {
    var filters = {};

    if (currentState.planIDOn === true) {
      filters["filter[plan_id]"] = currentState.planID;
    }

    if (currentState.statusOn === true) {
      filters["filter[status]"] = currentState.status;
    }

    if (currentState.activeOn === true) {
      filters["filter[active]"] = currentState.active;
    }

    if (currentState.dateOn === true) {
      if (this.state.datePickerDate !== null) {
        filters["filter[inserted_at]"] = currentState.date;
      }
    }

    if (currentState.emailOn === true) {
      filters["filter[email]"] = currentState.email;
    }

    this.props.onChange(filters, this.state.datePickerDate);
  };

  planIDToggledHandler = (isPlanChecked) => {
    this.setState({ planIDOn: isPlanChecked });
  };

  planIDChangedHandler = (newPlanID) => {
    this.setState({ planID: newPlanID });
  };

  statusToggledHandler = (isStatusChecked) => {
    this.setState({ statusOn: isStatusChecked });
  };

  statusChangedHandler = (newStatus) => {
    this.setState({ status: newStatus });
  };

  activeToggledHandler = (isActiveChecked) => {
    this.setState({ activeOn: isActiveChecked });
  };

  activeChangedHandler = (newActive) => {
    this.setState({ active: newActive });
  };

  emailToggledHandler = (isEmailChecked) => {
    this.setState({ emailOn: isEmailChecked });
  };

  emailedChangedHandler = (newEmail) => {
    this.setState({ email: newEmail });
  };

  dateToggledHandler = (isDateChecked) => {
    this.setState({ dateOn: isDateChecked });
  };

  dateChangedHandler = (newDate) => {
    if (newDate !== null) {
      const isoDate = DateHelper.getISODate(newDate);
      this.setState({ date: isoDate });
      this.setState({ datePickerDate: newDate });
    } else {
      this.setState({ date: null });
      this.setState({ datePickerDate: null });
    }
  };

  removeFilterClickedHandler = () => {
    const newState = {
      planIDOn: false,
      statusOn: false,
      activeOn: false,
      emailOn: false,
      dateOn: false,
      planID: "Pro",
      status: "0",
      active: "true",
      email: "",
      date: "",
      datePickerDate: null
    };

    this.setState(newState);
    this.updateFilterState(newState);
  };

  okClickedHandler = () => {
    this.updateFilterState(this.state);
  };

  render() {
    const filterOptions = (
      <div className="Padding-all--8">
        <div>
          <FilterPlan
            isChecked={this.state.planIDOn}
            currentPlan={this.state.planID}
            planIDToggledHandler={this.planIDToggledHandler}
            planIDChangedHandler={this.planIDChangedHandler}
          />
        </div>
        <div className="Margin-top--8">
          <FilterStatus
            isChecked={this.state.statusOn}
            currentStatus={this.state.status}
            statusToggledHandler={this.statusToggledHandler}
            statusChangedHandler={this.statusChangedHandler}
          />
        </div>
        <div className="Margin-top--8">
          <FilterActive
            isChecked={this.state.activeOn}
            currentActive={this.state.active}
            activeToggledHandler={this.activeToggledHandler}
            activeChangedHandler={this.activeChangedHandler}
          />
        </div>
        <div className="Margin-top--8">
          <FilterEmail
            isChecked={this.state.emailOn}
            currentEmail={this.state.email}
            emailToggledHandler={this.emailToggledHandler}
            emailChangedHandler={this.emailedChangedHandler}
          />
        </div>
        <div className="Margin-top--8">
          <FilterDate
            isChecked={this.state.dateOn}
            currentDate={this.state.datePickerDate}
            dateToggledHandler={this.dateToggledHandler}
            dateChangedHandler={this.dateChangedHandler}
          />
        </div>
        <div className="Padding-all--2">
          <Button
            className="Flex-alignContent--center"
            onClick={this.okClickedHandler}
          >
            Ok
          </Button>
        </div>
      </div>
    );

    return (
      <div>
        <Popover content={filterOptions} position={Position.BOTTOM_LEFT}>
          <Button icon="filter"> Filter </Button>
        </Popover>
        <Button
          onClick={this.removeFilterClickedHandler}
          disabled={this.anyFiltersActive()}
          icon="filter-remove"
        />
      </div>
    );
  }
}

export default AccountFilter;
