import React from "react";
import { InputGroup, Icon } from "@blueprintjs/core";

const EditUser = (props) => {
  return (
    <div className="Margin-top--12">
      <div className="Flex-flex Flex-direction--row Flex-justifyContent--flexEnd">
        <div className="Margin-left--8">
          <span> {props.status} </span>
        </div>

        <InputGroup
          className="Margin-left--8 Bottom-3"
          placeholder={props.placeholder}
          rightElement={
            props.valid === true ? (
              <Icon className="Margin-all--4" icon={"tick"} iconSize="20" />
            ) : (
              <Icon className="Margin-all--4" icon={"cross"} iconSize="20" />
            )
          }
          type="text"
          onChange={props.changed}
        />
      </div>
    </div>
  );
};

export default EditUser;
