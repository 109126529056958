import React from "react";
import { Popover, Position, Button } from "@blueprintjs/core";

const ActionCell = (props) => {
  return (
    <td
      className="Table-cell 
                    Table-cell--align--right 
                    Table-cell--verticalAlign--top 
                    Table-cell--width--minimized 
                    Table-cell--wrap--noWrap 
                    db-ListViewItem-cell 
                    db-ListViewItem-text"
    >
      <div className="db-ListViewItem-cellContent Box-root Padding-all--8">
        <div className="Box-root Flex-flex Flex-direction--rowReversed">
          <Popover
            className="table-info-button"
            content={props.menu}
            position={Position.BOTTOM_LEFT}
          >
            <Button minimal={true} icon="more" />
          </Popover>
        </div>
      </div>
    </td>
  );
};

export default ActionCell;
