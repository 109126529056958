import MenuBuilder from "./MenuBuilder.js";

const AccountFactory = {
  publisherDetailInfo: [
    {
      title: "Info",
      rows: [
        { key: "id", label: "ID", type: "Code" },
        { key: "created_at", label: "Created At", type: "Date" },
        { key: "updated_at", label: "Updated At", type: "Date" },
        { key: "host", label: "Host", type: "Host" }
      ]
    },
    {
      title: "",
      rows: [
        { key: "type", label: "Type" },
        { key: "status", label: "Status" },
        { key: "default_category", label: "Default Category" },
        { key: "categories", label: "Categories" },
        { key: "theme_info", label: "Theme" }
      ]
    }
  ],
  detailInfo: [
    {
      title: "Account Info",
      rows: [
        { key: "id", label: "ID", type: "Code" },
        { key: "inserted_at", label: "Created", type: "Date" },
        { key: "updated_at", label: "Updated", type: "Date" },
        { key: "active", label: "Active" }
      ]
    },
    {
      title: "Plan Info",
      rows: [
        { key: "plan_name", label: "Plan" },
        { key: "coupon", label: "Coupon" },
        {
          key: "stripe_cust_id",
          label: "Stripe Customer",
          type: "Stripe"
        }
      ]
    }
  ],
  userTableInfo: {
    columnKeys: ["fullName", "email", "inserted_at", "updated_at"],
    headerNames: {
      fullName: "Name",
      email: "Email",
      inserted_at: "created at",
      updated_at: "updated at"
    },
    columnTypes: { inserted_at: "Date", updated_at: "Date" },
    objectLink: "users",
    menuBuilder: MenuBuilder.userMenu
  },
  postTableInfo: {
    columnKeys: ["name", "statusCombined", "created_at", "updated_at"],
    headerNames: {
      name: "Name",
      statusCombined: "Status",
      created_at: "created at",
      updated_at: "updated at"
    },
    columnTypes: {
      statusCombined: "Pill",
      created_at: "Date",
      updated_at: "Date"
    },
    objectLink: "posts",
    menuBuilder: MenuBuilder.postMenu
  },
  eventTableInfo: {
    columnKeys: ["topic_name", "inserted_at", "updated_at", "transaction_id"],
    headerNames: {
      topic_name: "topic",
      inserted_at: "created at",
      updated_at: "updated at",
      transaction_id: "transaction"
    },
    columnTypes: {
      statusCombined: "Pill",
      inserted_at: "Date",
      updated_at: "Date"
    },
    objectLink: "events",
    menuBuilder: MenuBuilder.eventMenu
  }
};

export default AccountFactory;
