import React, { useState, useEffect } from "react";
import { ButtonGroup, Button, Popover, Icon } from "@blueprintjs/core";
import { DateRangePicker } from "@blueprintjs/datetime";
import DateButton from "./DateButton";
import SegmentButton from "./SegmentButton";
import DateHelper from "../../../helpers/DateHelper";
var moment = require("moment-timezone");

const checkIfNull = (arr) => arr.some((el) => el === null);

const DateButtons = (props) => {
  const [dayClicked, setDayClicked] = useState(true);
  const [weekClicked, setWeekClicked] = useState(false);
  const [monthClicked, setMonthClicked] = useState(false);
  const [weekToDateClicked, setWeekToDateClicked] = useState(false);
  const [monthToDateClicked, setMonthToDateClicked] = useState(false);
  const [allClicked, setAllClicked] = useState(false);
  const [currentButton, setCurrentButton] = useState("24h");

  const [hourlyClicked, setHourlyClicked] = useState(true);
  const [dailyClicked, setDailyClicked] = useState(false);
  const [weeklyClicked, setWeeklyClicked] = useState(false);
  const [monthlyClicked, setMonthlyClicked] = useState(false);
  const [yearlyClicked, setYearlyClicked] = useState(false);
  const [currentSegment, setCurrentSegment] = useState("Hourly");

  const [currentFirstDates, setCurrentFirstDates] = useState(
    DateHelper.getFirstDate("24h")
  );
  const [currentSecondDates, setCurrentSecondDates] = useState(
    DateHelper.getSecondDate("24h")
  );

  const measureButtonObj = {
    "24h": dayClicked,
    "1w": weekClicked,
    "4w": monthClicked,
    Wtd: weekToDateClicked,
    Mtd: monthToDateClicked,
    All: allClicked
  };

  const measureSegmentObj = {
    "24h": ["Hourly"],
    "1w": ["Daily", "Hourly"],
    "4w": ["Daily", "Weekly"],
    Wtd: ["Daily", "Hourly"],
    Mtd: ["Daily", "Weekly"],
    All: ["Monthly", "Yearly"]
  };

  const segmentButtonObj = {
    Hourly: hourlyClicked,
    Daily: dailyClicked,
    Weekly: weeklyClicked,
    Monthly: monthlyClicked,
    Yearly: yearlyClicked
  };

  const switchMeasureButtonsOff = () => {
    setDayClicked(false);
    setWeekClicked(false);
    setMonthClicked(false);
    setWeekToDateClicked(false);
    setMonthToDateClicked(false);
    setAllClicked(false);
  };

  const switchSegmentButtonsOff = () => {
    setHourlyClicked(false);
    setDailyClicked(false);
    setWeeklyClicked(false);
    setMonthlyClicked(false);
    setYearlyClicked(false);
  };

  const buttonChanged = (name) => {
    setCurrentButton(name);
    switchMeasureButtonsOff();

    setCurrentFirstDates(DateHelper.getFirstDate(name));
    setCurrentSecondDates(DateHelper.getSecondDate(name));

    switch (name) {
      case "24h":
        setDayClicked(true);
        switchSegmentButtonsOff();
        setHourlyClicked(true);
        setCurrentSegment("Hourly");
        props.onChangedHandler(
          name,
          "Hourly",
          DateHelper.getFirstDate(name),
          DateHelper.getSecondDate(name)
        );
        break;
      case "1w":
        setWeekClicked(true);
        switchSegmentButtonsOff();
        setDailyClicked(true);
        setCurrentSegment("Daily");
        props.onChangedHandler(
          name,
          "Daily",
          DateHelper.getFirstDate(name),
          DateHelper.getSecondDate(name)
        );
        break;
      case "4w":
        setMonthClicked(true);
        switchSegmentButtonsOff();
        setDailyClicked(true);
        setCurrentSegment("Daily");
        props.onChangedHandler(
          name,
          "Daily",
          DateHelper.getFirstDate(name),
          DateHelper.getSecondDate(name)
        );
        break;
      case "Wtd":
        setWeekToDateClicked(true);
        switchSegmentButtonsOff();
        setDailyClicked(true);
        setCurrentSegment("Daily");
        props.onChangedHandler(
          name,
          "Daily",
          DateHelper.getFirstDate(name),
          DateHelper.getSecondDate(name)
        );
        break;
      case "Mtd":
        setMonthToDateClicked(true);
        switchSegmentButtonsOff();
        setDailyClicked(true);
        setCurrentSegment("Daily");
        props.onChangedHandler(
          name,
          "Daily",
          DateHelper.getFirstDate(name),
          DateHelper.getSecondDate(name)
        );
        break;
      case "All":
        setAllClicked(true);
        switchSegmentButtonsOff();
        setMonthlyClicked(true);
        setCurrentSegment("Monthly");
        props.onChangedHandler(
          name,
          "Monthly",
          DateHelper.getFirstDate(name),
          DateHelper.getSecondDate(name)
        );
        break;
      default:
        break;
    }
  };

  const segmentButtonChanged = (segment) => {
    setCurrentSegment(segment);
    switchSegmentButtonsOff();

    props.onChangedHandler(
      currentButton,
      segment,
      currentFirstDates,
      currentSecondDates
    );

    switch (segment) {
      case "Hourly":
        setHourlyClicked(true);
        break;
      case "Daily":
        setDailyClicked(true);
        break;
      case "Weekly":
        setWeeklyClicked(true);
        break;
      case "Monthly":
        setMonthlyClicked(true);
        break;
      case "Yearly":
        setYearlyClicked(true);
        break;
      default:
        break;
    }
  };

  const segmentButtons = measureSegmentObj[currentButton].map((segment) => (
    <SegmentButton
      onChanged={segmentButtonChanged}
      clicked={segmentButtonObj[segment]}
      segment={segment}
    />
  ));

  const measureButtons = Object.entries(measureButtonObj).map((entry) => (
    <DateButton
      onChanged={buttonChanged}
      measure={entry[0]}
      clicked={entry[1]}
    />
  ));

  const firstDateChanged = (newDate) => {
    const newFirstDate = {
      ...currentFirstDates
    };

    newFirstDate.toDatePicker = newDate;

    if (!checkIfNull(newDate)) {
      switchMeasureButtonsOff();

      newFirstDate.toSend = newDate.map((date) =>
        moment(date)
          .tz(moment.tz.guess())
          .toISOString()
      );
      newFirstDate.toShowOnButton = newDate.map((date) =>
        moment(date)
          .tz(moment.tz.guess())
          .format("LL")
      );
    }

    setCurrentFirstDates(newFirstDate);
  };

  const secondDateChanged = (newDate) => {
    const newSecondDate = {
      ...currentSecondDates
    };

    if (!checkIfNull(newDate)) {
      switchMeasureButtonsOff();

      newSecondDate.toSend = newDate.map((date) =>
        moment(date)
          .tz(moment.tz.guess())
          .toISOString()
      );

      newSecondDate.toShowOnButton = newDate.map((date) =>
        moment(date)
          .tz(moment.tz.guess())
          .format("LL")
      );
    }

    newSecondDate.toDatePicker = newDate;

    setCurrentSecondDates(newSecondDate);
  };

  useEffect(() => {
    if (
      !checkIfNull(currentFirstDates.toDatePicker) &&
      !checkIfNull(currentSecondDates.toDatePicker)
    ) {
      props.onChangedHandler(
        currentButton,
        currentSegment,
        currentFirstDates,
        currentSecondDates
      );
    }
  }, [currentFirstDates, currentSecondDates]);

  return (
    <div className="Box-root Flex-flex Flex-direction--row">
      <ButtonGroup
        className="Card-root Card--radius--all Card--shadow--medium Box-background--white"
        minimal
      >
        {measureButtons}
      </ButtonGroup>

      <div className="Flex-flex Flex-inlineFlex">
        <Popover
          className="Margin-left--16"
          content={
            <DateRangePicker
              shortcuts={false}
              value={currentFirstDates["toDatePicker"]}
              onChange={firstDateChanged}
            />
          }
          target={
            <ButtonGroup
              className="Card-root Card--radius--all Card--shadow--medium Box-background--white"
              minimal
            >
              <Button className="">
                <div>
                  <span>{currentFirstDates["toShowOnButton"][0]}</span>
                  <Icon
                    className="Padding-all--2 Margin-left--4"
                    icon="arrow-right"
                  />
                  <span> {currentFirstDates["toShowOnButton"][1]} </span>
                </div>
              </Button>
            </ButtonGroup>
          }
        />

        <div className="Margin-left--12">
          <span className=" Text-color--black Text-fontSize--14 Text-lineHeight--32  Text-typeface--base Text-wrap--noWrap Text-display--inline">
            vs.
          </span>
        </div>

        <Popover
          className="Margin-left--16"
          content={
            <DateRangePicker
              shortcuts={false}
              value={currentSecondDates["toDatePicker"]}
              onChange={secondDateChanged}
            />
          }
          target={
            <ButtonGroup
              className="Card-root Card--radius--all Card--shadow--medium Box-background--white"
              minimal
            >
              <Button className="">
                <div>
                  <span>{currentSecondDates["toShowOnButton"][0]}</span>
                  <Icon
                    className="Padding-all--2 Margin-left--4"
                    icon="arrow-right"
                  />
                  <span>{currentSecondDates["toShowOnButton"][1]}</span>
                </div>
              </Button>
            </ButtonGroup>
          }
        />
      </div>

      <ButtonGroup
        className="Card-root Card--radius--all Card--shadow--medium Box-background--white Margin-left--16"
        minimal
      >
        {segmentButtons}
      </ButtonGroup>
    </div>
  );
};

export default DateButtons;
