import React from "react";

const Header = (props) => {
  return (
    <span className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-fontWeight--medium Text-lineHeight--24 Text-typeface--base Text-wrap--wrap Text-display--inline">
      <span> {props.title} </span>
    </span>
  );
};

export default Header;
