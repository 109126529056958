import React from "react";

const ColumnHeader = (props) => {
  return (
    <h5 className="bs-PropertyList-label">
      <span>{props.title}</span>
    </h5>
  );
};

export default ColumnHeader;
