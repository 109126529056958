import React, { Component } from "react";
import DateHelper from "../../helpers/DateHelper";
import FilterDate from "./FilterOptions/FilterDate";
import { Button, Position, Popover } from "@blueprintjs/core";

class PostFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateOn: false,
      date: "",
      datePickerDate: null
    };
  }

  componentDidMount() {
    if (
      !(
        Object.entries(this.props.previousState).length === 0 &&
        this.props.previousState.constructor === Object
      )
    ) {
      this.setState(this.props.previousState);
    }
  }

  anyFiltersActive = () => {
    return !this.state.dateOn;
  };

  updateFilterState = (currentState) => {
    var filters = {};

    if (currentState.dateOn === true) {
      if (this.state.datePickerDate !== null) {
        filters["filter[inserted_at]"] = currentState.date;
      }
    }

    this.props.onChange(filters, this.state.datePickerDate);
  };

  dateToggledHandler = (isDateChecked) => {
    this.setState({ dateOn: isDateChecked });
  };

  dateChangedHandler = (newDate) => {
    if (newDate !== null) {
      const isoDate = DateHelper.getISODate(newDate);
      this.setState({ date: isoDate });
      this.setState({ datePickerDate: newDate });
    } else {
      this.setState({ date: null });
      this.setState({ datePickerDate: null });
    }
  };

  removeFilterCheckedHandler = () => {
    const newState = {
      dateOn: false,
      date: "",
      datePickerDate: null
    };

    this.setState(newState);
    this.updateFilterState(newState);
  };

  okClickedHandler = () => {
    this.updateFilterState(this.state);
  };

  render() {
    const filterOptions = (
      <div className="Padding-all--8">
        <div className="Margin-top--8">
          <FilterDate
            currentDate={this.state.datePickerDate}
            isChecked={this.state.dateOn}
            dateToggledHandler={this.dateToggledHandler}
            dateChangedHandler={this.dateChangedHandler}
          />
        </div>
        <div className="Padding-all--2">
          <Button
            className="Flex-alignContent--center"
            onClick={this.okClickedHandler}
          >
            Ok
          </Button>
        </div>
      </div>
    );
    return (
      <div>
        <Popover content={filterOptions} position={Position.BOTTOM_LEFT}>
          <Button icon="filter"> Filter </Button>
        </Popover>
        <Button
          onClick={this.removeFilterCheckedHandler}
          disabled={this.anyFiltersActive()}
          icon="filter-remove"
        />
      </div>
    );
  }
}

export default PostFilter;
