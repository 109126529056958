import React, { Component } from "react";
import NavHeader from "./NavHeader";
import ApiHelper from "../helpers/ApiHelper";
import SearchHelper from "../helpers/SearchHelper";
import SearchTableSection from "./Search/SearchTableSection";
import ObjectFactory from "../helpers/ObjectFactory/ObjectFactory";

class SearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountData: [],
      postData: [],
      eventData: [],
      loading: false
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const currentURL = this.props.match.url;
    const searchParams = "?" + currentURL.split("/")[2];

    this.setState({ loading: true });

    const response = await ApiHelper.getRequest("api/search" + searchParams);

    this.setState({ loading: false });

    if (response === "redirect") {
      this.props.history.push("/login");
    }

    if (response === undefined || response.data.length === 0) {
      // DO SOMETHING MEANINGFUL
      return;
    }

    this.setState({
      accountData: SearchHelper.getAccounts(response),
      postData: SearchHelper.getPosts(response),
      eventData: SearchHelper.getEvents(response)
    });
  };

  render() {
    const currentURL = this.props.match.url;
    const searchParams = currentURL
      .split("/")[2]
      .split("&")
      .map((x) => x.split("=")[1]);

    let headerMessage;

    if (searchParams.length === 2) {
      headerMessage = (
        <span>
          {" "}
          Showing search results for: <i> {searchParams[1]} </i> matched by{" "}
          <i> {searchParams[0]} </i>
        </span>
      );
    } else {
      headerMessage = (
        <span>
          Showing search results for: <i> {searchParams[0]} </i>
        </span>
      );
    }
    console.log(this.state.accountData);

    return (
      <div>
        <NavHeader />
        <div className="World-container">
          <div className="Action-Header Box-root Padding-bottom--12">
            <div className="Card-root Card--radius--all Card--shadow--medium Section Box-root Box-background--white">
              <div className="ContentHeader Box-root Box-background--white Box-divider--light-bottom-1 Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-direction--column">
                <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween">
                  <div className="ContentHeader-center Box-root Flex-flex Flex-direction--column Flex-justifyContent--center">
                    <span className="ContentHeader-title Text-color--dark Text-fontSize--20 Text-fontWeight--medium Text-lineHeight--24 Text-typeface--base Text-wrap--wrap Text-display--inline">
                      <span>{headerMessage}</span>
                    </span>
                  </div>
                </div>
                <div className="Box-root Margin-top--8 Flex-flex Flex-direction--column">
                  <div className="ButtonGroup Box-root">
                    <div className="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--flexStart">
                      <div className="Box-root Box-hideIfEmpty" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SearchTableSection
            title={"Accounts"}
            loading={this.state.loading}
            tableInfo={ObjectFactory.search.accountTableInfo}
            data={this.state.accountData}
          />

          <SearchTableSection
            title={"Posts"}
            loading={this.state.loading}
            tableInfo={ObjectFactory.search.postTableInfo}
            data={this.state.postData}
          />

          <SearchTableSection
            title={"Events"}
            loading={this.state.loading}
            tableInfo={ObjectFactory.search.eventTableInfo}
            data={this.state.eventData}
          />
        </div>
      </div>
    );
  }
}

export default SearchPage;
