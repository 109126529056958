import React from "react";

const ContentHeader = (props) => {
  return (
    <div className="ContentHeader Box-root Box-background--white Box-divider--light-bottom-1 Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-direction--column">
      <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween">
        <div className="ContentHeader-center Box-root Flex-flex Flex-direction--column Flex-justifyContent--center">
          <span className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-fontWeight--medium Text-lineHeight--24 Text-typeface--base Text-wrap--wrap Text-display--inline">
            <span>{props.title}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContentHeader;
