import React from "react";
import { Icon } from "@blueprintjs/core";

const SortableColumnHeaderCell = (props) => {
  return (
    <td
      onClick={() => props.sortObj.switchCurrentSort(props.actualColName)}
      id="amount"
      className="db-ListViewItem-cell db-ListViewItem-text"
      style={{ cursor: "pointer" }}
    >
      <div className="db-ListViewItem-cellContent  Box-root Padding-all--8 Flex-flex Flex-direction--row">
        <span className="db-ListViewItem-text Text-color--dark Text-align--left Text-fontSize--13 Text-fontWeight--medium Text-lineHeight--20 Text-typeface--upper Text-wrap--wrap Text-display--block">
          <span>{props.title}</span>
        </span>
      </div>
    </td>
  );
};

export default SortableColumnHeaderCell;
