import React from "react";
import ActionHeaderFactory from "./ActionHeaderFactory";

const ActionHeader = (props) => {
  if (props.fetching) {
    return ActionHeaderFactory.getLoader();
  } else {
    return (
      <div className="Padding-bottom--12">
        <div className="Card-root Card--radius--all Card--shadow--medium Box-background--white">
          <div className="Box-background--white Box-divider--light-bottom-1 Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-direction--column">
            {ActionHeaderFactory.getTitle(props)}
            <div className="Box-root Margin-top--8 Flex-flex Flex-direction--column">
              <div className="ButtonGroup Box-root">
                <div className="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--flexStart">
                  <div className="Box-root Box-hideIfEmpty">
                    {ActionHeaderFactory.getMenuContent(props)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ActionHeader;
